import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Container, Row, Col } from 'react-bootstrap'

const ContactScreen = () => {
    return (
        <>
            <Meta 
            title='İletişim - Kuru Blok Kalibratör, Sıcaklık Kalibrasyon Banyosu' 
            description='Kuru blok kalibratör, sıcaklık kalibrasyon banyosu, elektriksel test cihazı, ısıtmalı soğutmalı sirkülatör, kalibrasyon fırını, sıcaklık nem kalibrasyon ve test kabini, siyah cisim infrared kalibrasyon fırını, test ve ölçüm cihazı.'
            keywords='kuru blok kalibratör, sıcaklık kalibrasyon banyosu, sıcaklık kalibratörü, kalibrasyon banyosu, elektriksel test cihazı, sıcaklık nem kalibrasyon ve test kabini, nem kabini, iklimlendirme kabini, soğutmalı sirkülatör, kalibrasyon fırını' />

            <Header />

            <Container>
                <Row>
                    <h1 className='header-1'>İLETİŞİM</h1>

                    <Col lg={4} xl={4} className='contactCol'>
                        <p className='contactItem'>
                            <i className="fas fa-info-circle fa-lg" />&nbsp;&nbsp;TKS Test ve Kalibrasyon Sistemleri San. ve Tic. Ltd. Şti.
                        </p>
                    </Col>
                    <Col lg={4} xl={4} className='contactCol'>
                        <p className='contactItem'>
                            <i className="fas fa-map-marker-alt fa-lg" />&nbsp;&nbsp;Orta Mahallesi, Tatlıkuyu Sokak, No: 19/1, Pendik/İstanbul, 34896
                        </p>
                    </Col>
                    <Col lg={4} xl={4} className='contactCol'>
                        <p className='contactItem'>
                            <i className="far fa-clock fa-lg" />&nbsp;&nbsp;Pazartesi-Cuma: 08:30-18:30
                        </p>
                    </Col>

                    <Col lg={4} xl={4} className='contactCol'>
                        <a href="mailto:info@tkstest.com.tr" className='contactItem'>
                            <i className="far fa-envelope fa-lg mailIcon" />&nbsp;&nbsp;info@tkstest.com.tr
                        </a>
                    </Col>
                    <Col lg={4} xl={4} className='contactCol'>
                        <a href="tel:+902163794500" className='contactItem'>
                            <i className="fas fa-phone fa-lg phoneIcon" />&nbsp;&nbsp;+90 216 379 45 00
                        </a>
                    </Col>
                    <Col lg={4} xl={4} className='contactCol'>
                        <a href="https://api.whatsapp.com/send?phone=902163794500" target="_blank" rel="noopener noreferrer" className='contactItem'>
                            <i className="fab fa-whatsapp fa-xl wpIcon" />&nbsp;&nbsp;+90 216 379 45 00
                        </a>
                    </Col>

                    <Col xl={12} className='contactCol'>
                        <a href="https://www.instagram.com/tkstestofficial" target="_blank" rel="noopener noreferrer" className='socialIcon'>
                            <i className="fab fa-instagram fa-lg" />
                        </a>
                    </Col>  

                    <Col xl={12}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12065.669185688128!2d29.2447453!3d40.8846603!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6bd0559e8794c802!2sTKS%20Test%20ve%20Kalibrasyon%20Sistemleri!5e0!3m2!1str!2str!4v1636031893487!5m2!1str!2str" title='googleMap' />
                    </Col>  
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default ContactScreen