import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Container, Row, Col, Image } from 'react-bootstrap'

const AboutScreen = () => {
    return (
        <>
            <Meta 
            title='About - Dry Block Calibrator, Temperature Calibration Bath' 
            description='Dry block calibrator, temperature calibration bath, electrical test instrument, refrigerated heating circulator, thermocouple calibration furnace, temperature humidity test and calibration chamber, black body infrared calibration furnace, dry well calibrator and measurement equipment.'
            keywords='dry block calibrator, temperature calibration bath, temperature calibrator, calibration bath, electrical test instrument, temperature humidity test and calibration chamber, humudity chamber, refrigerated circulator, thermocouple calibration furnace, dry well calibrator' />

            <Header />

            <Container>
                <Row className='categoryRow'>
                    <h1 className='header-1'>ABOUT</h1>
                    <Col xl={12} className='aboutCol'>
                        <p className='aboutP'>
                        TKS Test ve Kalibrasyon Sistemleri San. ve Tic. Ltd. Şti. is a manufacturing company that offers test and calibration systems that the industry needs. In this journey that we started in 1997 as the UMS Istanbul Calibration Center, we continue to produce the test and measurement devices that the industry needs with our experience of more than 25 years under the brand of TKS Test and Calibration Systems since 2015. Many times special systems are required for material testing according to relevant standards. In order to meet these needs, a part of our business area is devoted to the production of test systems. Our company produces high accuracy Temperature Calibration Bath, Dry Block Calibrator and Refrigerated Heating Circulator between -80 °C and +1400 °C temperatures, also Black Body (ASTM E1965, ASTM E2847) for the calibration of ear thermometers, forehead infrared thermometers.
                        </p>
                        <p className='aboutP'><strong>TKS Test ve Kalibrasyon Sistemleri San. ve Tic. Ltd. Şti.</strong></p>
                        <Image src='/../en/s/850-300/temperature-calibration-equipment.jpg' alt='temperature calibration equipment' className='aboutImage' fluid/>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default AboutScreen