import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image, Button, Breadcrumb } from 'react-bootstrap'
import Categories from '../../categoriesEn'
import Products from '../../productsEn'

const ProductsScreen = ( { match } ) => {
    var isCategory = false
    var topCategory = null

    const category = Categories.find( category => category.url === match.params.id)

    if(category !== undefined){
        isCategory = true

        if(category.topCategoryId !== null){
            topCategory = Categories.find( ctgry => ctgry.id === category.topCategoryId)
        }
    }

    return (
        <>
            {isCategory === true ? 
                <Meta   title={category.name + ' - TKS'}
                        description={category.description}
                        keywords={category.keywords} /> 
            : null}

            <Header />

            <Container>
                {isCategory === true && category.isActive === true ?
                <Row>
                    <h1 className='header-1'>{category.name}</h1>

                    <Col xl={12} className='breadcrumbCol-2'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to='/en/'>
                                    <i className="fas fa-home" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/en/products/'>
                                    Products
                                </Link>
                            </Breadcrumb.Item>
                            {topCategory === null ? null :
                                <Breadcrumb.Item>
                                    <Link to={`/en/products/${topCategory.url}/`}>
                                        {topCategory.name}
                                    </Link>
                                </Breadcrumb.Item>}
                        </Breadcrumb>
                    </Col>

                    {Products.map( product => 
                        <>
                            {product.category === category.id && product.isActive === true ? 
                                <Col xs={6} sm={6} md={4} lg={4} xl={3} className='categoryCol'>
                                    {product.externalUrl === false ? 
                                    <Link to={`/en/product/${product.url}/`} className='textDecorationNone'>
                                        <Col className='categoryItem'>
                                            <picture>
                                                <source media='(min-width:750px)' srcset={product.image350} />
                                                <Image src={product.image250} alt={product.name + ' ' + product.device} className='categoryImage-2' fluid/>
                                            </picture>   

                                            <h2 className='categoryHeader-2'>
                                                {product.name}
                                            </h2>
                                            {product.overview.map((overview) => <p className='techinacalData'>{overview[0]}: {overview[1]}</p>)}
                                            <Button className='categoryButton'>
                                                Show Product
                                            </Button>
                                        </Col>
                                    </Link> 
                                    :
                                    <a href={product.url} className='textDecorationNone' target='_blank' rel="noopener noreferrer">
                                        <Col className='categoryItem'>
                                            <picture>
                                                <source media='(min-width:750px)' srcset={product.image350} />
                                                <Image src={product.image250} alt={category.name} className='categoryImage-2' fluid/>
                                            </picture>   

                                            <h2 className='categoryHeader-2'>
                                                {product.name}
                                            </h2>
                                            {product.overview.map((overview) => <p className='techinacalData'>{overview[0]}: {overview[1]}</p>)}
                                            <Button className='categoryButton'>
                                                Show Products
                                            </Button>
                                        </Col>
                                    </a>
                                    }
                                </Col>
                            : null}
                        </>
                    )}
                    
                    {category.description === null ? null : 
                        <Col xl={12} className='categoryCol'>
                            <Col xl={12} className='categoryDescriptionCol'>
                                <p className='categoryDescriptionP'>{category.description}</p>
                            </Col>
                        </Col>}           
                </Row>

                :

                <Row className='errorRow'>
                    <Col xl={12} className='productError'>
                        <p className='productErrorP'>Not Found</p>
                        <p className='error404P'>404</p>
                    </Col>
                </Row>
                }
            </Container>

            <Footer />
        </>
    )
}

export default ProductsScreen