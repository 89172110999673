import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Categories from '../../categoriesEn'

const CategoryScreen = () => {
    return (
        <>
            <Meta 
            title='Products - Dry Block Calibrator, Temperature Calibration Bath' 
            description='Dry block calibrator, temperature calibration bath, electrical test instrument, refrigerated heating circulator, thermocouple calibration furnace, temperature humidity test and calibration chamber, black body infrared calibration furnace, dry well calibrator and measurement equipment.'
            keywords='dry block calibrator, temperature calibration bath, temperature calibrator, calibration bath, electrical test instrument, temperature humidity test and calibration chamber, humudity chamber, refrigerated circulator, thermocouple calibration furnace, dry well calibrator' />

            <Header />

            <Container>
                <Row>
                    <h1 className='header-1'>PRODUCTS</h1>
                    
                    {Categories.map(category => category.topCategoryId === null && category.isActive === true ?
                        <Col xs={6} sm={6} md={6} lg={4} xl={4} className='categoryCol' key={category.name}>
                            <Link to={`/en/products/${category.url}/`} className='textDecorationNone'>
                                <Col className='categoryItem'>  
                                    <picture>
                                        <source media='(min-width:750px)' srcset={category.imageM} />
                                        <Image src={category.imageS} alt={category.name} className='categoryImage' fluid/> 
                                    </picture>   

                                    <h2 className='categoryHeader'>
                                        {category.name}
                                    </h2>
                                </Col>
                            </Link>
                        </Col>
                        : null
                    )}  
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default CategoryScreen