const categoriesEn = [
    {
        isActive: true,
        id: 'C01',
        topCategoryId: null,
        url: 'temperature-calibration-baths',
        imageS: '/en/s/400-225/temperature-calibration-baths.jpg',
        imageM: '/en/s/800-450/temperature-calibration-baths.jpg',
        name: 'Temperature Calibration Baths',
        description: 'Temperature Calibration Baths are constant temperature sources that provide heating or cooling of a liquid to provide more precise calibration of temperature sensors such as resistance thermometer temperature sensors (RTD), platinum resistance thermometers (PRT, SPRT), thermocouples. Depending on the temperature to be measured for calibration, temperature calibration baths are filled with either water or certain types of oil. In order to achieve a higher temperature homogeneity, stability and the lowest calibration uncertainties, experts recommend temperature calibration baths instead of dry block calibrators. Calibration baths can be either in the form of portable instruments or large, permanently installed in the laboratory.',
        keywords: 'temperature calibration bath, calibration bath, temperature calibrator',
    },
    {
        isActive: true,
        id: 'C02',
        topCategoryId: null,
        url: 'dry-block-calibrators',
        imageS: '/en/s/400-225/dry-block-calibrators.jpg',
        imageM: '/en/s/800-450/dry-block-calibrators.jpg',
        name: 'Dry Block Calibrators',
        description: 'Dry Block Calibrators (Dry Well Calibrators) are constant temperature sources used for the calibration of temperature sensors such as resistance thermometer temperature sensors (RTD), thermometers, thermocouples. For more accurate results, the calibrators should have a maximum space of a few millimeters to prevent airflow around the temperature probe. Therefore, Dry Block Calibrators have replaceable dry blocks to achieve good thermal contact with thermometers, thermocouples of different diameters. Dry Block Temperature Calibrators are fast and provide convenience to calibration laboratories providing mobile calibration services thanks to their easy portability.',
        keywords: 'dry block calibrator, dry well calibrator, calibration furnace, thermocouple calibration furnace, temperature calibrator',
    },
    {
        isActive: true,
        id: 'C10',
        topCategoryId: null,
        url: 'electrical-test-instruments',
        imageS: '/en/s/400-225/electrical-test-instruments.jpg',
        imageM: '/en/s/800-450/electrical-test-instruments.jpg',
        name: 'Electrical Test Instruments',
        description: null,
        keywords: 'electrical test instruments, electrical tools, electrical calibration',
    },
    {
        isActive: true,
        id: 'C03',
        topCategoryId: null,
        url: 'refrigerated-heating-circulators',
        imageS: '/en/s/400-225/refrigerated-heating-circulators.jpg',
        imageM: '/en/s/800-450/refrigerated-heating-circulators.jpg',
        name: 'Refrigerated Heating Circulators',
        description: 'Refrigerated Heating Circulators are constant temperature sources to heating or cooling a liquid for industrial and laboratory applications where a wide variety of temperature controls are required. Refrigerated heating circulators have a larger volume than temperature calibration baths. Depending on the temperature, generally refrigerated heating circulators are filled with either water or certain types of oil. Refrigerated heating circulators can be either in the form of portable instruments or large, permanently installed in the laboratory.',
        keywords: 'refrigerated circulator, refrigerated heating circulator, heating circulator',
    },
    {
        isActive: true,
        id: 'C11',
        topCategoryId: null,
        url: 'temperature-humidity-test-and-calibration-chambers',
        imageS: '/en/s/400-225/temperature-humidity-test-and-calibration-chambers.jpg',
        imageM: '/en/s/800-450/temperature-humidity-test-and-calibration-chambers.jpg',
        name: 'Temperature Humidity Test and Calibration Chambers',
        description: 'Temperature Humidity Test and Calibration Chambers, with their high stability and homogeneity, can be used for the calibration and testing of temperature and humidity meters, as well as various other applications. They offer a large volume for industrial and laboratory applications where various controls are required in humidity levels ranging from 5% RH to 95% RH.',
        keywords: 'temperature humidity test and calibration chamber, humidity chamber, humidity test chamber, calibration chamber',
    },
    {
        isActive: true,
        id: 'C05',
        topCategoryId: null,
        url: 'black-body-infrared-calibration-furnaces',
        imageS: '/en/s/400-225/black-body-infrared-calibration-furnaces.jpg',
        imageM: '/en/s/800-450/black-body-infrared-calibration-furnaces.jpg',
        name: 'Black Body Infrared Calibration Furnaces',
        description: 'Black Body Infrared Calibration Furnaces (Infrared Calibrator) is used for the calibration of ear thermometers and forehead infrared thermometers in constant temperature sources such as temperature calibration baths and refrigerated heating circulators. In order to obtain the lowest calibration uncertainties in the calibration of ear thermometers and forehead infrared thermometers, experts recommend black bodies used in liquid environments such as refrigerated heating circulators instead of infrared calibrators in dry environments. Black Body designed and produced according to American Standards (ASTM E1965, ASTM E2847) is generally used in calibration and test laboratories.',
        keywords: 'infrared calibrator, infrared temperature calibrator, infrared calibration furnace',
    },
    {
        isActive: true,
        id: 'C09',
        topCategoryId: null,
        url: 'dewar-flasks-cryo-vessels',
        imageS: '/en/s/400-225/dewar-flasks-cryo-vessels.jpg',
        imageM: '/en/s/800-450/dewar-flasks-cryo-vessels.jpg',
        name: 'Dewar Flasks',
        description: null,
        keywords: 'dewar flasks, cryo vessels',
    },
    {
        isActive: true,
        id: 'C08',
        topCategoryId: null,
        url: 'oiml-test-weights',
        imageS: '/en/s/400-225/oiml-test-weights.jpg',
        imageM: '/en/s/800-450/oiml-test-weights.jpg',
        name: 'OIML Test Weights',
        description: "It’s important to calibrate scales at regular intervals to ensure they remain accurate. Stainless Steel Masses (Test Weights) are used in calibration and testing of scales. Masses (Test Weights) can be produced according to standards such as ASTM, OIML and NIST. From lowest (most accurate) to highest tolerance, the OIML classes are E1, E2, F1, F2, M1, M2, and M3. OIML class weights are more commonly used outside the US in Europe and Latin America.",
        keywords: 'test weights, oiml weights, stainless steel masses, calibration mass sets',
    },
    {
        isActive: true,
        id: 'C04',
        topCategoryId: null,
        url: 'test-and-measurement-equipment',
        imageS: '/en/s/400-225/test-and-measurement-equipment.jpg',
        imageM: '/en/s/800-450/test-and-measurement-equipment.jpg',
        name: 'Test and Measurement Equipment',
        description: 'The instruments used to test various parameters defining the performance of a device is commonly termed as Test and Measurement Equipment. The test equipment creates signals and reads its response to check the performance of the device under test. Testing helps identify the components that undergo timely degradation due to the device’s exposure to external disturbances such as heat, shocks, vibrations and fluctuating environmental conditions. Voltmeter, Ohmmeter, Ammeter, and Multimeters are the most common devices in the first category.',
        keywords: 'measurement equipment, measurement device',
    },
    {
        isActive: true,
        id: 'C06',
        topCategoryId: null,
        url: 'accessories',
        imageS: '/en/s/400-225/test-calibration-accessories.jpg',
        imageM: '/en/s/800-450/test-calibration-accessories.jpg',
        name: 'Accessories',
        description: null,
        keywords: 'silicon oil, heat transfer salt, temperature calibration accessories, calibration accessories, calibration bath accessories',
    },
]
  
export default categoriesEn