const references = [
    {
        image: '/tr/s/200-200/aygaz-logo.jpg',
        name: 'aygaz logo'
    },
    {
        image: '/tr/s/200-200/borcelik-logo.jpg',
        name: 'borcelik logo'
    },
    {
        image: '/tr/s/200-200/ulker-logo.jpg',
        name: 'ulker logo'
    },
    {
        image: '/tr/s/200-200/banvit-logo.jpg',
        name: 'banvit logo'
    },
    {
        image: '/tr/s/200-200/hes-kablo-logo.jpg',
        name: 'hes kablo logo'
    },
    {
        image: '/tr/s/200-200/pirelli-logo.jpg',
        name: 'pirelli logo'
    },
    {
        image: '/tr/s/200-200/hyundai-logo.jpg',
        name: 'hyundai logo'
    },
    {
        image: '/tr/s/200-200/asas-aluminyum-logo.jpg',
        name: 'Asaş Alüminyum Logo'
    },
    {
        image: '/tr/s/200-200/pms-aluminyum-logo.jpg',
        name: 'PMS Alüminyum Logo'
    },
    {
        image: '/tr/s/200-200/turas-logo.jpg',
        name: 'turaş logo'
    },
    {
        image: '/tr/s/200-200/esan-aku-logo.jpg',
        name: 'esan aku logo'
    },
    {
        image: '/tr/s/200-200/kale-pw-logo.jpg',
        name: 'kale pw logo'
    },
    {
        image: '/tr/s/200-200/pakkens-logo.jpg',
        name: 'pakkens logo'
    },
    {
        image: '/tr/s/200-200/saglik-bakanligi-logo.jpg',
        name: 'saglik bakanligi logo'
    },
    {
        image: '/tr/s/200-200/osel-logo.jpg',
        name: 'osel logo'
    },
    {
        image: '/tr/s/200-200/haver-logo.jpg',
        name: 'haver logo'
    },
    {
        image: '/tr/s/200-200/menta-pharma-logo.jpg',
        name: 'menta pharma logo'
    },
    {
        image: '/tr/s/200-200/hacettepe-universitesi-logo.jpg',
        name: 'hacettepe üniversitesi logo'
    },
    {
        image: '/tr/s/200-200/ytu-logo.jpg',
        name: 'ytu logo'
    },
    {
        image: '/tr/s/200-200/tubitak-sage-logo.jpg',
        name: 'tubitak sage logo'
    },
    {
        image: '/tr/s/200-200/maden-teknik-arama-logo.jpg',
        name: 'maden teknik arama logo'
    },
    {
        image: '/tr/s/200-200/ak-gida-logo.jpg',
        name: 'ak gida logo'
    },
    {
        image: '/tr/s/200-200/kiwa-logo.jpg',
        name: 'kiwa logo'
    },
    {
        image: '/tr/s/200-200/elimko-logo.jpg',
        name: 'elimko logo'
    },
    {
        image: '/tr/s/200-200/skt-logo.jpg',
        name: 'skt logo'
    },
    {
        image: '/tr/s/200-200/ataturk-universitesi-logo.jpg',
        name: 'ataturk universitesi logo'
    },
    {
        image: '/tr/s/200-200/durden-logo.jpg',
        name: 'durden plastik logo'
    },
    {
        image: '/tr/s/200-200/berke-plastik-logo.jpg',
        name: 'berke plastik logo'
    },
    {
        image: '/tr/s/200-200/samet-logo.jpg',
        name: 'samet logo'
    },
    {
        image: '/tr/s/200-200/ozata-tersanecilik-logo.jpg',
        name: 'özata tersanecilik logo'
    },
    {
        image: '/tr/s/200-200/kroman-celik-logo.jpg',
        name: 'kroman celik logo'
    },
    {
        image: '/tr/s/200-200/teksan-logo.jpg',
        name: 'teksan logo'
    },
    {
        image: '/tr/s/200-200/caltek-logo.jpg',
        name: 'caltek logo'
    },
    {
        image: '/tr/s/200-200/superlit-logo.jpg',
        name: 'superlit logo'
    },
    {
        image: '/tr/s/200-200/enelsan-logo.jpg',
        name: 'enelsan logo'
    },
    {
        image: '/tr/s/200-200/airfel-logo.jpg',
        name: 'airfel logo'
    },
    {
        image: '/tr/s/200-200/tisan-logo.jpg',
        name: 'tisan logo'
    },
    {
        image: '/tr/s/200-200/lisi-aerospace-logo.jpg',
        name: 'lisi aerospace logo'
    },
    {
        image: '/tr/s/200-200/ak-teknik-logo.jpg',
        name: 'ak teknik logo'
    },
    {
        image: '/tr/s/200-200/aterma-logo.jpg',
        name: 'aterma logo'
    },
    {
        image: '/tr/s/200-200/accurate-muayene-logo.jpg',
        name: 'accurate muayene logo'
    },
    {
        image: '/tr/s/200-200/unikal-logo.jpg',
        name: 'unikal logo'
    },
    {
        image: '/tr/s/200-200/ankara-kalibrasyon-logo.jpg',
        name: 'ankara kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/meter-logo.jpg',
        name: 'meter logo'
    },
    {
        image: '/tr/s/200-200/penta-logo.jpg',
        name: 'penta logo'
    },
    {
        image: '/tr/s/200-200/anadolu-kalibrasyon-logo.jpg',
        name: 'anadolu kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/okm-kalibrasyon-logo.jpg',
        name: 'okm kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/egemet-logo.jpg',
        name: 'egemet logo'
    },
    {
        image: '/tr/s/200-200/kalmet-kalibrasyon-logo.jpg',
        name: 'kalmet kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/ums-medikal-logo.jpg',
        name: 'ums medikal logo'
    },
    {
        image: '/tr/s/200-200/arti-kalibrasyon-logo.jpg',
        name: 'arti kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/dacka-kalibrasyon-logo.jpg',
        name: 'dacka kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/mmo-kalmem-logo.jpg',
        name: 'mmo kalmem logo'
    },
    {
        image: '/tr/s/200-200/markal-kalibrasyon-logo.jpg',
        name: 'markal kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/isokal-logo.jpg',
        name: 'isokal logo'
    },
    {
        image: '/tr/s/200-200/kalmer-kalibrasyon-logo.jpg',
        name: 'kalmer kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/as-kalibrasyon-logo.jpg',
        name: 'as kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/batimet-kalibrasyon-logo.jpg',
        name: 'batimet kalibrasyon logo'
    },
    {
        image: '/tr/s/200-200/donanim-kalibrasyon-logo.jpg',
        name: 'donanim kalibrasyon logo'
    },
]
  
export default references 