import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import AdvertisementCarousel from '../components/AdvertisementCarousel'
import { Container, Row, Col, Image } from 'react-bootstrap'

const HomeScreen = () => {
    return (
        <>
            <Meta 
            title='Dry Block Calibrator, Temperature Calibration Bath, Electrical Test Instrument' 
            description='Dry block calibrator, temperature calibration bath, electrical test instrument, refrigerated heating circulator, thermocouple calibration furnace, temperature humidity test and calibration chamber, black body infrared calibration furnace, dry well calibrator and measurement equipment.'
            keywords='dry block calibrator, temperature calibration bath, temperature calibrator, calibration bath, electrical test instrument, temperature humidity test and calibration chamber, humudity chamber, refrigerated circulator, thermocouple calibration furnace, dry well calibrator' />
            
            <Header />

            <AdvertisementCarousel />

            <Container>
                <Row>
                    <h1 className='homeHeader'>TKS Test and Calibration Systems</h1>
                    <p className='homeP'>
                    TKS Test ve Kalibrasyon Sistemleri San. ve Tic. Ltd. Şti. is a manufacturing company that offers test and calibration systems that the industry needs. Many times special systems are required for material testing according to relevant standards. In order to meet these needs, a part of our business area is devoted to the production of test systems. Our company produces high accuracy Temperature Calibration Bath, Dry Block Calibrator and Refrigerated Heating Circulator between -80 °C and +1400 °C temperatures, also Black Body (ASTM E1965, ASTM E2847) for the calibration of ear thermometers, forehead infrared thermometers.
                    </p>
                </Row>
            </Container>

            <section className='homeSection'>
                <Container>
                    <Row>
                        <Col xl={6} className='homeCol-2'>
                            <h2 className='homeHeader-2'>What Is Temperature Calibration Bath?</h2>
                            <p className='homeP-2'>
                            Temperature Calibration Baths are constant temperature sources that provide heating or cooling of a liquid to provide more precise calibration of temperature sensors such as resistance thermometer temperature sensors (RTD), platinum resistance thermometers (PRT, SPRT), thermocouples. Depending on the temperature to be measured for calibration, temperature calibration baths are filled with either water or certain types of oil. In order to achieve a higher temperature homogeneity, stability and the lowest calibration uncertainties, experts recommend temperature calibration baths instead of dry block calibrators. Calibration baths can be either in the form of portable instruments or large, permanently installed in the laboratory.
                            </p>
                        </Col> 
                        <Col xl={6} className='homeCol-2'>
                            <h2 className='homeHeader-2'>What Is Dry Block Calibrator?</h2>
                            <p className='homeP-2'>
                            Dry Block Calibrators are constant temperature sources used for the calibration of temperature sensors such as resistance thermometer temperature sensors (RTD), thermometers, thermocouples. For more accurate results, the calibrators should have a maximum space of a few millimeters to prevent airflow around the temperature probe. Therefore, Dry Block Calibrators have replaceable dry blocks to achieve good thermal contact with thermometers, thermocouples of different diameters. Dry Block Temperature Calibrators are fast and provide convenience to calibration laboratories providing mobile calibration services thanks to their easy portability.
                            </p>
                        </Col> 
                        <Col xl={6} className='homeCol-2'>
                            <h2 className='homeHeader-2'>What Is Test and Measurement Equipment?</h2>
                            <p className='homeP-2'>
                            The instruments used to test various parameters defining the performance of a device is commonly termed as Test and Measurement Equipment. The test equipment creates signals and reads its response to check the performance of the device under test. Testing helps identify the components that undergo timely degradation due to the device’s exposure to external disturbances such as heat, shocks, vibrations and fluctuating environmental conditions. Voltmeter, Ohmmeter, Ammeter, and Multimeters are the most common devices in the first category.
                            </p>
                        </Col>
                        <Col xl={6} className='homeCol-2'>
                            <h2 className='homeHeader-2'>What Is Stainless Steel Masses?</h2>
                            <p className='homeP-2'>
                            It’s important to calibrate scales at regular intervals to ensure they remain accurate. Stainless Steel Masses (Test Weights) are used in calibration and testing of scales. Masses (Test Weights) can be produced according to standards such as ASTM, OIML and NIST. From lowest (most accurate) to highest tolerance, the OIML classes are E1, E2, F1, F2, M1, M2, and M3. OIML class weights are more commonly used outside the US in Europe and Latin America.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container>
                <Row>
                    <h3 className='homeHeader-3'>Distributors</h3>
                    <Col xs={6} sm={6} md={4} lg={4} xl={4} className='categoryCol'>
                        <a href='https://www.giussanionline.com/en' path="/" className='textDecorationNone' target='_blank' rel="noopener noreferrer">
                            <Col className='categoryItem'>
                                <Image src='/en/s/350-175/giussani-logo.jpg' alt='giussani logo' className='categoryImage-3' fluid/>
                            </Col>
                        </a>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4} xl={4} className='categoryCol'>
                        <a href='https://www.tunkia.cn/' path="/" className='textDecorationNone' target='_blank' rel="noopener noreferrer">
                            <Col className='categoryItem'>
                                <Image src='/en/s/350-175/tunkia-logo.jpg' alt='tunkia logo' className='categoryImage-3' fluid/>
                            </Col>
                        </a>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4} xl={4} className='categoryCol'>
                        <a href='https://www.demeithermo.com/' path="/" className='textDecorationNone' target='_blank' rel="noopener noreferrer">
                            <Col className='categoryItem'>
                                <Image src='/en/s/350-175/demei-logo.jpg' alt='demei instrument logo' className='categoryImage-3' fluid/>
                            </Col>
                        </a>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default HomeScreen