import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Container, Row, Col, Image } from 'react-bootstrap'
import References from '../../references'

const ReferenceScreen = () => {
    return (
        <>
            <Meta 
            title='Referanslar - Kuru Blok Kalibratör, Sıcaklık Kalibrasyon Banyosu' 
            description='Kuru blok kalibratör, sıcaklık kalibrasyon banyosu, elektriksel test cihazı, ısıtmalı soğutmalı sirkülatör, kalibrasyon fırını, sıcaklık nem kalibrasyon ve test kabini, siyah cisim infrared kalibrasyon fırını, test ve ölçüm cihazı.'
            keywords='kuru blok kalibratör, sıcaklık kalibrasyon banyosu, sıcaklık kalibratörü, kalibrasyon banyosu, elektriksel test cihazı, sıcaklık nem kalibrasyon ve test kabini, nem kabini, iklimlendirme kabini, soğutmalı sirkülatör, kalibrasyon fırını' />

            <Header />

            <Container>
                <Row className='referenceRow'>
                    <h1 className='header-1'>REFERANSLAR</h1>

                    {References.map( reference => 
                        <Col xs={6} sm={4} md={3} lg={3} xl={2} className='referenceCol' key={reference.name}>
                            <Image src={reference.image} alt={reference.name} className='referenceImage' fluid/>
                        </Col>
                    )}    
                </Row>
            </Container>
            
            <Footer />
        </>
    )
}

export default ReferenceScreen