import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Container, Row, Col, Image } from 'react-bootstrap'

const ServiceScreen = () => {
    return (
        <>
            <Meta 
            title='Services - Dry Block Calibrator, Temperature Calibration Bath' 
            description='Dry block calibrator, temperature calibration bath, electrical test instrument, refrigerated heating circulator, thermocouple calibration furnace, temperature humidity test and calibration chamber, black body infrared calibration furnace, dry well calibrator and measurement equipment.'
            keywords='dry block calibrator, temperature calibration bath, temperature calibrator, calibration bath, electrical test instrument, temperature humidity test and calibration chamber, humudity chamber, refrigerated circulator, thermocouple calibration furnace, dry well calibrator' />

            <Header />
            
            <Container>
                <Row>
                    <h1 className='header-1'>SERVICES</h1>
                    <Col md={6} lg={6} xl={6} className='serviceCol'>
                        <Col className='serviceCol-2'>
                            <h2 className='serviceHeader'>
                            TECHNICAL SERVICE
                            </h2>
                            <p className='serviceP'>
                            TKS Test and Calibration Systems continues to be the solution partner needed by the private sector and public institutions with devices in many fields such as Temperature Calibration Baths. When necessary, we can assist you quickly and easily with maintenance and repair, thanks to our wide range of services.
                            </p>
                            <Image src='/../en/s/600-300/technical-service.jpg' alt='technical service' className='serviceImage' fluid/>
                        </Col>
                    </Col>
                    <Col md={6} lg={6} xl={6} className='serviceCol'>
                        <Col className='serviceCol-2'>
                            <h2 className='serviceHeader'>
                            CUSTOM DESIGN AND PRIVATE LABEL PRODUCTION
                            </h2>
                            <p className='serviceP'>
                            Don't our standard systems provide the requirements you request? Can your special needs be solved by improving existing systems or creating new systems? We can improve our systems or create new systems in line with your needs and wishes. Please contact our team regarding your questions and requests.
                            </p>
                            <Image src='/../en/s/600-300/refrigerated-circulator.jpg' alt='refrigerated circulator' className='serviceImage' fluid/>
                        </Col>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default ServiceScreen