const popularProductsTr = [
    {
        isActive: true,
        id: 'P0003',
        url: 'pyros-650-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/pyros-650-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/pyros-650-kuru-blok-kalibrator.jpg',
        name: 'PYROS 650',
        device: "Kuru Blok Sıcaklık Kalibratörü",
        overview: [["Marka", "Giussani"], ["Sıcaklık Aralığı", "+35 °C ... +650 °C"]]
    },
    {
        isActive: true,
        id: 'P0027',
        url: 'td1858-tasinabilir-cok-fonksiyonlu-kalibrator',
        image250: '/tr/s/250-250/td1858-tasinabilir-cok-fonksiyonlu-kalibrator.jpg',
        image350: '/tr/s/350-350/td1858-tasinabilir-cok-fonksiyonlu-kalibrator.jpg',
        name: 'TD1858',
        device: "Taşınabilir Çok Fonksiyonlu Kalibratör",
        overview: [["Marka", "Tunkia"], ["Doğruluk", "100 ppm"]],
    },
    {
        isActive: true,
        id: 'P0016',
        url: 'dy-gtl-d-ultra-soguk-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/dy-gtl-d-ultra-soguk-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/dy-gtl-d-ultra-soguk-kuru-blok-kalibrator.jpg',
        name: 'DY-GTL-D',
        device: "Ultra Soğuk Kuru Blok Kalibratör",
        overview: [["Doğruluk", "±0.2 °C"], ["Sıcaklık Aralığı", "-100 °C ... +40 °C"]],
    },
    {
        isActive: true,
        id: 'P0014',
        url: 'sicaklik-kalibrasyon-banyosu-mini-200',
        image250: '/tr/s/250-250/mini-200-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/mini-200-sicaklik-kalibrasyon-banyosu.jpg',
        name: 'MINI 200',
        device: "Sıcaklık Kalibrasyon Banyosu",
        overview: [["Marka", "TKS"], ["Sıcaklık Aralığı", "+35 °C ... +200 °C"]],
    },
]
  
export default popularProductsTr  