import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'

const Header = () => {
    return (
        <header>
            <Navbar expand='lg'>
                <Container>
                    <LinkContainer to='/en/'>
                        <Navbar.Brand>
                            <img src='/tr/s/tks-test-ve-kalibrasyon-sistemleri-logo.png' className='logo' alt='TKS Test ve Kalibrasyon Sistemleri Logo'/>
                        </Navbar.Brand>
                    </LinkContainer>
                        
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className='ms-auto'>
                            <LinkContainer to='/en/'>
                                <Nav.Link id='headerItem'>HOME</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/en/about/'>
                                <Nav.Link id='headerItem'>ABOUT</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/en/products/'>
                                <Nav.Link id='headerItem'>PRODUCTS</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/en/services/'>
                                <Nav.Link id='headerItem'>SERVICES</Nav.Link>
                            </LinkContainer>

                            <Nav.Link href='https://www.umsistanbul.com.tr/tr/main/' target='_blank' rel="noopener noreferrer" id='headerItem'>CALIBRATION</Nav.Link>

                            <LinkContainer to='/en/references/'>
                                <Nav.Link id='headerItem'>REFERENCES</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/en/contact/'>
                                <Nav.Link id='headerItem'>CONTACT</Nav.Link>
                            </LinkContainer>

                            <NavDropdown title={<i className="fas fa-globe" />} id='headerItem'>
                                <LinkContainer to='/tr/'>
                                    <NavDropdown.Item>Türkçe</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/en/'>
                                    <NavDropdown.Item>English</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header