import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Container, Row, Col, Image } from 'react-bootstrap'
import References from '../../references'

const ReferenceScreen = () => {
    return (
        <>
            <Meta 
            title='References - Dry Block Calibrator, Temperature Calibration Bath' 
            description='Dry block calibrator, temperature calibration bath, electrical test instrument, refrigerated heating circulator, thermocouple calibration furnace, temperature humidity test and calibration chamber, black body infrared calibration furnace, dry well calibrator and measurement equipment.'
            keywords='dry block calibrator, temperature calibration bath, temperature calibrator, calibration bath, electrical test instrument, temperature humidity test and calibration chamber, humudity chamber, refrigerated circulator, thermocouple calibration furnace, dry well calibrator' />

            <Header />
            
            <Container>
                <Row className='referenceRow'>
                    <h1 className='header-1'>REFERENCES</h1>

                    {References.map( reference => 
                        <Col xs={6} sm={4} md={3} lg={3} xl={2} className='referenceCol' key={reference.name}>
                            <Image src={reference.image} alt={reference.name} className='referenceImage' fluid/>
                        </Col>
                    )}    
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default ReferenceScreen