const productsEn = [
    {
        isActive: true,
        id: 'P0001',
        category: 'C02',
        externalUrl: false,
        url: 'pyros-140-dry-block-calibrator',
        image250: '/en/s/250-250/pyros-140-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/pyros-140-dry-block-calibrator.jpg',
        image450: [ '/en/s/450-450/pyros-140-dry-block-calibrator.jpg'],
        name: 'PYROS 140',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [['Manufacturer', 'Giussani'], ['Origin', 'Italy'], ['Temperature Range', '-26 °C ... +140 °C'], ['Accuracy', '±0.25 °C'], ['Stability', '±0.10 °C'], ['Hole Diameter', '19 mm'], ['Hole Depth', '104 mm']],
        introduction: 'Giussani PYROS 140 dry block calibrator is designed for field applications and the severe conditions of the maritime industry. Reduced response time during the heating and cooling phases and rapid stabilization result in time savings in multi-point calibration operations.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 'Giussani PYROS 140 Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "Pyros 140-1H", "Pyros 140-2H"], ["Temperature Range", "-26 °C ... +140 °C", "-26 °C ... +140 °C"], ["Display Accuracy", "±0.25 °C", "±0.25 °C"], ["Display Resolution", "0.1 °C", "0.1 °C"], ["Heating Time (Stabilization Included)", <>T<sub>Amb</sub> ... +120 °C: 17 min</>, <>T<sub>Amb</sub> ... +120 °C: 20 min</>], ["CoolingTime (Stabilization Included)", <>120 °C ... T<sub>Amb</sub>: 15 min<br/>T<sub>Amb</sub> ... -20 °C: 15 min</>, <>120 °C ... T<sub>Amb</sub>: 17 min<br/>T<sub>Amb</sub> ... -20 °C: 20 min</>], ["Stability", "±0.10 °C", "±0.10 °C"], ["Axial Uniformity", <>±0.10 °C (at -20 °C)<br/>±0.05 °C (at 0 °C)<br/>±0.10 °C (at 100 °C)</>, <>±0.12 °C (at -20 °C)<br/>±0.04 °C (at 0 °C)<br/>±0.12 °C (at 100 °C)</>], ["Radial Uniformity (at 40 mm)", "±0.05 °C (at 100 °C)", "±0.05 °C (at 100 °C)"], ["Hole Diameter", "1 x 19 mm", "2 x 13 mm"], ["Hole Depth", "104 mm", "104 mm"], ["Operating Voltage", <>115/240 VAC<br/>± 10% 50/60Hz</>, <>115/240 VAC<br/>± 10% 50/60Hz</>], ["Electric Power", "80 W", "80 W"], ["Dimensions", "130x260x280 mm", "130x260x280 mm"], ["Weight", "5.5 kg", "5.5 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "PYROS 140-1H: 2D3391 4 Holes Insert (1 x 6.4 mm, 2 x 4.8 mm, 1 x 3.3 mm), 2D3463 Blind Insert", "PYROS 140-2H: 2D3199-003 1 Hole Insert (1 x 4.8 mm), 2D3199-004 1 Hole Insert (1 x 6.4 mm)"],
        hasAccessory: true,
        accessory: [["2MFR526MAX5053", "Rigid Case", '/en/s/150-150/2MFR526MAX5053.jpg'], ["2TRMBAG-PYROS", "Cordura Soft Bag", '/en/s/150-150/2TRMBAG-PYROS.jpg'], [<>(PYROS 140-2H)<br/>2D3199-000<br/>2D3199-001<br/>2D3199-002<br/>2D3199-003<br/>2D3199-004<br/>2D3199-005<br/>2D3199-006<br/>2D3199-007</>, <>(PYROS 140-2H)<br/>Blind Insert<br/>1 Hole Insert (1 x 3.2 mm)<br/>1 Hole Insert (1 x 4.0 mm)<br/>1 Hole Insert (1 x 4.8 mm)<br/>1 Hole Insert (1 x 6.4 mm)<br/>1 Hole Insert (1 x 7.9 mm)<br/>1 Hole Insert (1 x 9.5 mm)<br/>1 Hole Insert (1 x 11.1 mm)</>, '/en/s/150-150/pyros-140-inserts.jpg']],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0002',
        category: 'C02',
        externalUrl: false,
        url: 'pyros-375-dry-block-calibrator',
        image250: '/en/s/250-250/pyros-375-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/pyros-375-dry-block-calibrator.jpg',
        image450: [ '/en/s/450-450/pyros-375-dry-block-calibrator.jpg',],
        name: 'PYROS 375',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [['Manufacturer', 'Giussani'], ['Origin', 'Italy'], ['Temperature Range', '+30 °C ... +375 °C'], ['Accuracy', '±0.35 °C'], ['Stability', '±0.10 °C'], ['Hole Diameter', '26 mm'], ['Hole Depth', '150 mm']],
        introduction: 'DNV certified Giussani PYROS 375 dry block calibrator is designed for field applications and the severe conditions of the maritime industry. Compact, easily portable dry block temperature calibrator designed for the severe conditions of ships. Reduced response time during the heating and cooling phases and rapid stabilization result in time savings in multi-point calibration operations.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 'Giussani PYROS 375 Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "Pyros 375"], ["Temperature Range", "+30 °C ... +375 °C"], ["Display Accuracy", "±0.35 °C"], ["Display Resolution", "0.1 °C"], ["Heating Time (Stabilization Included)", <>T<sub>Amb</sub> ... +375 °C: 15 min</>], ["CoolingTime (Stabilization Included)", "+375 °C ... +50 °C: 60 min"], ["Stability", "±0.10 °C"], ["Axial Uniformity", <>±0.02 °C (at 50 °C)<br/>±0.2 °C (at 200 °C)<br/>±0.4 °C (at 375 °C)</>], ["Radial Uniformity (at 40 mm)", <>±0.1 °C (at 200 °C)<br/>±0.2 °C (at 375 °C)</>], ["Hole Diameter", "26 mm"], ["Hole Depth", "150 mm"], ["Operating Voltage", <>115/240 VAC<br/>± 10% 50/60Hz</>], ["Electric Power", "600 W"], ["Dimensions", "130x260x280 mm"], ["Weight", "5.32 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "2D3332 4 Holes Insert (1 x 11.1 mm, 1 x 6.4 mm, 1 x 4.8 mm, 1 x 3.2 mm)"],
        hasAccessory: true,
        accessory: [["2MFR526MAX5053", "Rigid Case", '/en/s/150-150/2MFR526MAX5053.jpg'], ["2TRMBAG-PYROS", "Cordura Soft Bag", '/en/s/150-150/2TRMBAG-PYROS.jpg'], [<>2D3333<br/>2D3332<br/>2D3331<br/>2D3738</>, <>Blind Insert<br/>4 Holes Insert (1 x 11.1 mm, 1 x 6.4 mm, 1 x 4.8 mm, 1 x 3.2 mm)<br/>2 Holes Insert (1 x 12.7 mm, 1 x 6.4 mm)<br/>Black Body</>, '/en/s/150-150/pyros-375-inserts.jpg']],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0003',
        category: 'C02',
        externalUrl: false,
        url: 'pyros-650-dry-block-calibrator',
        image250: '/en/s/250-250/pyros-650-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/pyros-650-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/pyros-650-dry-block-calibrator.jpg',],
        name: 'PYROS 650',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [['Manufacturer', 'Giussani'], ['Origin', 'Italy'], ['Temperature Range', '+35 °C ... +650 °C'], ['Accuracy', '±0.50 °C'], ['Stability', '±0.30 °C'], ['Hole Diameter', '26 mm'], ['Hole Depth', '150 mm']],
        introduction: 'DNV certified Giussani PYROS 650 dry block calibrator is designed for field applications and the severe conditions of the maritime industry. Compact, easily portable dry block temperature calibrator designed for the severe conditions of ships. Reduced response time during the heating and cooling phases and rapid stabilization result in time savings in multi-point calibration operations.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 'Giussani PYROS 650 Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "PYROS 650"], ["Temperature Range", "+35 °C ... +650 °C"], ["Display Accuracy", "±0.50 °C"], ["Display Resolution", "0.1 °C"], ["Heating Time (Stabilization Included)", <>T<sub>Amb</sub> ... +650 °C: 40 min</>], ["CoolingTime (Stabilization Included)", "+650 °C ... +100 °C: 70 min"], ["Stability", "±0.3 °C"], ["Axial Uniformity", <>±0.6 °C (at 250 °C)<br/>±0.5 °C (at 450 °C)<br/>±0.5 °C (at 650 °C)</>], ["Radial Uniformity (at 40 mm)", <>±0.15 °C (at 450 °C)<br/>±0.6 °C (at 650 °C)</>], ["Hole Diameter", "26 mm"], ["Hole Depth", "150 mm"], ["Operating Voltage", <>115/240 VAC<br/>± 10% 50/60Hz</>], ["Electric Power", "600 W"], ["Dimensions", "130x260x280 mm"], ["Weight", "6.5 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "2D3133 4 Holes Insert (1 x 10.5 mm, 1 x 7 mm, 1 x 5 mm, 1 x 3.2 mm)"],
        hasAccessory: true,
        accessory: [["2MFR526MAX5053", "Rigid Case", '/en/s/150-150/2MFR526MAX5053.jpg'], ["2TRMBAG-PYROS", "Cordura Soft Bag", '/en/s/150-150/2TRMBAG-PYROS.jpg'], [<>2D2927<br/>2D2928<br/>2D2929<br/>2D3033<br/>2D3034<br/>2D3133<br/>2D3738</>, <>Blind Insert<br/>2 Holes Insert (1 x 12.7 mm, 1 x 6.5 mm)<br/>1 Hole Insert (1 x 15.7 mm)<br/>3 Holes Insert (1 x 10.5 mm, 1 x 6.5 mm, 1 x 4.5 mm)<br/>1 Hole Insert (1 x 17.5 mm)<br/>4 Holes Insert (1 x 10.5 mm, 1 x 7 mm, 1 x 5 mm, 1 x 3.2 mm)<br/>Black Body</>, '/en/s/150-150/pyros-650-inserts.jpg']],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0004',
        category: 'C02',
        externalUrl: false,
        url: 'quartz-633-dry-block-calibrator',
        image250: '/en/s/250-250/quartz-633-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/quartz-633-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/quartz-633-dry-block-calibrator.jpg',],
        name: 'QUARTZ 633',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [['Manufacturer', 'Giussani'], ['Origin', 'Italy'], ['Temperature Range', '-30 °C ... +150 °C'], ['Accuracy', '±0.15 °C'], ['Stability', '±0.03 °C'], ['Hole Diameter', '35 mm'], ['Hole Depth', '135 mm']],
        introduction: 'Giussani QUARTZ 633 dry block calibrator with its rapid response time, high stability and high accuracy saves your time and effort when calibrating or verifying resistance thermometers, thermocouples and temperature sensors.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-quartz-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-quartz-series-dry-block-calibrators.pdf', 'Giussani QUARTZ 633 Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "QUARTZ 633"], ["Temperature Range", "-30 °C ... +150 °C"], ["Display Accuracy", "±0.15 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+25 °C ... +140 °C: 40 min"], ["CoolingTime (Stabilization Included)", "+140 °C ... +25 °C: 21 min"], ["Stability", "±0.03 °C"], ["Axial Uniformity (60 mm)", "±0.01 °C"], ["Hole Diameter", "35 mm"], ["Hole Depth", "135 mm"], ["Operating Voltage", "115 or 240 VAC ± 10% 50/60Hz"], ["Electric Power", "350 W"], ["Dimensions", "160x350x360 mm"], ["Weight", "9.3 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "2D1191 6 Holes Insert (1 x 10.5 mm, 1 x 8.5 mm, 1 x 6.5 mm, 1 x 5.5 mm, 1 x 4.5 mm, 1 x 3.5 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0005',
        category: 'C02',
        externalUrl: false,
        url: 'quartz-50-dry-block-calibrator',
        image250: '/en/s/250-250/quartz-50-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/quartz-50-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/quartz-50-dry-block-calibrator.jpg',],
        name: 'QUARTZ 50',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [['Manufacturer', 'Giussani'], ['Origin', 'Italy'], ['Temperature Range', '-50 °C ... +150 °C'], ['Accuracy', '±0.15 °C'], ['Stability', '±0.03 °C'], ['Hole Diameter', '35 mm'], ['Hole Depth', '135 mm']],
        introduction: 'Giussani QUARTZ 50 dry block calibrator with its rapid response time, high stability and high accuracy saves your time and effort when calibrating or verifying resistance thermometers, thermocouples and temperature sensors.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-quartz-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-quartz-series-dry-block-calibrators.pdf', 'Giussani QUARTZ 50 Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "QUARTZ 50"], ["Temperature Range", "-50 °C ... +150 °C"], ["Display Accuracy", "±0.15 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+25 °C ... +140 °C: 23 min"], ["CoolingTime (Stabilization Included)", "+140 °C ... +25 °C: 31 min"], ["Stability", "±0.03 °C"], ["Axial Uniformity (60 mm)", "±0.01 °C"], ["Hole Diameter", "35 mm"], ["Hole Depth", "135 mm"], ["Operating Voltage", "115 or 240 VAC ± 10% 50/60Hz"], ["Electric Power", "400 W"], ["Dimensions", "160x350x360 mm"], ["Weight", "9.3 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "2D1191 6 Holes Insert (1 x 10.5 mm, 1 x 8.5 mm, 1 x 6.5 mm, 1 x 5.5 mm, 1 x 4.5 mm, 1 x 3.5 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0006',
        category: 'C02',
        externalUrl: false,
        url: 'solar-1100-dry-block-calibrator',
        image250: '/en/s/250-250/solar-1100-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/solar-1100-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/solar-1100-dry-block-calibrator.jpg',],
        name: 'SOLAR 1100',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [["Manufacturer", "Giussani"], ["Origin", "Italy"], ["Temperature Range", "+200 °C ... +1100 °C"], ["Accuracy", "±3 °C"], ["Stability", "±0.30 °C"], ["Hole Diameter", "44 mm"], ["Hole Depth", "220 mm"]],
        introduction: 'SOLAR 1100 dry block calibrator allows operation up to 1100 °C, for control and verification of ultra high temperature probes. It ensures high thermal insulation even at the highest temperatures.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-solar-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-solar-series-dry-block-calibrators.pdf', 'Giussani SOLAR 1100 Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "SOLAR 1100"], ["Temperature Range", "+200 °C ... +1100 °C"], ["Display Accuracy", "±3 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+30 °C ... +1000 °C: 100 min"], ["CoolingTime (Stabilization Included)", "+1000 °C ... +100 °C: 360 min"], ["Stability", "±0.3 °C"], ["Axial Uniformity (40 mm)", "3.6 °C ± 0.3"], ["Hole Diameter", "44 mm"], ["Hole Depth", "220 mm"], ["Operating Voltage", "115 or 230 VAC"], ["Electric Power", "900 W"], ["Dimensions", "170x330x450 mm"], ["Weight", "12 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "2D3274 4 Holes Ceramic Insert (1 x 13.5 mm, 1 x 11 mm, 1 x 9 mm, 1 x 7 mm)", "2D3273 1 Hole Ceramic Insert (1 x 26 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0007',
        category: 'C02',
        externalUrl: false,
        url: 'solar-1200-dry-block-calibrator',
        image250: '/en/s/250-250/solar-1200-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/solar-1200-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/solar-1200-dry-block-calibrator.jpg',],
        name: 'SOLAR 1200',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [["Manufacturer", "Giussani"], ["Origin", "Italy"], ["Temperature Range", "+200 °C ... +1200 °C"], ["Accuracy", "±3 °C"], ["Stability", "±0.30 °C"], ["Hole Diameter", "35 mm"], ["Hole Depth", "210 mm"]],
        introduction: 'SOLAR 1200 dry block calibrator allows operation up to 1200 °C, for control and verification of ultra high temperature probes. It ensures high thermal insulation even at the highest temperatures.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-solar-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-solar-series-dry-block-calibrators.pdf', 'Giussani SOLAR 1200 Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "SOLAR 1200"], ["Temperature Range", "+200 °C ... +1200 °C"], ["Display Accuracy", "±3 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+30 °C ... +1000 °C: 100 min"], ["CoolingTime (Stabilization Included)", "+1000 °C ... +100 °C: 360 min"], ["Stability", "±0.3 °C"], ["Axial Uniformity (40 mm)", "1.5 °C ± 0.8"], ["Hole Diameter", "35 mm"], ["Hole Depth", "210 mm"], ["Operating Voltage", "230 VAC"], ["Electric Power", "900 W"], ["Dimensions", "160x350x385 mm"], ["Weight", "11.5 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "2D4019 4 Holes Ceramic Insert (1 x 12.8 mm, 1 x 9.5 mm, 1 x 6.4 mm, 1 x 4.8 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0008',
        category: 'C02',
        externalUrl: false,
        url: 'pulsar-35cu-dry-block-calibrator',
        image250: '/en/s/250-250/pulsar-35cu-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/pulsar-35cu-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/pulsar-35cu-dry-block-calibrator.jpg',],
        name: 'PULSAR 35CU',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [["Manufacturer", "Giussani"], ["Origin", "Italy"], ["Temperature Range", "+35 °C ... +600 °C"], ["Accuracy", "±0.3 °C"], ["Stability", "±0.05 °C"], ["Hole Diameter", "35 mm"], ["Hole Depth", "185 mm"]],
        introduction: 'Giussani PULSAR 35CU dry block calibrator with its rapid response time, high stability and high accuracy saves your time and effort when calibrating or verifying resistance thermometers, thermocouples and temperature sensors.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pulsar-35cu-dry-block-calibrator.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pulsar-35cu-dry-block-calibrator.pdf', 'Giussani PULSAR 35CU Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "PULSAR 35CU"], ["Temperature Range", "+30 °C ... +600 °C"], ["Display Accuracy", "±0.3 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+30 °C ... +600 °C: 52 min"], ["CoolingTime (Stabilization Included)", "+600 °C ... +100 °C: 65 min"], ["Stability", "±0.05 °C"], ["Axial Uniformity (60 mm)", "0.15 °C"], ["Hole Diameter", "35 mm"], ["Hole Depth", "185 mm"], ["Operating Voltage", "115 or 230 VAC"], ["Electric Power", "800 W"], ["Dimensions", "160x360x350 mm"], ["Weight", "10 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "2D3007 5 Holes Insert (1 x 12.5 mm, 1 x 8.5 mm, 1 x 6.5 mm, 1 x 5 mm, 1 x 3.5 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0009',
        category: 'C02',
        externalUrl: false,
        url: 'pulsar-80cu-dry-block-calibrator',
        image250: '/en/s/250-250/pulsar-80cu-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/pulsar-80cu-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/pulsar-80cu-dry-block-calibrator.jpg',],
        name: 'PULSAR 80CU',
        device: 'Dry Block Calibrator',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [["Manufacturer", "Giussani"], ["Origin", "Italy"], ["Temperature Range", "+30 °C ... +550 °C"], ["Accuracy", "±0.3 °C"], ["Stability", "±0.05 °C"], ["Hole Diameter", "60 mm"], ["Hole Depth", "275 mm"]],
        introduction: 'Giussani PULSAR 80CU dry block calibrator with its high stability, high accuracy and wide well saves your time and effort when calibrating or verifying resistance thermometers, thermocouples and temperature sensors.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pulsar-80cu-dry-block-calibrator.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pulsar-80cu-dry-block-calibrator.pdf', 'Giussani PULSAR 80CU Dry Block Calibrator Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "PULSAR 80CU"], ["Temperature Range", "+30 °C ... +550 °C"], ["Display Accuracy", "±0.3 °C"], ["Display Resolution", "0.1 °C"], ["Heating Time", "9 °C/min"], ["Cooling Time", "1.6 °C/min"], ["Stability", "±0.05 °C"], ["Axial Uniformity (120 mm)", "0.3 °C"], ["Hole Diameter", "60 mm"], ["Hole Depth", "275 mm"], ["Operating Voltage", "230 VAC"], ["Electric Power", "1700 W"], ["Dimensions", "170x450x330 mm"], ["Weight", "23 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Electric power cable", "Tweezers for insert removing", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Test report", "Optional Holes Insert"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0010',
        category: 'C01',
        externalUrl: false,
        url: 'fluid-100-45-temperature-calibration-bath',
        image250: '/en/s/250-250/fluid-100-45-temperature-calibration-bath.jpg',
        image350: '/en/s/350-350/fluid-100-45-temperature-calibration-bath.jpg',
        image450: [  '/en/s/450-450/fluid-100-45-temperature-calibration-bath.jpg',],
        name: 'FLUID 100-45',
        device: 'Temperature Calibration Bath',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [["Manufacturer", "Giussani"], ["Origin", "Italy"], ["Temperature Range", "-35 °C ... +140 °C"], ["Accuracy", "±0.15 °C"], ["Stability", "±0.02 °C"], ["Hole Diameter", "45 mm"], ["Hole Depth", "170 mm"]],
        introduction: 'FLUID 100-45 temperature calibration bath is a portable liquid temperature calibrator used for checking thermocouples, platinum resistance and glass thermometers in the laboratory as well as in the field. It is constantly kept homogeneous by a magnetic mixer whose speed is adjustable according to the viscosity of the fluid used.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 'FLUID 100-45 Temperature Calibration Bath Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "FLUID 100-45"], ["Temperature Range", "-35 °C ... +140 °C"], ["Display Accuracy", "±0.15 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+25 °C ... +140 °C: 40 min"], ["CoolingTime (Stabilization Included)", "+140 °C ... +25 °C: 100 min"], ["Stability", "±0.02 °C"], ["Axial Uniformity", "±0.05 °C"], ["Hole Diameter", "45 mm"], ["Hole Depth", "170 mm"], ["Operating Voltage", "115 or 230 VAC"], ["Electric Power", "350 W"], ["Dimensions", "160x360x350 mm"], ["Weight", "11 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["FLUID 100-45 Temperature Calibration Bath", "500 ml Silicone Oil 47V10", "Closure cap for transport", "Fluid emptying system", "Glass Thermometer Holder", "Electric power cable", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Cordura® Soft Bag"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0011',
        category: 'C01',
        externalUrl: false,
        url: 'fluid-h100-temperature-calibration-bath',
        image250: '/en/s/250-250/fluid-h100-temperature-calibration-bath.jpg',
        image350: '/en/s/350-350/fluid-h100-temperature-calibration-bath.jpg',
        image450: [  '/en/s/450-450/fluid-h100-temperature-calibration-bath.jpg',],
        name: 'FLUID H100',
        device: 'Temperature Calibration Bath',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [["Manufacturer", "Giussani"], ["Origin", "Italy"], ["Temperature Range", "-18 °C ... +140 °C"], ["Accuracy", "±0.15 °C"], ["Stability", "±0.02 °C"], ["Hole Diameter", "60 mm"], ["Hole Depth", "170 mm"]],
        introduction: 'FLUID H100 temperature calibration bath is a portable liquid temperature calibrator used for checking thermocouples, platinum resistance and glass thermometers in the laboratory as well as in the field. It is constantly kept homogeneous by a magnetic mixer whose speed is adjustable according to the viscosity of the fluid used.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 'FLUID H100 Temperature Calibration Bath Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "FLUID H100"], ["Temperature Range", "-18 °C ... +140 °C"], ["Display Accuracy", "±0.15 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+25 °C ... +140 °C: 55 min"], ["CoolingTime (Stabilization Included)", "+140 °C ... +25 °C: 50 min"], ["Stability", "±0.02 °C"], ["Axial Uniformity", "±0.05 °C"], ["Hole Diameter", "60 mm"], ["Hole Depth", "170 mm"], ["Operating Voltage", "115 or 230 VAC"], ["Electric Power", "300 W"], ["Dimensions", "160x360x350 mm"], ["Weight", "11 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["FLUID H100 Temperature Calibration Bath", "500 ml Silicone Oil 47V10", "Closure cap for transport", "Fluid emptying system", "Glass Thermometer Holder", "Electric power cable", "Kit of fuses", "Thermostat connection cables", "Instruction manual", "Cordura® Soft Bag"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0012',
        category: 'C01',
        externalUrl: false,
        url: 'fluid-200-temperature-calibration-bath',
        image250: '/en/s/250-250/fluid-200-temperature-calibration-bath.jpg',
        image350: '/en/s/350-350/fluid-200-temperature-calibration-bath.jpg',
        image450: [  '/en/s/450-450/fluid-200-temperature-calibration-bath.jpg',],
        name: 'FLUID 200',
        device: 'Temperature Calibration Bath',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [["Manufacturer", "Giussani"], ["Origin", "Italy"], ["Temperature Range", "+30 °C ... +200 °C"], ["Accuracy", "±0.15 °C"], ["Stability", "±0.02 °C"], ["Hole Diameter", "60 mm"], ["Hole Depth", "170 mm"]],
        introduction: 'FLUID 200 temperature calibration bath is a portable liquid temperature calibrator used for checking thermocouples, platinum resistance and glass thermometers in the laboratory as well as in the field. It is constantly kept homogeneous by a magnetic mixer whose speed is adjustable according to the viscosity of the fluid used.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 'FLUID 200 Temperature Calibration Bath Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "FLUID 200"], ["Temperature Range", "+30 °C ... +200 °C"], ["Display Accuracy", "±0.15 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+25 °C ... +140 °C: 25 min"], ["CoolingTime (Stabilization Included)", "+140 °C ... +25 °C: 70 min"], ["Stability", "±0.02 °C"], ["Axial Uniformity", "±0.01 °C"], ["Hole Diameter", "60 mm"], ["Hole Depth", "170 mm"], ["Operating Voltage", "115 or 230 VAC"], ["Electric Power", "500 W"], ["Dimensions", "160x360x350 mm"], ["Weight", "8.3 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["FLUID 200 Temperature Calibration Bath", "500 ml Silicone Oil 47V20", "500 ml Silicone Oil 47V10", "Closure cap for transport", "Fluid emptying system", "Glass Thermometer Holder", "Electric power cable", "Kit of fuses", "Thermostat connection cables", "Instruction manual"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0013',
        category: 'C01',
        externalUrl: false,
        url: 'fluid-h200-temperature-calibration-bath',
        image250: '/en/s/250-250/fluid-h200-temperature-calibration-bath.jpg',
        image350: '/en/s/350-350/fluid-h200-temperature-calibration-bath.jpg',
        image450: [  '/en/s/450-450/fluid-h200-temperature-calibration-bath.jpg',],
        name: 'FLUID H200',
        device: 'Temperature Calibration Bath',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [["Manufacturer", "Giussani"], ["Origin", "Italy"], ["Temperature Range", "+30 °C ... +250 °C"], ["Accuracy", "±0.2 °C"], ["Stability", "±0.02 °C"], ["Hole Diameter", "60 mm"], ["Hole Depth", "170 mm"]],
        introduction: 'FLUID H200 temperature calibration bath is a portable liquid temperature calibrator used for checking thermocouples, platinum resistance and glass thermometers in the laboratory as well as in the field. It is constantly kept homogeneous by a magnetic mixer whose speed is adjustable according to the viscosity of the fluid used.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 'FLUID H200 Temperature Calibration Bath Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "FLUID H200"], ["Temperature Range", "+30 °C ... +250 °C"], ["Display Accuracy", "±0.2 °C"], ["Display Resolution", "0.1/0.01 °C"], ["Heating Time (Stabilization Included)", "+25 °C ... +140 °C: 25 min"], ["CoolingTime (Stabilization Included)", "+140 °C ... +25 °C: 70 min"], ["Stability", "±0.02 °C"], ["Axial Uniformity", "±0.01 °C"], ["Hole Diameter", "60 mm"], ["Hole Depth", "170 mm"], ["Operating Voltage", "115 or 230 VAC"], ["Electric Power", "500 W"], ["Dimensions", "160x360x350 mm"], ["Weight", "8.3 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["FLUID 200 Temperature Calibration Bath", "500 ml Silicone Oil 47V20", "500 ml Silicone Oil 47V10", "Closure cap for transport", "Fluid emptying system", "Glass Thermometer Holder", "Electric power cable", "Kit of fuses", "Thermostat connection cables", "Instruction manual"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0014',
        category: 'C01',
        externalUrl: false,
        url: 'temperature-calibration-bath-mini-200',
        image250: '/en/s/250-250/mini-200-temperature-calibration-bath.jpg',
        image350: '/en/s/350-350/mini-200-temperature-calibration-bath.jpg',
        image450: [  '/en/s/450-450/mini-200-temperature-calibration-bath.jpg',],
        name: 'MINI 200',
        device: 'Temperature Calibration Bath',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+35 °C ... +200 °C"], ["Accuracy", "±0.35 °C"], ["Stability", "±0.06 °C"], ["Hole Diameter", "66 mm"], ["Hole Depth", "120 mm"]],
        introduction: 'MINI 200 temperature calibration bath is a portable liquid temperature calibrator used for checking thermocouples, platinum resistance and glass thermometers in the laboratory as well as in the field. It is constantly kept homogeneous by a magnetic mixer whose speed is adjustable according to the viscosity of the fluid used.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "MINI 200"], ["Temperature Range", "+35 °C ... +200 °C"], ["Display Accuracy", "±0.35 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.06 °C"], ["Hole Diameter", "66 mm"], ["Usable Well Depth", "120 mm"], ["Volume", "0.5 L"], ["Operating Voltage", "220/230 VAC ± 10% 50/60Hz"], ["Electric Power", "500 W"], ["Dimensions (W x D x H)", "132x210x272 mm"], ["Weight", "6 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'MINI 200 temperature calibration bath offers high stability and accuracy for pharmaceutical industry applications such as incubator calibration and steam sterilizer autoclave validation.'], ['', 'MINI 200 temperature calibration bath offers high stability and accuracy for plastic injection molding in the manufacturing industry.']],
    },
    {
        isActive: true,
        id: 'P0015',
        category: 'C02',
        externalUrl: false,
        url: 'dy-gtl-d-ultra-cool-dry-block-calibrator',
        image250: '/en/s/250-250/dy-gtl-d-ultra-cool-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/dy-gtl-d-ultra-cool-dry-block-calibrator.jpg',
        image450: [ '/en/s/450-450/dy-gtl-d-ultra-cool-dry-block-calibrator.jpg', '/en/s/450-450/dy-gtl-d-ultra-cool-dry-block-calibrator-2.jpg',],
        name: 'DY-GTL-D',
        device: 'Ultra Cool Dry Block Calibrator',
        brand: null,
        madeIn: null,
        overview: [["Temperature Range", "-100 °C ... +40 °C"], ["Accuracy", "±0.2 °C"], ["Display Resolution", "0.01 °C"], ["Stability", "±0.2 °C"], ["Hole Diameter", "30 mm"], ["Hole Depth", "155 mm"], ["Cooling Time", "+20 °C ... -100 °C: 45 min"]],
        introduction: 'DY-GTL-D ultra cool dry block calibrator allows operation up to -100 °C for field and laboratory applications at low temperatures. By reaching -100 °C from ambient temperature in 45 minutes, it provides time efficiency for testing and calibrating resistance thermometers, thermocouples and temperature sensors.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-100 °C ... +40 °C"], ["Display Resolution", "0.01 °C"], ["Accuracy", "±0.2 °C"], ["Stability", "±0.2 °C"], ["Cooling Time", "+20 °C ... -100 °C: 45 min"], ["Hole Diameter", "30 mm"], ["Hole Depth", "155 mm"], ["Weight", "12 kg"], ["Operating Voltage", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'DY-GTL-D ultra cool dry block calibrator offers high stability and accuracy for pharmaceutical industry applications such as incubator calibration, environmental test chamber calibration and ultra low temperature freezer calibration.']],
    },
    {
        isActive: true,
        id: 'P0016',
        category: 'C02',
        externalUrl: false,
        url: 'dy-gtl150x-dry-block-calibrator',
        image250: '/en/s/250-250/dy-gtl150x-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/dy-gtl150x-dry-block-calibrator.jpg',
        image450: [ '/en/s/450-450/dy-gtl150x-dry-block-calibrator.jpg'],
        name: 'DY-GTL150X',
        device: 'Dry Block Calibrator',
        brand: null,
        madeIn: null,
        overview: [["Temperature Range", "-30 °C ... +150 °C"], ["Accuracy", "±0.2 °C"], ["Stability", "±0.1 °C"], ["Hole Diameter", "30 mm"], ["Hole Depth", "155 mm"], ["Display Resolution", "0.1 °C"], ["Weight", "8 kg"]],
        introduction: 'DY-GTL150X dry block calibrator with its high stability and accuracy saves your time and effort when calibrating or verifying resistance thermometers, thermocouples and temperature sensors.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-30 °C ... +150 °C"], ["Display Resolution", "0.1 °C"], ["Accuracy", "±0.2 °C"], ["Stability", "±0.1 °C"], ["Hole Diameter", "30 mm"], ["Hole Depth", "155 mm"], ["Weight", "8 kg"], ["Operating Voltage", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'DY-GTL150X dry block calibrator offers high stability and accuracy for pharmaceutical industry applications such as incubator calibration, environmental test chamber calibration, ultra low temperature freezer calibration and steam sterilizer autoclave validation.']],
    },
    {
        isActive: true,
        id: 'P0017',
        category: 'C02',
        externalUrl: false,
        url: 'dy-gtl650x-dry-block-calibrator',
        image250: '/en/s/250-250/dy-gtl1200x-thermocouple-calibration-furnace.jpg',
        image350: '/en/s/350-350/dy-gtl1200x-thermocouple-calibration-furnace.jpg',
        image450: [ '/en/s/450-450/dy-gtl1200x-thermocouple-calibration-furnace.jpg'],
        name: 'DY-GTL650X',
        device: 'Dry Block Calibrator',
        brand: null,
        madeIn: null,
        overview: [["Temperature Range", "+50 °C ... +650 °C"], ["Accuracy", "±0.5 °C"], ["Stability", "±0.1 °C"], ["Hole Diameter", "34 mm"], ["Hole Depth", "155 mm"], ["Display Resolution", "0.1 °C"], ["Radial Uniformity", "±0.2 °C"]],
        introduction: 'DY-GTL650X dry block calibrator with its high stability and accuracy saves your time and effort when calibrating or verifying resistance thermometers, thermocouples and temperature sensors.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+50 °C ... +650 °C"], ["Display Resolution", "0.1 °C"], ["Accuracy", "±0.5 °C"], ["Stability", "±0.1 °C"], ["Radial Uniformity", "±0.2 °C"], ["Hole Diameter", "34 mm"], ["Hole Depth", "155 mm"], ["Weight", "8 kg"], ["Operating Voltage", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0018',
        category: 'C02',
        externalUrl: false,
        url: 'dy-gtl1200x-thermocouple-calibration-furnace',
        image250: '/en/s/250-250/dy-gtl1200x-thermocouple-calibration-furnace.jpg',
        image350: '/en/s/350-350/dy-gtl1200x-thermocouple-calibration-furnace.jpg',
        image450: [ '/en/s/450-450/dy-gtl1200x-thermocouple-calibration-furnace.jpg'],
        name: 'DY-GTL1200X',
        device: 'Thermocouple Calibration Furnace',
        brand: null,
        madeIn: null,
        overview: [["Temperature Range", "+300 °C ... +1200 °C"], ["Accuracy", "±2.0 °C"], ["Stability", "±1.0 °C"], ["Hole Diameter", "30 mm"], ["Hole Depth", "155 mm"], ["Display Resolution", "0.1 °C"], ["Radial Uniformity", "±1.0 °C"]],
        introduction: 'DY-GTL1200X dry block calibrator allows operation up to 1200 °C, for field and laboratory applications at high temperatures. It is a portable thermocouple calibration furnace designed for manufacturing applications such as steel, aluminum production.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+300 °C ... +1200 °C"], ["Display Resolution", "0.1 °C"], ["Accuracy", "±2.0 °C"], ["Stability", "±1.0 °C"], ["Radial Uniformity", "±1.0 °C"], ["Hole Diameter", "30 mm"], ["Hole Depth", "155 mm"], ["Weight", "8 kg"], ["Operating Voltage", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0019',
        category: 'C01',
        externalUrl: false,
        url: 'temperature-calibration-bath-mini-cool-125-5',
        image250: '/en/s/250-250/temperature-calibration-bath-mini-cool-125-5.jpg',
        image350: '/en/s/350-350/temperature-calibration-bath-mini-cool-125-5.jpg',
        image450: [  '/en/s/450-450/temperature-calibration-bath-mini-cool-125-5.jpg',],
        name: 'MINI COOL 125-5',
        device: 'Temperature Calibration Bath',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "-5 °C ... +125 °C"], ["Accuracy", "±0.65 °C"], ["Stability", "±0.07 °C"], ["Hole Diameter", "65 mm"], ["Hole Depth", "130 mm"]],
        introduction: 'MINI COOL 125-5 temperature calibration bath is a portable liquid temperature calibrator (Peltier Thermoelectric Cooler) used for checking thermocouples, platinum resistance and glass thermometers in the laboratory as well as in the field.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "MINI COOL 125-5"], ["Temperature Range", "-5 °C ... +125 °C"], ["Display Accuracy", "±0.65 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.07 °C"], ["Hole Diameter", "65 mm"], ["Usable Well Depth", "130 mm"], ["Hole Depth", "150 mm"], ["Volume", "0.4 L"], ["Operating Voltage", "220/230 VAC ± 10% 50/60Hz"], ["Dimensions (W x D x H)", "170x270x275 mm"], ["Weight", "9 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'MINI COOL 125-5 temperature calibration bath offers high stability and accuracy for pharmaceutical industry applications such as incubator calibration, environmental test chamber calibration and steam sterilizer autoclave validation.']],
    },
    {
        isActive: true,
        id: 'P0020',
        category: 'C01',
        externalUrl: false,
        url: 'temperature-calibration-bath-mini-cool-125-15',
        image250: '/en/s/250-250/temperature-calibration-bath-mini-cool-125-15.jpg',
        image350: '/en/s/350-350/temperature-calibration-bath-mini-cool-125-15.jpg',
        image450: [  '/en/s/450-450/temperature-calibration-bath-mini-cool-125-15.jpg',],
        name: 'MINI COOL 125-15',
        device: 'Temperature Calibration Bath',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "-15 °C ... +125 °C"], ["Accuracy", "±0.75 °C"], ["Stability", "±0.07 °C"], ["Hole Diameter", "25 mm"], ["Hole Depth", "130 mm"]],
        introduction: 'MINI COOL 125-15 temperature calibration bath is a portable liquid temperature calibrator (Peltier Thermoelectric Cooler) used for checking thermocouples, platinum resistance and glass thermometers in the laboratory as well as in the field.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "MINI COOL 125-15"], ["Temperature Range", "-15 °C ... +125 °C"], ["Display Accuracy", "±0.75 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.07 °C"], ["Hole Diameter", "25 mm"], ["Usable Well Depth", "130 mm"], ["Hole Depth", "150 mm"], ["Operating Voltage", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'MINI COOL 125-15 temperature calibration bath offers high stability and accuracy for pharmaceutical industry applications such as incubator calibration, environmental test chamber calibration and steam sterilizer autoclave validation.']],
    },
    {
        isActive: true,
        id: 'P0021',
        category: 'C01',
        externalUrl: false,
        url: 'temperature-calibration-salt-bath-duden-550l',
        image250: '/en/s/250-250/temperature-calibration-salt-bath-duden-550l.jpg',
        image350: '/en/s/350-350/temperature-calibration-salt-bath-duden-550l.jpg',
        image450: [  '/en/s/450-450/temperature-calibration-salt-bath-duden-550l.jpg',],
        name: 'DUDEN 550L',
        device: 'Extremely High Temperature Calibration Salt Bath',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+180 °C ... +550 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.06 °C"], ["Hole Diameter", "158 mm"], ["Hole Depth", "500 mm"]],
        introduction: 'DUDEN 550L extremely high temperature calibration salt bath allows operation up to 550 °C in a liquid environment using heat transfer salt for testing and calibration applications.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+180 °C ... +550 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.06 °C (at +200 °C), ±0.1 °C (at +500 °C)"], ["Hole Diameter", "158 mm (Access opening %55)"], ["Hole Depth", "500 mm"], ["Volume", "9 L"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "500 mm × 1172 mm × 638 mm"], ["Weight", "75 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0022',
        category: 'C01',
        externalUrl: false,
        url: 'temperature-calibration-bath-duden-300s',
        image250: '/en/s/250-250/temperature-calibration-bath-duden-300s.jpg',
        image350: '/en/s/350-350/temperature-calibration-bath-duden-300s.jpg',
        image450: [  '/en/s/450-450/temperature-calibration-bath-duden-300s.jpg',],
        name: 'DUDEN 300S',
        device: 'Temperature Calibration Bath',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+35 °C ... +300 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.03 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "200 mm"]],
        introduction: 'DUDEN 300S series temperature calibration bath is used in industry for testing and calibrating resistance thermometers, glass thermometers, thermocouples and temperature sensors within the temperature range of +35 °C to +300 °C. Its wide access opening saves your time and effort when calibrating or verifying multiple thermometers simultaneously.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+35 °C ... +300 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.03 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "200 mm"], ["Volume", "3.5 L"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "255 mm × 475 mm × 420 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0023',
        category: 'C01',
        externalUrl: false,
        url: 'temperature-calibration-bath-duden-150-20',
        image250: '/en/s/250-250/temperature-calibration-bath-duden-150-20.jpg',
        image350: '/en/s/350-350/temperature-calibration-bath-duden-150-20.jpg',
        image450: [  '/en/s/450-450/temperature-calibration-bath-duden-150-20.jpg',],
        name: 'DUDEN -20 Series',
        device: 'Temperature Calibration Bath',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "-20 °C ... +100 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.03 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "200 mm"]],
        introduction: 'DUDEN -20 series temperature calibration bath is used in industry for testing and calibrating resistance thermometers, glass thermometers, thermocouples and temperature sensors within the temperature range of -20 °C to +100 °C. Its wide access opening saves your time and effort when calibrating or verifying multiple thermometers simultaneously.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-20 °C ... +100 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.03 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "200 mm"], ["Volume", "3.5 L"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0024',
        category: 'C01',
        externalUrl: false,
        url: 'temperature-calibration-bath-duden-150-40',
        image250: '/en/s/250-250/temperature-calibration-bath-duden-150-40.jpg',
        image350: '/en/s/350-350/temperature-calibration-bath-duden-150-40.jpg',
        image450: [  '/en/s/450-450/temperature-calibration-bath-duden-150-40.jpg',],
        name: 'DUDEN -40 Series',
        device: 'Temperature Calibration Bath',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "-40 °C ... +100 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.03 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "200 mm"]],
        introduction: 'DUDEN -40 series temperature calibration bath is used in industry for testing and calibrating resistance thermometers, glass thermometers, thermocouples and temperature sensors within the temperature range of -40 °C to +100 °C. Its wide access opening saves your time and effort when calibrating or verifying multiple thermometers simultaneously.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-40 °C ... +100 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.03 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "200 mm"], ["Volume", "3.5 L"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "320 mm × 635 mm × 420 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0025',
        category: 'C01',
        externalUrl: false,
        url: 'temperature-calibration-bath-duden-100-80',
        image250: '/en/s/250-250/temperature-calibration-bath-duden-100-80.jpg',
        image350: '/en/s/350-350/temperature-calibration-bath-duden-100-80.jpg',
        image450: [  '/en/s/450-450/temperature-calibration-bath-duden-100-80.jpg',],
        name: 'DUDEN -80 Series',
        device: 'Temperature Calibration Bath',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "-80 °C ... +50 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.03 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "200 mm"]],
        introduction: 'DUDEN -80 series temperature calibration bath is used in industry for testing and calibrating resistance thermometers, glass thermometers, thermocouples and temperature sensors within the temperature range of -80 °C to +50 °C. Its wide access opening saves your time and effort when calibrating or verifying multiple thermometers simultaneously.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-80 °C ... +50 °C"], ["Display Resolution", "0.1 °C"], ["Stability", "±0.03 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "200 mm"], ["Volume", "3.5 L"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "415 mm × 840 mm × 615 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0026',
        category: 'C01',
        externalUrl: false,
        url: 'dy-rts30m-temperature-calibration-bath',
        image250: '/en/s/250-250/dy-rts30m-temperature-calibration-bath.jpg',
        image350: '/en/s/350-350/dy-rts30m-temperature-calibration-bath.jpg',
        image450: [  '/en/s/450-450/dy-rts30m-temperature-calibration-bath.jpg',],
        name: 'DY-RTS30M',
        device: 'Temperature Calibration Bath',
        brand: null,
        madeIn: null,
        overview: [["Temperature Range", "-30 °C ... +95 °C"], ["Display Resolution", "0.001 °C"], ["Stability", "±0.01 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "300 mm"], ["Volume", "6.5 L"]],
        introduction: 'DY-RTS30M temperature calibration bath is used in industry for testing and calibrating resistance thermometers, glass thermometers, thermocouples and temperature sensors within the temperature range of -30 °C to +95 °C. Its wide access opening saves your time and effort when calibrating or verifying multiple thermometers simultaneously.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-30 °C ... +95 °C"], ["Display Resolution", "0.001 °C"], ["Stability", "±0.01 °C"], ["Radial Uniformity", "±0.01 °C"], ["Axial Uniformity", "±0.02 °C"], ["Hole Diameter", "100 mm"], ["Hole Depth", "300 mm"], ["Volume", "6.5 L"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions", "310 mm × 420 mm × 500 mm"], ["Weight", "27 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0027',
        category: 'C01',
        externalUrl: false,
        url: 'dy-rts60-temperature-calibration-bath',
        image250: '/en/s/250-250/dy-rts60-temperature-calibration-bath.jpg',
        image350: '/en/s/350-350/dy-rts60-temperature-calibration-bath.jpg',
        image450: [  '/en/s/450-450/dy-rts60-temperature-calibration-bath.jpg',],
        name: 'DY-RTS60',
        device: 'Temperature Calibration Bath',
        brand: null,
        madeIn: null,
        overview: [["Temperature Range", "-60 °C ... +95 °C"], ["Display Resolution", "0.001 °C"], ["Stability", "±0.01 °C"], ["Hole Diameter", "130 mm"], ["Hole Depth", "480 mm"], ["Volume", "18 L"]],
        introduction: 'DY-RTS60 temperature calibration bath is used in industry for testing and calibrating resistance thermometers, glass thermometers, thermocouples and temperature sensors within the temperature range of -60 °C to +95 °C. Its wide access opening saves your time and effort when calibrating or verifying multiple thermometers simultaneously.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-60 °C ... +95 °C"], ["Display Resolution", "0.001 °C"], ["Stability", "±0.01 °C"], ["Radial Uniformity", "±0.01 °C"], ["Axial Uniformity", "±0.02 °C"], ["Hole Diameter", "130 mm"], ["Hole Depth", "480 mm"], ["Volume", "18 L"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions", "850 mm × 600 mm × 1200 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0028',
        category: 'C02',
        externalUrl: false,
        url: 'dry-block-calibrator-erciyes-marine-650',
        image250: '/en/s/250-250/marine-series-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/marine-series-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/marine-series-dry-block-calibrator.jpg',],
        name: 'Marine Series',
        device: 'Dry Block Calibrator',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+50 °C ... +650 °C"], ["Accuracy", "±1.5 °C"], ["Stability", "±0.3 °C"], ["Hole Diameter", "26 mm"], ["Immersion Depth", "125 mm"]],
        introduction: 'Marine series dry block calibrator is designed for field applications and the severe conditions of the maritime industry. Compact, easily portable dry block temperature calibrator designed for the severe conditions of ships.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "Marine 650"], ["Temperature Range", "+50 °C ... +650 °C"], ["Display Accuracy", "±1.5 °C"], ["Display Resolution", "1 °C"], ["Stability", "±0.3 °C"], ["Stabilization Time", "12 min."], ["Hole Diameter", "26 mm"], ["Immersion Depth", "125 mm"], ["Operating Voltage", "115/230 VAC (± 10%), 50/60 Hz"], ["Electric Power", "900 W"], ["Dimensions (W x H x D)", "122 x 314 x 210 mm"], ["Weight", "5.8 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0029',
        category: 'C02',
        externalUrl: false,
        url: 'dry-block-calibrator-erciyes-650',
        image250: '/en/s/250-250/erciyes-series-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/erciyes-series-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/erciyes-series-dry-block-calibrator.jpg',],
        name: 'Erciyes Series',
        device: 'Dry Block Calibrator',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+50 °C ... +650 °C"], ["Accuracy", "±1.5 °C"], ["Stability", "±0.3 °C"], ["Hole Diameter", "35 mm"], ["Immersion Depth", "125 mm"]],
        introduction: 'Erciyes series dry block calibrator is a portable dry well temperature calibrator used for checking thermocouples, platinum resistance and temperature sensors in the laboratory as well as in the field.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+50 °C ... +650 °C"], ["Display Resolution", "1 °C"], ["Accuracy", "±1.2 °C"], ["Stability", "±0.3 °C"], ["Stabilization Time", "15 min"], ["Hole Diameter", "35 mm"], ["Immersion Depth", "125 mm"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "153 mm x 312 mm x 242 mm"], ["Weight", "7.6 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0030',
        category: 'C02',
        externalUrl: false,
        url: 'dry-block-calibrator-erciyes-500',
        image250: '/en/s/250-250/custom-series-dry-block-calibrator.jpg',
        image350: '/en/s/350-350/custom-series-dry-block-calibrator.jpg',
        image450: [  '/en/s/450-450/custom-series-dry-block-calibrator.jpg',],
        name: 'Custom Series',
        device: 'Dry Block Calibrator',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+35 °C ... +500 °C"], ["Accuracy", "±1.2 °C"], ["Stability", "±0.15 °C"], ["Block Diameter", "70 mm"], ["Immersion Depth", "x ≤ 190 mm"]],
        introduction: 'Custom series dry block calibrator is a portable temperature calibrator specially designed for testing thermostats, resistance thermometers, thermocouples and temperature sensors with custom hole quantity, diameter and depth to meet specific requirements.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+35 °C ... +500 °C"], ["Display Resolution", "0.1 °C"], ["Accuracy", "±1.2 °C"], ["Stability", "±0.15 °C"], ["Block Diameter", "70 mm"], ["Diameter of Holes", "It is manufactured according to customer requirements."], ["Depth of Holes", "x ≤ 190 mm (It is manufactured according to customer requirements.)"], ["Well Type", "Fixed (Cannot be removed.)"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "153 mm x 312 mm x 242 mm"], ["Weight", "8.2 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0031',
        category: 'C02',
        externalUrl: false,
        url: 'dry-block-calibrator-micro-350',
        image250: '/en/s/250-250/dry-block-calibrator-micro-350.jpg',
        image350: '/en/s/350-350/dry-block-calibrator-micro-350.jpg',
        image450: [  '/en/s/450-450/dry-block-calibrator-micro-350.jpg',],
        name: 'Micro 350',
        device: 'Handheld Dry Well Calibrator',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+35 °C ... +350 °C"], ["Accuracy", "±1.5 °C"], ["Stability", "±0.1 °C"], ["Immersion Depth", "80 mm"]],
        introduction: 'Micro 350 dry block calibrator is a handheld dry well temperature calibrator used for checking thermocouples and temperature sensors in the laboratory as well as in the field. It is a compact, lightweight, and easily portable handheld dry well temperature calibrator',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+35 °C ... +350 °C"], ["Display Resolution", "0.1 °C"], ["Accuracy", "±1.5 °C"], ["Stability", "±0.1 °C"], ["Stabilization Time", "5 min"], ["Radial Uniformity", "±0.1 °C (+200 °C), ±0.3 °C (+350 °C)"], ["Axial Uniformity", "±0.8 °C (+200 °C), ±2.1 °C (+350 °C)"], ["Immersion Depth", "80 mm"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "11 cm x 7 cm x 16 cm"], ["Weight", "1.2 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0032',
        category: 'C03',
        externalUrl: false,
        url: 'refrigerated-heating-circulator-abant-health',
        image250: '/en/s/250-250/abant-health-series-refrigerated-heating-circulator.jpg',
        image350: '/en/s/350-350/abant-health-series-refrigerated-heating-circulator.jpg',
        image450: [  '/en/s/450-450/abant-health-series-refrigerated-heating-circulator.jpg',],
        name: 'Abant Health Series',
        device: 'Heating Circulator',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+30 °C ... +100 °C"], ["Stability", "±0.03 °C"]],
        introduction: 'Abant Health series heating circulator is used for checking thermocouples, platinum resistance and temperature sensors in the industry within the temperature range of +30 °C to +100 °C. Its large volume allows for the use of samples in testing and control processes. Abant Health series can also be used with the ASTM E1965 / ASTM E2847 blackbody designed for the calibration of ear and forehead infrared thermometers.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+30 °C ... +100 °C"], ["Stability", "±0.03 °C"], ["Display Type", "Digital"], ["Display Resolution", "0.1 °C"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0033',
        category: 'C03',
        externalUrl: false,
        url: 'refrigerated-heating-circulator-abant-150-40',
        image250: '/en/s/250-250/refrigerated-heating-circulator-abant-150-40.jpg',
        image350: '/en/s/350-350/refrigerated-heating-circulator-abant-150-40.jpg',
        image450: [  '/en/s/450-450/refrigerated-heating-circulator-abant-150-40.jpg',],
        name: 'Abant -40 Series',
        device: 'Refrigerated Heating Circulator',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "-40 °C ... +100 °C"], ["Stability", "±0.03 °C"]],
        introduction: 'Abant -40 series refrigerated heating circulator is used for checking thermocouples, platinum resistance and temperature sensors in the industry within the temperature range of -40 °C to +100 °C. Its large volume allows for the use of samples in testing and control processes.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-40 °C ... +100 °C"], ["Stability", "±0.03 °C"], ["Display Type", "Digital"], ["Display Resolution", "0.1 °C"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0034',
        category: 'C03',
        externalUrl: false,
        url: 'refrigerated-heating-circulator-abant-150-20',
        image250: '/en/s/250-250/refrigerated-heating-circulator-abant-150-20.jpg',
        image350: '/en/s/350-350/refrigerated-heating-circulator-abant-150-20.jpg',
        image450: [  '/en/s/450-450/refrigerated-heating-circulator-abant-150-20.jpg',],
        name: 'Abant -20 Series',
        device: 'Refrigerated Heating Circulator',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "-20 °C ... +100 °C"], ["Stability", "±0.03 °C"]],
        introduction: 'Abant -20 series refrigerated heating circulator is used for checking thermocouples, platinum resistance and temperature sensors in the industry within the temperature range of -20 °C to +100 °C. Its large volume allows for the use of samples in testing and control processes.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "-20 °C ... +100 °C"], ["Stability", "±0.03 °C"], ["Display Type", "Digital"], ["Display Resolution", "0.1 °C"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0035',
        category: 'C03',
        externalUrl: false,
        url: 'refrigerated-heating-circulator-abant-200ss',
        image250: '/en/s/250-250/refrigerated-heating-circulator-abant-200ss.jpg',
        image350: '/en/s/350-350/refrigerated-heating-circulator-abant-200ss.jpg',
        image450: [  '/en/s/450-450/refrigerated-heating-circulator-abant-200ss.jpg',],
        name: 'Abant 200 Series',
        device: 'Heating Circulator',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Manufacturer", "TKS"], ["Origin", "Türkiye"], ["Temperature Range", "+30 °C ... +200 °C"], ["Stability", "±0.03 °C"]],
        introduction: 'Abant 200 series refrigerated heating circulator is used for checking thermocouples, platinum resistance and temperature sensors in the industry within the temperature range of +30 °C to +200 °C. Its large volume allows for the use of samples in testing and control processes.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Temperature Range", "+30 °C ... +200 °C"], ["Stability", "±0.03 °C"], ["Display Type", "Digital"], ["Display Resolution", "0.1 °C"], ["Wetted Parts", "Stainless Steel"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "255 mm x 475 mm x 420 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0036',
        category: 'C10',
        externalUrl: false,
        url: 'td1858-portable-multifunction-calibrator',
        image250: '/en/s/250-250/td1858-portable-multifunction-calibrator.jpg',
        image350: '/en/s/350-350/td1858-portable-multifunction-calibrator.jpg',
        image450: [  '/en/s/450-450/td1858-portable-multifunction-calibrator.jpg', '/en/s/450-450/td1858-portable-multifunction-calibrator-2.jpg'],
        name: 'TD1858',
        device: "Portable Multifunction Calibrator",
        brand: 'TUNKIA',
        madeIn: 'Çin',
        overview: [["Manufacturer", "TUNKIA"], ['Accuracy', '100 ppm']],
        introduction: 'TD1858 is a high precision and multi-function calibrator, integrates AC\\DC voltage source, AC\\DC current source, DC resistance source, etc. It is portable and very suitable for on-site calibration',
        hasDataSheet: true,
        dataSheet: '/en/s/td1858-portable-multifunction-calibrator.pdf', 
        hasDocument: true,
        document: [['/en/s/td1858-portable-multifunction-calibrator.pdf', 'TD 1858 Portable Multifunction Calibrator Data Sheet']],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0037',
        category: 'C10',
        externalUrl: true,
        url: 'https://www.tunkia.cn/products.html',
        image250: '/en/s/250-250/tunkia-products.jpg',
        image350: '/en/s/350-350/tunkia-products.jpg',
        image450: [  '/en/s/450-450/tunkia-products.jpg',],
        name: 'Click for TUNKIA Products',
        device: '',
        brand: null,
        madeIn: null,
        overview: [["Announcement", "Products will be uploaded to our website soon. Click for TUNKIA"]],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0038',
        category: 'C11',
        externalUrl: false,
        url: 'dy-wsx01-temperature-humudity-test-and-calibration-chamber',
        image250: '/en/s/250-250/dy-wsx01-temperature-humudity-test-and-calibration-chamber.jpg',
        image350: '/en/s/350-350/dy-wsx01-temperature-humudity-test-and-calibration-chamber.jpg',
        image450: [  '/en/s/450-450/dy-wsx01-temperature-humudity-test-and-calibration-chamber.jpg', '/en/s/450-450/dy-wsx01-temperature-humudity-test-and-calibration-chamber-2.jpg', '/en/s/450-450/dy-wsx01-temperature-humudity-test-and-calibration-chamber-3.jpg'],
        name: 'DY-WSX01',
        device: "Temperature Humudity Test and Calibration Chamber",
        brand: 'Demei Instrument',
        madeIn: 'Çin',
        overview: [["Manufacturer", "Demei Instrument"], ["Origin", "Çin"], ["Humudity Range", "5% RH ... 95% RH"], ["Temperature Range", "+5 °C ... +50 °C"], ["Humudity Stability", "±0.8% RH"], ["Temperature Stability", "±0.2 °C"]],
        introduction: 'DY-WSX01 is temperature humudity test and calibration chamber specially developed for the calibration of temperature and humidity meter. It has 360 observation window without dead angle and high stability and fluctuation index. It is a special verification equipment for the detection of hair temperature and humidity meter, dry and wet bulb thermometer, digital temperature and humidity meter and other kinds of temperature and humidity sensors.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Humudity Range", "5% RH ... 95% RH"], ["Temperature Range", "+5 °C ... +50 °C"], ["Humudity Stability", "±0.8% RH"], ["Temperature Stability", "±0.2 °C"], ["Display Resolution", "0.1 °C, 0.1% RH"], ["Inside Working Area", "500×500×600 mm"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Weight", "320 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0039',
        category: 'C11',
        externalUrl: true,
        url: 'https://www.demeithermo.com/',
        image250: '/en/s/250-250/demei-products.jpg',
        image350: '/en/s/350-350/demei-products.jpg',
        image450: [  '/en/s/450-450/demei-products.jpg',],
        name: 'Click for DEMEI Products',
        device: '',
        brand: null,
        madeIn: null,
        overview: [["Announcement", "Products will be uploaded to our website soon. Click for DEMEI"]],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0040',
        category: 'C05',
        externalUrl: false,
        url: 'dy-ht50m-infrared-calibration-furnace',
        image250: '/en/s/250-250/dy-ht50m-infrared-calibration-furnace.jpg',
        image350: '/en/s/350-350/dy-ht50m-infrared-calibration-furnace.jpg',
        image450: [  '/en/s/450-450/dy-ht50m-infrared-calibration-furnace.jpg', ],
        name: 'DY-HT50M',
        device: 'Infrared Calibration Furnace',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "DY-HT50M"], ["Temperature Range", "+35 °C ... +500 °C"], ["Display Resolution", "0.1 °C"], ["Operating Voltage", "230 VAC (± 10%), 50/60 Hz"], ["Dimensions (W x H x D)", "220 x 145 x 250 mm"], ["Weight", "4 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0041',
        category: 'C05',
        externalUrl: false,
        url: 'black-body-astm-e1965-e2847-infrared-thermometer',
        image250: '/en/s/250-250/black-body-astm-e1965-e2847-infrared-thermometer.jpg',
        image350: '/en/s/350-350/black-body-astm-e1965-e2847-infrared-thermometer.jpg',
        image450: [  '/en/s/450-450/black-body-astm-e1965-e2847-infrared-thermometer.jpg', ],
        name: 'ASTM E1965 / ASTM E2847',
        device: 'Black Body',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'ASTM E1965 / ASTM E2847 blackbody is placed in temperature calibration baths and refrigerated heating circulators for the calibration of ear and forehead infrared thermometers. It is designed and manufactured according to American Standards (ASTM E1965, ASTM E2847).',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'ASTM E1965 / ASTM E2847 blackbody is placed in temperature calibration baths and refrigerated heating circulators for the calibration of ear and forehead infrared thermometers. It is designed and manufactured according to American Standards (ASTM E1965, ASTM E2847).']],
    },
    {
        isActive: true,
        id: 'P0042',
        category: 'C05',
        externalUrl: false,
        url: 'black-body-astm-e1965-infrared-thermometer',
        image250: '/en/s/250-250/black-body-astm-e1965-infrared-thermometer.jpg',
        image350: '/en/s/350-350/black-body-astm-e1965-infrared-thermometer.jpg',
        image450: [  '/en/s/450-450/black-body-astm-e1965-infrared-thermometer.jpg',],
        name: 'ASTM E1965',
        device: 'Black Body',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'ASTM E1965 blackbody is placed in temperature calibration baths and refrigerated heating circulators for the calibration of ear thermometers. It is designed and manufactured according to American Standards (ASTM E1965).',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'ASTM E1965 blackbody is placed in temperature calibration baths and refrigerated heating circulators for the calibration of ear thermometers. It is designed and manufactured according to American Standards (ASTM E1965).']],
    },
    {
        isActive: true,
        id: 'P0043',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-e2-weight-set',
        image250: '/en/s/250-250/oiml-e2-f1-f2-class-weight-set.jpg',
        image350: '/en/s/350-350/oiml-e2-f1-f2-class-weight-set.jpg',
        image450: [  '/en/s/450-450/oiml-e2-f1-f2-class-weight-set.jpg',],
        name: 'OIML E2 Class Weight Set',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Test weights (Stainless Steel Masses) manufactured from stainless steel in accordance with OIML E2 class standards are calibrated and certified in the calibration laboratory.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Dimensions:', 'OIML R 111-1'], ['Material:', 'Stainless Steel'], ['Test Weight Set Options:', ''], ['', '1 mg, 2 mg, 2 mg, 5 mg, 10 mg, 20 mg, 20 mg, 50 mg, 100 mg, 200 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 2 g, 5 g, 10 g, 20 g, 20 g, 50 g, 100 g, 200 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 2 kg, 5 kg'], ['Calibration Certificate:', '✓']],
    },
    {
        isActive: true,
        id: 'P0044',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-e2-single-weight',
        image250: '/en/s/250-250/oiml-e2-f1-f2-class-single-weight.jpg',
        image350: '/en/s/350-350/oiml-e2-f1-f2-class-single-weight.jpg',
        image450: [  '/en/s/450-450/oiml-e2-f1-f2-class-single-weight.jpg',],
        name: 'OIML E2 Class Single Weight',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Test weights (Stainless Steel Masses) manufactured from stainless steel in accordance with OIML E2 class standards are calibrated and certified in the calibration laboratory.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Dimensions:', 'OIML R 111-1'], ['Material:', 'Stainless Steel'], ['Test Weight Options:', ''], ['', '1 mg, 2 mg, 5 mg, 10 mg, 20 mg, 50 mg, 100 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 5 g, 10 g, 20 g, 50 g, 100 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Calibration Certificate:', '✓']],
    },
    {
        isActive: true,
        id: 'P0045',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-f1-weight-set',
        image250: '/en/s/250-250/oiml-e2-f1-f2-class-weight-set.jpg',
        image350: '/en/s/350-350/oiml-e2-f1-f2-class-weight-set.jpg',
        image450: [  '/en/s/450-450/oiml-e2-f1-f2-class-weight-set.jpg',],
        name: 'OIML F1 Class Weight Set',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Test weights (Stainless Steel Masses) manufactured from stainless steel in accordance with OIML F1 class standards are calibrated and certified in the calibration laboratory.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Dimensions:', 'OIML R 111-1'], ['Material:', 'Stainless Steel'], ['Test Weight Set Options:', ''], ['', '1 mg, 2 mg, 2 mg, 5 mg, 10 mg, 20 mg, 20 mg, 50 mg, 100 mg, 200 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 2 g, 5 g, 10 g, 20 g, 20 g, 50 g, 100 g, 200 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 2 kg, 5 kg'], ['Calibration Certificate:', 'It can be requested optionally.']],
    },
    {
        isActive: true,
        id: 'P0046',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-f1-single-weight',
        image250: '/en/s/250-250/oiml-e2-f1-f2-class-single-weight.jpg',
        image350: '/en/s/350-350/oiml-e2-f1-f2-class-single-weight.jpg',
        image450: [  '/en/s/450-450/oiml-e2-f1-f2-class-single-weight.jpg',],
        name: 'OIML F1 Class Single Weight',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Test weights (Stainless Steel Masses) manufactured from stainless steel in accordance with OIML F1 class standards are calibrated and certified in the calibration laboratory.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Dimensions:', 'OIML R 111-1'], ['Material:', 'Stainless Steel'], ['Test Weight Options:', ''], ['', '1 mg, 2 mg, 5 mg, 10 mg, 20 mg, 50 mg, 100 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 5 g, 10 g, 20 g, 50 g, 100 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Calibration Certificate:', 'It can be requested optionally.']],
    },
    {
        isActive: true,
        id: 'P0047',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-f2-weight-set',
        image250: '/en/s/250-250/oiml-e2-f1-f2-class-weight-set.jpg',
        image350: '/en/s/350-350/oiml-e2-f1-f2-class-weight-set.jpg',
        image450: [  '/en/s/450-450/oiml-e2-f1-f2-class-weight-set.jpg',],
        name: 'OIML F2 Class Weight Set',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Test weights (Stainless Steel Masses) manufactured from stainless steel in accordance with OIML F2 class standards are calibrated and certified in the calibration laboratory.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Dimensions:', 'OIML R 111-1'], ['Material:', 'Stainless Steel'], ['Test Weight Set Options:', ''], ['', '1 mg, 2 mg, 2 mg, 5 mg, 10 mg, 20 mg, 20 mg, 50 mg, 100 mg, 200 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 2 g, 5 g, 10 g, 20 g, 20 g, 50 g, 100 g, 200 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 2 kg, 5 kg'], ['Calibration Certificate:', 'It can be requested optionally.']],
    },
    {
        isActive: true,
        id: 'P0048',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-f2-single-weight',
        image250: '/en/s/250-250/oiml-e2-f1-f2-class-single-weight.jpg',
        image350: '/en/s/350-350/oiml-e2-f1-f2-class-single-weight.jpg',
        image450: [  '/en/s/450-450/oiml-e2-f1-f2-class-single-weight.jpg',],
        name: 'OIML F2 Class Single Weight',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Test weights (Stainless Steel Masses) manufactured from stainless steel in accordance with OIML F2 class standards are calibrated and certified in the calibration laboratory.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Dimensions:', 'OIML R 111-1'], ['Material:', 'Stainless Steel'], ['Test Weight Options:', ''], ['', '1 mg, 2 mg, 5 mg, 10 mg, 20 mg, 50 mg, 100 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 5 g, 10 g, 20 g, 50 g, 100 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Calibration Certificate:', 'It can be requested optionally.']],
    },
    {
        isActive: true,
        id: 'P0049',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-m1-stainless-steel-weight',
        image250: '/en/s/250-250/oiml-m1-class-single-weight.jpg',
        image350: '/en/s/350-350/oiml-m1-class-single-weight.jpg',
        image450: [  '/en/s/450-450/oiml-m1-class-single-weight.jpg',],
        name: 'OIML M1 Class Single Weight',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML M1 class test weights (Stainless Steel Masses), suitable for businesses and laboratories using scales for calibration and verification, can be used.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Dimensions:', 'OIML R 111-1'], ['Material:', 'Stainless Steel'], ['Test Weight Options:', ''], ['', '1 mg, 2 mg, 5 mg, 10 mg, 20 mg, 50 mg, 100 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 5 g, 10 g, 20 g, 50 g, 100 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Calibration Certificate:', 'It can be requested optionally.']],
    },
    {
        isActive: true,
        id: 'P0050',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-m1-cast-iron-weight',
        image250: '/en/s/250-250/oiml-m1-class-cast-iron-single-weight.jpg',
        image350: '/en/s/350-350/oiml-m1-class-cast-iron-single-weight.jpg',
        image450: [  '/en/s/450-450/oiml-m1-class-cast-iron-single-weight.jpg',],
        name: 'OIML M1 Class Cast Iron Weight',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML M1 class test weights manufactured from cast iron can be used in all businesses and laboratories using scales for calibration and verification.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Dimensions:', 'OIML R 111-1'], ['Material:', 'Cast Iron'], ['Test Weight Options:', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Calibration Certificate:', 'It can be requested optionally.']],
    },
    {
        isActive: true,
        id: 'P0051',
        category: 'C05',
        externalUrl: false,
        url: 'black-body-astm-e2847-infrared-thermometer',
        image250: '/en/s/250-250/black-body-astm-e2847-infrared-thermometer.jpg',
        image350: '/en/s/350-350/black-body-astm-e2847-infrared-thermometer.jpg',
        image450: [  '/en/s/450-450/black-body-astm-e2847-infrared-thermometer.jpg',],
        name: 'ASTM E2847',
        device: 'Black Body',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'ASTM E2847 blackbody is placed in temperature calibration baths and refrigerated heating circulators for the calibration of forehead infrared thermometers. It is designed and manufactured according to American Standards (ASTM E2847).',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'ASTM E2847 blackbody is placed in temperature calibration baths and refrigerated heating circulators for the calibration of forehead infrared thermometers. It is designed and manufactured according to American Standards (ASTM E2847).']],
    },
    {
        isActive: true,
        id: 'P0052',
        category: 'C05',
        externalUrl: true,
        url: 'https://www.demeithermo.com/',
        image250: '/en/s/250-250/demei-products.jpg',
        image350: '/en/s/350-350/demei-products.jpg',
        image450: [  '/en/s/450-450/demei-products.jpg',],
        name: 'Click for DEMEI Products',
        device: '',
        brand: null,
        madeIn: null,
        overview: [["Announcement", "Products will be uploaded to our website soon. Click for DEMEI"]],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0053',
        category: 'C06',
        externalUrl: false,
        url: 'silicone-oil',
        image250: '/en/s/250-250/silicone-oil.jpg',
        image350: '/en/s/350-350/silicone-oil.jpg',
        image450: [  '/en/s/450-450/silicone-oil.jpg',],
        name: 'Silicone Oil',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Silicone oils capable of withstanding high temperatures for temperature calibration baths and refrigerated heating circulators. DOW silicone oils (XIAMETER PMX-200 Silicone Oil 10 cSt, 50 cSt and more). WACKER silicone oils (AK 50, AK 100, AP 200 and more).',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: true,
        document: [['/en/s/dow-xiameter-pmx-200-5-20.pdf', "Dow Xiameter PMX-200 5 ... 20 cSt Data Sheet"], ['/en/s/dow-xiameter-pmx-200-50-1000.pdf', "Dow Xiameter PMX-200 50 ... 1000 cSt Data Sheet"], ['/en/s/wacker-ap-200.pdf', "Wacker AP 200 Data Sheet"]],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Manufacturer", "Model", "Flash Point"], ["DOW", "XIAMETER PMX-200 Silicone Oil 5 cSt (Silicone Oil Type 200.05)", "134 °C (Closed Cup)"], ["DOW", "XIAMETER PMX-200 Silicone Oil 10 cSt (Silicone Oil Type 200.10)", "211 °C (Closed Cup)"], ["DOW", "XIAMETER PMX-200 Silicone Oil 20 cSt (Silicone Oil Type 200.20)", "246 °C (Closed Cup)"], ["DOW", "XIAMETER PMX-200 Silicone Oil 50 cSt (Silicone Oil Type 200.50)", "318 °C (Open Cup)"], ["WACKER", "AP 200 ", "260 °C"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0054',
        category: 'C06',
        externalUrl: false,
        url: 'liquid-for-low-temperature',
        image250: '/en/s/250-250/liquid-for-low-temperature.jpg',
        image350: '/en/s/350-350/liquid-for-low-temperature.jpg',
        image450: [  '/en/s/450-450/liquid-for-low-temperature.jpg',],
        name: 'Liquid For Low Temperature',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Low temperature fluid providing high stability and homogeneity with low viscosity for use in temperature calibration baths and refrigerated heating circulator.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Low temperature fluid providing high stability and homogeneity with low viscosity for use in temperature calibration baths and refrigerated heating circulator.']],
    },
    {
        isActive: true,
        id: 'P0055',
        category: 'C06',
        externalUrl: false,
        url: 'heat-transfer-salt',
        image250: '/en/s/250-250/heat-transfer-salt.jpg',
        image350: '/en/s/350-350/heat-transfer-salt.jpg',
        image450: [  '/en/s/450-450/heat-transfer-salt.jpg',],
        name: 'Heat Transfer Salt',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Heat transfer salt is used for testing and calibrating in a liquid environment at very high temperatures in DUDEN 550L temperature calibration salt bath.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Heat transfer salt is used for testing and calibrating in a liquid environment at very high temperatures in DUDEN 550L temperature calibration salt bath.']],
    },
    {
        isActive: true,
        id: 'P0056',
        category: 'C06',
        externalUrl: false,
        url: 'temperature-calibrator-carrying-case',
        image250: '/en/s/250-250/temperature-calibrator-carrying-case.jpg',
        image350: '/en/s/350-350/temperature-calibrator-carrying-case.jpg',
        image450: [  '/en/s/450-450/temperature-calibrator-carrying-case.jpg',],
        name: 'Aluminum Carrying Case',
        device: '',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'Aluminum carrying cases in custom sizes for dry block calibrators, temperature calibration baths, test and measurement devices.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Aluminum carrying cases in custom sizes for dry block calibrators, temperature calibration baths, test and measurement devices.']],
    },
    {
        isActive: true,
        id: 'P0057',
        category: 'C04',
        externalUrl: false,
        url: '6-way-selector-switch',
        image250: '/en/s/250-250/6-way-selector-switch.jpg',
        image350: '/en/s/350-350/6-way-selector-switch.jpg',
        image450: [  '/en/s/450-450/6-way-selector-switch.jpg',],
        name: '6 Way Selector Switch',
        device: '',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: '6 Way Selector Switch for resistance thermometers and thermocouples. Selector switch enables easy selection of connected sensors on the device, eliminating complexity for testing and calibration of multiple sensors.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', '6 Way Selector Switch for resistance thermometers and thermocouples. Selector switch enables easy selection of connected sensors on the device, eliminating complexity for testing and calibration of multiple sensors.']],
    },
    {
        isActive: true,
        id: 'P0058',
        category: 'C04',
        externalUrl: false,
        url: 'pt100-resistance-thermometer',
        image250: '/en/s/250-250/pt100-resistance-thermometer.jpg',
        image350: '/en/s/350-350/pt100-resistance-thermometer.jpg',
        image450: [  '/en/s/450-450/pt100-resistance-thermometer.jpg',],
        name: 'Pt100 Reference Resistance Thermometer',
        device: '',
        brand: 'KMP Krick Messtechnik & Partner GmbH',
        madeIn: 'Germany',
        overview: [],
        introduction: 'Pt100 temperature sensors are widely used in various applications such as industrial automation, food production, pharmaceutical industry, laboratories, and climate control systems due to their high precision and durability.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [['Standard', 'DIN EN 60751, Class 1/10 DIN B; 1 x Pt100, 4 Wire'], ['Diameter', '4 mm'], ['Length', '400 mm'], ['Measuring Range', '-50 °C ... 500 °C']],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0059',
        category: 'C09',
        externalUrl: false,
        url: 'dewar-flasks-cylindrical',
        image250: '/en/s/250-250/dewar-flasks-cylindrical.jpg',
        image350: '/en/s/350-350/dewar-flasks-cylindrical.jpg',
        image450: [  '/en/s/450-450/dewar-flasks-cylindrical.jpg',],
        name: 'Dewar Flask Cylindrical',
        device: '',
        brand: 'KGW Isotherm',
        madeIn: null,
        overview: [],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: true,
        document: [['/en/s/dewargefasse-typ-00-S22-E.pdf', 'Dewar Flasks Cylindrical Type 00-S22'], ['/en/s/dewargefasse-typ-G-0-S22-E.pdf', 'Dewar Flasks Cylindrical with Grip Type G0-GS22']],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0060',
        category: 'C09',
        externalUrl: false,
        url: 'dewar-flasks-made-of-stainless-steel',
        image250: '/en/s/250-250/dewar-flasks-made-of-stainless-steel.jpg',
        image350: '/en/s/350-350/dewar-flasks-made-of-stainless-steel.jpg',
        image450: [  '/en/s/450-450/dewar-flasks-made-of-stainless-steel.jpg',],
        name: 'Dewar Flask Made of Stainless Steel',
        device: '',
        brand: 'KGW Isotherm',
        madeIn: null,
        overview: [],
        introduction: '',
        hasDataSheet: true,
        dataSheet: '/en/s/dewar-flasks-made-of-stainless-steel-type-gss-dss.pdf', 
        hasDocument: true,
        document: [['/en/s/dewar-flasks-made-of-stainless-steel-type-gss-dss.pdf', 'Dewar Flasks Made of Stainless Steel']],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0061',
        category: 'C06',
        externalUrl: false,
        url: 'giussani-carrying-case',
        image250: '/en/s/250-250/giussani-carrying-case.jpg',
        image350: '/en/s/350-350/giussani-carrying-case.jpg',
        image450: [  '/en/s/450-450/giussani-carrying-case.jpg',],
        name: 'Giussani carrying Cases',
        device: '',
        brand: 'Giussani',
        madeIn: 'Italy',
        overview: [],
        introduction: 'Giussani carrying cases for Giussani dry block calibrators and temperature calibration baths. Cordura® Soft Bag, Rigid Case, Aluminium Rigid Case. Cordura® Soft Bag, Rigid Case, Aluminium Rigid Case',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Cordura® Soft Bag'], ['', 'Rigid Case'], ['', 'Aluminium Rigid Case']],
    },
]
  
export default productsEn