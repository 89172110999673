import React from 'react'
import { Button, Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AdvertisementCarousel = () => {
    return (
        <section>
            <Carousel interval={6000} controls={false} pause={false}>    
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:450px)' srcset='/en/s/1500-555/giussani-dry-block-calibrator.jpg' />
                        <Image src='/en/s/500-750/giussani-dry-block-calibrator.jpg' alt='giussani dry block calibrator' className='carouselImage' fluid/>
                    </picture>
                    <Carousel.Caption>
                        <h2 className='carouselHeader'>Türkiye Azerbaijan Libya<br/>GIUSSANI Distributor</h2>
                        <p className='carouselP'>DNV Certified PYROS 650 Dry Block Calibrator for the Maritime Industry</p>
                        <p className='carouselSecondP'>Dry Block Calibrator, Temperature Calibration Bath, Test Bench</p>
                        <Link to={'products/dry-block-calibrators/'}>
                            <Button className='carouselButton-2'>
                                Show Product
                            </Button>
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:450px)' srcset='/en/s/1500-555/tunkia-electrical-test-instrument.jpg' />
                        <Image src='/en/s/500-750/tunkia-electrical-test-instrument.jpg' className='carouselImage' alt='tunkia electrical test instrument' fluid/>
                    </picture>
                    <Carousel.Caption>
                        <h2 className='carouselHeader'>TUNKIA Türkiye Distributor</h2>
                        <p className='carouselP'>Electric Vehicle Charger Tester, Electrical Test Instrument</p>
                        <p className='carouselSecondP'>High Voltage Test Equipment, Electrical Calibration Equipment</p>
                        <Link to={'products/electrical-test-instruments/'}>
                            <Button className='carouselButton'>
                                Show Product
                            </Button>
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:450px)' srcset='/en/s/1500-555/demei-instrument-temperature-calibrator.jpg' />
                        <Image src='/en/s/500-750/demei-instrument-temperature-calibrator.jpg' alt='demei instrument temperature calibrator' className='carouselImage' fluid/>
                    </picture>
                    <Carousel.Caption>
                        <h2 className='carouselHeader-2'>DEMEI INSTRUMENT Türkiye Distributor</h2>
                        <p className='carouselP-2'>Temperature Humidity Test and Calibration Chamber, Dry Block Calibrator</p>
                        <p className='carouselSecondP-2'>Black Body Infrared Calibration Furnace, Pressure Calibrator</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </section>
    )
}

export default AdvertisementCarousel