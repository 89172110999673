import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Container, Row, Col, Image } from 'react-bootstrap'

const AboutScreen = () => {
    return (
        <>
            <Meta 
            title='Kurumsal - Kuru Blok Kalibratör, Sıcaklık Kalibrasyon Banyosu' 
            description='Kuru blok kalibratör, sıcaklık kalibrasyon banyosu, elektriksel test cihazı, ısıtmalı soğutmalı sirkülatör, kalibrasyon fırını, sıcaklık nem kalibrasyon ve test kabini, siyah cisim infrared kalibrasyon fırını, test ve ölçüm cihazı.'
            keywords='kuru blok kalibratör, sıcaklık kalibrasyon banyosu, sıcaklık kalibratörü, kalibrasyon banyosu, elektriksel test cihazı, sıcaklık nem kalibrasyon ve test kabini, nem kabini, iklimlendirme kabini, soğutmalı sirkülatör, kalibrasyon fırını' />

            <Header />

            <Container>
                <Row className='categoryRow'>
                    <h1 className='header-1'>KURUMSAL</h1>
                    <Col xl={12} className='aboutCol'>
                        <p className='aboutP'>
                            TKS Test ve Kalibrasyon Sistemleri San. ve Tic. Ltd. Şti. endüstrinin ihtiyacı olan test ve kalibrasyon sistemlerini sunan bir üretim firmasıdır. 1997'de UMS İstanbul Kalibrasyon Laboratuvarı olarak başladığımız bu yolculukta 2015 yılından itibaren TKS Test ve Kalibrasyon Sistemleri markası ile 25 yılı aşkın tecrübemizle sektörün ihtiyacı olan test ve ölçüm cihazlarını üretmeye devam ediyoruz. Bir çok zaman ilgili standartlara göre malzeme testleri için özel sistemler gerekir. Bu ihtiyaçları karşılamak amacı ile iş alanımızın bir bölümünü test sistemleri üretimine ayrılmıştır. Firmamız -100 °C ile +1400 °C sıcaklıkları arasında yüksek hassasiyetli Sıcaklık Kalibrasyon Banyosu, Kuru Blok Kalibratör, Isıtmalı Soğutmalı Sirkülatör ve kulaktan ateş ölçer, alından ateş ölçer infrared termometrelerin kalibrasyonu için Siyah Cisim üretimi yapmaktadır.
                        </p>
                        <p className='aboutP'><strong>TKS Test ve Kalibrasyon Sistemleri San. ve Tic. Ltd. Şti.</strong></p>
                        <Image src='/../tr/s/850-300/sicaklik-kalibrasyonu.jpg' alt='sıcaklık kalibrasyonu' className='aboutImage' fluid/>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default AboutScreen