import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image, Carousel, Button, Breadcrumb, Table, Tabs, Tab} from 'react-bootstrap'
import Categories from '../../categoriesTr'
import Products from '../../productsTr'

const ProductScreen = ( { match } ) => {

    var isProduct = false
    var category

    const product = Products.find(product => product.url === match.params.id)

    if(product !== undefined){
        isProduct = true
        category = Categories.find( category => category.id === product.category)
    }

    return (
        <>
            {isProduct === true ? 
                <Meta   title={product.name + ' ' + product.device + ' - TKS'}
                        description={product.introduction}
                        keywords={category.keywords} /> 
            : null}

            <Header />

            <Container>
                {isProduct === true && product.isActive === true ?
                <Row>
                    <Col xl={12} className='breadcrumbCol'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to='/tr/'>
                                    <i className="fas fa-home" />
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to='/tr/urunler/'>
                                    Ürünler
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/tr/urunler/${category.url}/`}>
                                    {category.name}
                                </Link>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className='productCol'>
                        <Carousel className='productCarousel' interval={null} indicators={false}>
                            {product.image450.map( image => 
                                <Carousel.Item className='carouselItem'>
                                    <Image src={image} alt={product.name + ' ' + product.device} fluid/>
                                </Carousel.Item>
                            )}
                        </Carousel>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className='productCol'>
                        <Col xl={12} className='productCol-3'>
                            <h1 className='productHeader'>{product.name} {product.device}</h1>
                        </Col>
                        <p className='productOverviewP'>{product.introduction}</p>
                        {product.brand === null ? null : <p className='productOverviewP'><strong className='productOverviewP-2'>Marka:</strong> {product.brand}</p>}
                        {product.madeIn === null ? null : <p className='productOverviewP'><strong className='productOverviewP-2'>Menşei:</strong> {product.madeIn}</p>}
                        <p className='productOverviewP'><strong className='productOverviewP-2'>Kategori:</strong> <Link to={`/tr/urunler/${category.url}/`} className='textDecorationNone-2'>{category.name}</Link></p>
                        <Col className='requestCol'>
                            <a href="mailto:info@tkstest.com.tr" className='requestHeader'>
                                <Button className='requestButton'>
                                    <i className="fa-regular fa-envelope fa-lg" /> Teklif İste
                                </Button>
                            </a>
                        </Col>
                        <Col className='requestCol'>
                            <a href="https://api.whatsapp.com/send?phone=902163794500" target="_blank" rel="noopener noreferrer" className='requestHeader'>
                                <Button className='requestWhatsappButton'>
                                    <i className="fa-brands fa-whatsapp fa-lg" /> Teklif İste
                                </Button>
                            </a> 
                        </Col>
                        {product.hasDataSheet === true ?
                        <Col className='requestCol'>
                            <a href={product.dataSheet} target="_blank" rel="noopener noreferrer" className='requestHeader'>
                                <Button className='requestPdf'>
                                    <i className="fa-regular fa-file-pdf fa-lg" />&nbsp;&nbsp;Data Sheet
                                </Button>
                            </a> 
                        </Col> : null}
                    </Col>
                    
                    <Col xl={12} className='productCol-2'>
                        <Col xl={12} className='customTabs'>
                            <Tabs id="fill-tab-example" className="mb-3" fill>
                                {product.hasTechnicalSpecification === true ?
                                <Tab eventKey="technical-specifications" title="Teknik Özellikler">
                                    <Table responsive striped bordered>
                                        <tbody>
                                        {product.technicalSpecification.map((technicalData) => 
                                            <tr>
                                                {technicalData.map((data) => <th className='productInfoTable-2'>{data}</th>)}
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </Tab> : null}
                                {product.hasExplanation === true ?
                                <Tab eventKey="description" title="Açıklama">
                                    {product.explanation.map((data) => <p className='productOverviewP'><strong className='productOverviewP-2'>{data[0]}</strong> {data[1]}</p>)}
                                </Tab> : null}
                                {product.hasStandartEquipment === true ?
                                <Tab eventKey="standard-equipment" title="Standart Ekipman">
                                    <Table responsive striped bordered>
                                        <tbody>
                                            {product.standardEquipment.map((data) => 
                                            <tr>
                                                <th className='productInfoTable-2'>
                                                    {data}
                                                </th>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </Tab> : null}
                                {product.hasAccessory === true ?
                                <Tab eventKey="accessories" title="Aksesuarlar">
                                    <Table responsive striped bordered>
                                        <tbody>
                                            <tr>
                                                <th className='productInfoTable-2'>Sipariş No</th>
                                                <th className='productInfoTable-2'>Açıklama</th>
                                                <th className='productInfoTable-3'>Görsel</th>
                                            </tr>
                                            {product.accessory.map((technicalData) => 
                                            <tr>
                                                <th className='productInfoTable-2'>{technicalData[0]}</th>
                                                <th className='productInfoTable-2'>{technicalData[1]}</th>
                                                <th className='productInfoTable-3'>
                                                    <Image src={technicalData[2]} alt={category.name} fluid className='accessoryTableImage'/>
                                                </th>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </Tab> : null}
                                {product.hasDocument === true ?
                                <Tab eventKey="documents" title="Dökümanlar">
                                    <Table responsive striped bordered>
                                    <tbody>
                                        {product.document.map((data) => 
                                        <tr>
                                            <th className='productInfoTable-2'>
                                                <a href={data[0]} target="_blank" rel="noopener noreferrer"><i className="fa-regular fa-file-pdf fa-2xl productDatasheet"/> {data[1]}</a>
                                            </th>
                                        </tr>)}
                                    </tbody>
                                    </Table>
                                </Tab> : null}
                            </Tabs>
                        </Col>  
                    </Col>
                </Row>

                :

                <Row className='errorRow'>
                    <Col xl={12} className='productError'>
                        <p className='productErrorP'>Sayfa Bulunamadı.</p>
                        <p className='error404P'>404</p>
                    </Col>
                </Row>
                }
            
            </Container>

            <Footer />
        </>
    )
}

export default ProductScreen