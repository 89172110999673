import React from 'react'
import Header from './en/components/Header'
import Footer from './en/components/Footer'
import { Container, Row, Col } from 'react-bootstrap'

const ErrorScreen = () => {
    return (
        <>
            <Header />

            <Container>
                <Row className='errorRow'>
                    <Col xl={12} className='productError'>
                        <p className='productErrorP'>Page Not Found</p>
                        <p className='error404P'>404</p>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default ErrorScreen