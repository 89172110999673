import React from 'react'
import { Button, Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AdvertisementCarousel = () => {
    return (
        <section>
            <Carousel interval={6000} controls={false} pause={false}>    
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:450px)' srcset='/tr/s/1500-555/giussani-kuru-blok-kalibrator.jpg' />
                        <Image src='/tr/s/500-750/giussani-kuru-blok-kalibrator.jpg' alt='giussani kuru blok kalibrator' className='carouselImage' fluid/>
                    </picture>
                    <Carousel.Caption>
                        <h2 className='carouselHeader'>Türkiye Azerbaycan Libya<br/>GIUSSANI Distribütörü</h2>
                        <p className='carouselP'>Denizcilik Sektörü İçin DNV Sertifikalı PYROS 650 Kuru Blok Kalibratör</p>
                        <p className='carouselSecondP'>Kuru Blok Kalibratör, Sıcaklık Kalibrasyon Banyosu, Test Cihazı</p>
                        <Link to={'urunler/kuru-blok-kalibratorler/'}>
                            <Button className='carouselButton-2'>
                                    Ürünleri İncele
                            </Button>
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:450px)' srcset='/tr/s/1500-555/tunkia-elektriksel-test-cihazi.jpg' />
                        <Image src='/tr/s/500-750/tunkia-elektriksel-test-cihazi.jpg' className='carouselImage' alt='tunkia elektriksel test cihazi' fluid/>
                    </picture>
                    <Carousel.Caption>
                        <h2 className='carouselHeader'>TUNKIA Türkiye Distribütörü</h2>
                        <p className='carouselP'>Elektrikli Araç Şarj İstasyonu Test Cihazı, Elektriksel Test Cihazı</p>
                        <p className='carouselSecondP'>Yüksek Gerilim Test Cihazı, Elektriksel Kalibrasyon Cihazı</p>
                        <Link to={'urunler/elektriksel-test-cihazlari/'}>
                            <Button className='carouselButton'>
                                    Ürünleri İncele
                            </Button>
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <picture>
                        <source media='(min-width:450px)' srcset='/tr/s/1500-555/demei-instrument-sicaklik-kalibratoru.jpg' />
                        <Image src='/tr/s/500-750/demei-instrument-sicaklik-kalibratoru.jpg' alt='demei instrument sıcaklık kalibratörü' className='carouselImage' fluid/>
                    </picture>
                    <Carousel.Caption>
                        <h2 className='carouselHeader-2'>DEMEI INSTRUMENT Türkiye Distribütörü</h2>
                        <p className='carouselP-2'>Sıcaklık Nem Kalibrasyon ve Test Kabini, Kuru Blok Sıcaklık Kalibratörü</p>
                        <p className='carouselSecondP-2'>Siyah Cisim Infrared Kalibrasyon Fırını, Basınç Kalibratörü</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </section>
    )
}

export default AdvertisementCarousel