import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Meta from '../components/Meta'

const ServiceScreen = () => {
    return (
        <>
            <Meta 
            title='Hizmetler - Kuru Blok Kalibratör, Sıcaklık Kalibrasyon Banyosu' 
            description='Kuru blok kalibratör, sıcaklık kalibrasyon banyosu, elektriksel test cihazı, ısıtmalı soğutmalı sirkülatör, kalibrasyon fırını, sıcaklık nem kalibrasyon ve test kabini, siyah cisim infrared kalibrasyon fırını, test ve ölçüm cihazı.'
            keywords='kuru blok kalibratör, sıcaklık kalibrasyon banyosu, sıcaklık kalibratörü, kalibrasyon banyosu, elektriksel test cihazı, sıcaklık nem kalibrasyon ve test kabini, nem kabini, iklimlendirme kabini, soğutmalı sirkülatör, kalibrasyon fırını' />

            <Header />

            <Container>
                <Row>
                    <h1 className='header-1'>HİZMETLER</h1>
                    <Col md={6} lg={6} xl={6} className='serviceCol'>
                        <Col className='serviceCol-2'>
                            <h2 className='serviceHeader'>
                            TEKNİK SERVİS
                            </h2>
                            <p className='serviceP'>
                            TKS Test ve Kalibrasyon Sistemleri sektördeki tecrübesi sayesinde Sıcaklık Kalibrasyon Banyoları, Kuru Blok Kalibratörler gibi birçok alanda test ve ölçüm cihazları ile özel sektör ve kamu kurumlarının ihtiyaç duyduğu çözüm ortağı olmayı sürdürmektedir. Gerektiğinde geniş hizmet yelpazemiz sayesinde bakım ve onarım konusunda size hızlı ve kolay bir şekilde yardımcı olabiliriz.
                            </p>
                            <Image src='/../tr/s/600-300/teknik-servis.jpg' alt='teknik servis' className='serviceImage' fluid/>
                        </Col>
                    </Col>
                    <Col md={6} lg={6} xl={6} className='serviceCol'>
                        <Col className='serviceCol-2'>
                            <h2 className='serviceHeader'>
                            ÖZEL TASARIM VE MARKA TALEBİ
                            </h2>
                            <p className='serviceP'>
                            Talep ettiğiniz gereksinimleri, standart sistemlerimiz sağlamıyor mu? Mevcut sistemleri geliştirerek veya yeni sistemler oluşturarak özel gereksinimleriniz çözülebilir mi? Gereksinimleriniz ve istekleriniz doğrultusanda sistemlerimizi geliştirebilir veya yeni sistemler oluşturabiliriz. Soru ve isteklerinizle ilgili olarak ekibimizle iletişime geçiniz.
                            </p>
                            <Image src='/../tr/s/600-300/sicaklik-kalibratoru.jpg' alt='sıcaklık kalibratörü' className='serviceImage' fluid/>
                        </Col>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default ServiceScreen