const categoriesTr = [
    {
        isActive: true,
        id: 'C01',
        topCategoryId: null,
        url: 'sicaklik-kalibrasyon-banyolari',
        imageS: '/tr/s/400-225/sicaklik-kalibrasyon-banyolari.jpg',
        imageM: '/tr/s/800-450/sicaklik-kalibrasyon-banyolari.jpg',
        name: 'Sıcaklık Kalibrasyon Banyoları',
        description: 'Sıcaklık Kalibrasyon Banyoları, rezistans termometre sıcaklık sensörleri (RTD), platin rezistans termometreler (PRT, SPRT), termokupllar gibi sıcaklık sensörlerinin daha hassas kalibrasyonunu sağlamak için bir sıvının ısıtılmasını veya soğutulmasını sağlayan sabit sıcaklık kaynaklarıdır. Kalibrasyon için ölçülecek sıcaklığa bağlı olarak sıcaklık kalibrasyon banyoları ya su ile ya da belirli yağ türleri ile doldurulur. Daha yüksek bir sıcaklık homejenliği ve stabilitesi ile en düşük kalibrasyon belirsizliklerini elde edebilmek için uzmanlar kuru blok kalibratörler yerine sıcaklık kalibrasyon banyolarını önermektedir. Kalibrasyon banyoları ya portatif aletler şeklinde ya da büyük, kalıcı olarak laboratuvara kurulmuş olabilir.',
        keywords: 'sıcaklık kalibrasyon banyosu, kalibrasyon banyosu, sıcaklık kalibratörü',
    },
    {
        isActive: true,
        id: 'C02',
        topCategoryId: null,
        url: 'kuru-blok-kalibratorler',
        imageS: '/tr/s/400-225/kuru-blok-kalibratorler.jpg',
        imageM: '/tr/s/800-450/kuru-blok-kalibratorler.jpg',
        name: 'Kuru Blok Kalibratörler',
        description: 'Kuru Blok Kalibratörler, rezistans termometre sıcaklık sensörleri (RTD), termometreler, termokupllar gibi sıcaklık sensörlerinin kalibrasyonları için kullanılan sabit sıcaklık kaynaklarıdır. Kalibratörlerde daha doğru sonuçlar elde etmek için kontrol edilen sıcaklık probu ile etrafında hava akışını önlemek için maksimum birkaç milimetre boşluk olmalıdır. Bu yüzden Kuru Blok Kalibratörler farklı çaplardaki termometreler, termokupllar ile iyi bir termal temas elde edebilmek için değiştirilebilir kuru bloklara sahiptir. Kuru Blok Sıcaklık Kalibratörleri hızlıdır ve kolay taşınabilirliği sayesinde mobil kalibrasyon hizmeti veren kalibrasyon laboratuvarlarına kolaylık sağlar.',
        keywords: 'kuru blok kalibratör, kalibrasyon fırını, sıcaklık kalibratörü, termokupl kalibrasyon fırını',
    },
    {
        isActive: true,
        id: 'C10',
        topCategoryId: null,
        url: 'elektriksel-test-cihazlari',
        imageS: '/tr/s/400-225/elektriksel-test-cihazlari.jpg',
        imageM: '/tr/s/800-450/elektriksel-test-cihazlari.jpg',
        name: 'Elektriksel Test Cihazları',
        description: null,
        keywords: 'elektriksel test cihazları, elektriksel ölçüm cihazları, elektriksel kalibrasyon',
    },
    {
        isActive: true,
        id: 'C03',
        topCategoryId: null,
        url: 'isitmali-sogutmali-sirkulatorler',
        imageS: '/tr/s/400-225/isitmali-sogutmali-sirkulatorler.jpg',
        imageM: '/tr/s/800-450/isitmali-sogutmali-sirkulatorler.jpg',
        name: 'Isıtmalı Soğutmalı Sirkülatörler',
        description: 'Isıtmalı Soğutmalı Sirkülatörler, çok çeşitli sıcaklık kontrollerinin gerekli olduğu endüstriyel ve laboratuvar uygulamaları için bir sıvının ısıtılmasını veya soğutulmasını sağlayan sabit sıcaklık kaynaklarıdır. Isıtmalı soğutmalı sirkülatörler sıcaklık kalibrasyon banyolarına göre daha geniş hacimlidir. Sıcaklığa bağlı olarak genellikle ısıtmalı soğutmalı sirkülatörler ya su ile ya da belirli yağ türleri ile doldurulur. Isıtmalı soğutmalı sirkülatörler ya portatif aletler şeklinde ya da büyük, kalıcı olarak laboratuvara kurulmuş olabilir.',
        keywords: 'soğutmalı sirkülatör, ısıtmalı soğutmalı sirkülatör, sirkülatör',
    },
    {
        isActive: true,
        id: 'C11',
        topCategoryId: null,
        url: 'sicaklik-nem-kalibrasyon-ve-test-kabinleri',
        imageS: '/tr/s/400-225/sicaklik-nem-kalibrasyon-ve-test-kabinleri.jpg',
        imageM: '/tr/s/800-450/sicaklik-nem-kalibrasyon-ve-test-kabinleri.jpg',
        name: 'Sıcaklık Nem Kalibrasyon ve Test Kabinleri',
        description: 'Sıcaklık Nem Kalibrasyon ve Test Kabinleri sahip olduğu yüksek stabilite ve homojenite sayesinde sıcaklık ve nem ölçerlerin kalibrasyonunda, testlerinde ve farklı birçok uygulamada kullanılabilmektedir. 5% RH ... 95% RH nem oranlarında çeşitli kontrollerin gerekli olduğu endüstriyel ve laboratuvar uygulamaları için geniş hacim sunan Sıcaklık Nem Kalibrasyon ve Test Kabinleri.',
        keywords: 'sıcaklık nem kalibrasyon ve test kabini, nem kabini, nem test kabini',
    },
    {
        isActive: true,
        id: 'C05',
        topCategoryId: null,
        url: 'siyah-cisim-infrared-kalibrasyon-firinlari',
        imageS: '/tr/s/400-225/siyah-cisim-infrared-kalibrasyon-firinlari.jpg',
        imageM: '/tr/s/800-450/siyah-cisim-infrared-kalibrasyon-firinlari.jpg',
        name: 'Siyah Cisim Infrared Kalibrasyon Fırınları',
        description: 'Siyah Cisim (Infrared Kalibratör) sıcaklık kalibrasyon banyoları ve ısıtmalı soğutmalı sirkülatörler gibi sabit sıcaklık kaynaklarında, kulaktan ateş ölçer ve alından ateş ölçer infrared termometrelerin kalibrasyonu için kullanılmaktadır. Kulaktan ateş ölçer ve alından ateş ölçer infrared termometrelerin kalibrasyonunda en düşük kalibrasyon belirsizliklerini elde edebilmek için uzmanlar kuru ortamdaki infrared kalibratörler yerine sıvı ortamlarda kullanılan siyah cisimleri önermektedir. Amerikan Standartlarına (ASTM E1965, ASTM E2847) göre tasarlanıp üretilen Siyah Cisim genellikle kalibrasyon ve test laboratuvarlarında kullanılmaktadır.',
        keywords: 'infrared kalibratör, infrared sıcaklık kalibratörü, infrared kalibrasyon fırını',
    },
    {
        isActive: true,
        id: 'C09',
        topCategoryId: null,
        url: 'dewar-kaplari-sivi-azot-tanklari',
        imageS: '/tr/s/400-225/dewar-kaplari-sivi-azot-tanklari.jpg',
        imageM: '/tr/s/800-450/dewar-kaplari-sivi-azot-tanklari.jpg',
        name: 'Dewar Kapları',
        description: null,
        keywords: 'dewar kabı, sıvı azot tankları, dewar kapları',
    },
    {
        isActive: true,
        id: 'C08',
        topCategoryId: null,
        url: 'oiml-paslanmaz-celik-kutleler',
        imageS: '/tr/s/400-225/oiml-paslanmaz-celik-kutleler.jpg',
        imageM: '/tr/s/800-450/oiml-paslanmaz-celik-kutleler.jpg',
        name: 'OIML Paslanmaz Çelik Kütleler',
        description: "Terazilerin doğru sonuçlar verip vermediğinden emin olmak için teraziler düzenli aralıklarla kalibre edilmelidir. Paslanmaz Çelik Kütleler (Endüstriyel Test Ağırlıkları) terazilerin test ve kalibrasyonu sürecinde kullanılmaktadır. Kütleler (Test Ağırlıkları) ASTM, OIML ve NIST gibi standartlara göre üretilebilirler. En düşük (en doğru) ile en yüksek tolerans arasında, OIML sınıfları E1, E2, F1, F2, M1, M2 ve M3'tür. OIML sınıf ağırlıkları, ABD dışında Avrupa ve Latin Amerika'da daha yaygın olarak kullanılmaktadır.",
        keywords: 'oiml paslanmaz çelik kütleler, kalibrasyon kütle setleri, test ağırlıkları',
    },
    {
        isActive: true,
        id: 'C04',
        topCategoryId: null,
        url: 'test-ve-olcum-cihazlari',
        imageS: '/tr/s/400-225/test-ve-olcum-cihazlari.jpg',
        imageM: '/tr/s/800-450/test-ve-olcum-cihazlari.jpg',
        name: 'Test ve Ölçüm Cihazları',
        description: 'Bir cihazın performansını tanımlayan çeşitli parametreleri test etmek için kullanılan araçlar, yaygın olarak Test ve Ölçüm Cihazları olarak adlandırılır. Test ekipmanı, test edilen cihazın performansını kontrol etmek için sinyaller oluşturur ve yanıtını okur. Test, cihazın ısı, şok, titreşim ve değişken çevresel koşullar gibi dış etkenlere maruz kalması nedeniyle zamanında bozulmaya uğrayan bileşenlerin belirlenmesine yardımcı olur. Voltmetre, Ohmmetre, Ampermetre ve Multimetreler birinci kategorideki en yaygın cihazlardır.',
        keywords: 'ölçü aleti, ölçüm cihazı',
    },
    {
        isActive: true,
        id: 'C06',
        topCategoryId: null,
        url: 'aksesuarlar',
        imageS: '/tr/s/400-225/test-kalibrasyon-aksesuarlari.jpg',
        imageM: '/tr/s/800-450/test-kalibrasyon-aksesuarlari.jpg',
        name: 'Aksesuarlar',
        description: null,
        keywords: 'silikon yağı, ısı transfer tuzu, kalibrasyon aksesuarları, kalibratör taşıma çantası',
    },
]
  
export default categoriesTr