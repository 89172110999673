import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col} from 'react-bootstrap'

const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col xl={4}>
                        <Col className='footerCol'>
                            <p className='footerItem'>
                                <i className="fas fa-info-circle fa-lg" />
                                &nbsp;&nbsp;TKS Test ve Kalibrasyon Sistemleri San. ve Tic. Ltd. Şti.
                            </p>
                        </Col>
                        <Col className='footerCol'>
                            <p className='footerItem'>
                                <i className="fas fa-map-marker-alt fa-lg" />
                                &nbsp;&nbsp;Orta Mahallesi, Tatlıkuyu Sokak, No: 19/1, Pendik/İstanbul, 34896
                            </p>
                        </Col>
                    </Col>

                    <Col xl={4}>
                        <Col className='footerCol'>
                            <a href="mailto:info@tkstest.com.tr" className='footerItem'>
                                <i className="far fa-envelope fa-lg" />
                                &nbsp;&nbsp;info@tkstest.com.tr
                            </a>    
                        </Col>
                        <Col className='footerCol'>
                            <a href="tel:+902163794500" className='footerItem'>
                                <i className="fas fa-phone fa-lg" />
                                &nbsp;&nbsp;+90 216 379 45 00
                            </a>
                        </Col>
                        <Col className='footerCol'>
                            <a href="https://api.whatsapp.com/send?phone=902163794500" target="_blank" rel="noopener noreferrer" className='footerItem'>
                                <i className="fab fa-whatsapp fa-xl" />
                                &nbsp;&nbsp;+90 216 379 45 00
                            </a>  
                        </Col>                       
                    </Col>

                    <Col xl={4}>
                        <Col className='footerCol'>
                            <p className='footerItem'>
                                Kuru blok kalibratör, sıcaklık kalibrasyon banyosu, elektriksel test cihazı, ısıtmalı soğutmalı sirkülatör, kalibrasyon fırını, sıcaklık nem kalibrasyon ve test kabini, siyah cisim infrared kalibrasyon fırını, sıcaklık kalibratörü, test ve ölçüm cihazı.
                            </p>
                        </Col>
                        <Col>
                            <Link to='/tr/' className='footerItem'>
                                Türkçe
                            </Link>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <Link to='/en/' className='footerItem'>
                                English
                            </Link>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer