import React from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

import HomeScreenTr from './tr/screens/HomeScreen'
import AboutScreenTr from './tr/screens/AboutScreen'
import CategoryScreenTr from './tr/screens/CategoryScreen'
import ProductsScreenTr from './tr/screens/ProductsScreen'
import ProductScreenTr from './tr/screens/ProductScreen'
import ServiceScreenTr from './tr/screens/ServiceScreen'
import ReferenceScreenTr from './tr/screens/ReferenceScreen'
import ContactScreenTr from './tr/screens/ContactScreen'

import HomeScreenEn from './en/screens/HomeScreen'
import AboutScreenEn from './en/screens/AboutScreen'
import CategoryScreenEn from './en/screens/CategoryScreen'
import ProductsScreenEn from './en/screens/ProductsScreen'
import ProductScreenEn from './en/screens/ProductScreen'
import ServiceScreenEn from './en/screens/ServiceScreen'
import ReferenceScreenEn from './en/screens/ReferenceScreen'
import ContactScreenEn from './en/screens/ContactScreen'

import ErrorScreen from './ErrorScreen'

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Redirect exact strict from='/tr/urun/kuru-blok-kalibrator-volcano-1200/' to='/tr/urun/dy-gtl1200x-termokupl-kalibrasyon-firini/' />

                <Redirect exact strict from='/tr/home/' to='/tr/' />
                <Redirect exact strict from='/tr/test-ve-kalibrasyon-banyolari-menu/' to='/tr/' />
                <Redirect exact from='/' to='/tr/' />

                <Route exact path='/tr/' component={HomeScreenTr} />
                <Route exact path='/tr/kurumsal/' component={AboutScreenTr} />
                <Route exact path='/tr/urunler/' component={CategoryScreenTr} />
                <Route exact path='/tr/urunler/:id/' component={ProductsScreenTr} />
                <Route exact path='/tr/urun/:id/' component={ProductScreenTr} />
                <Route exact path='/tr/hizmetler/' component={ServiceScreenTr} />
                <Route exact path='/tr/referanslar/' component={ReferenceScreenTr} />
                <Route exact path='/tr/iletisim/' component={ContactScreenTr} />

                <Route exact path='/en/' component={HomeScreenEn} />
                <Route exact path='/en/about/' component={AboutScreenEn} />
                <Route exact path='/en/products/' component={CategoryScreenEn} />
                <Route exact path='/en/products/:id/' component={ProductsScreenEn} />
                <Route exact path='/en/product/:id/' component={ProductScreenEn} />
                <Route exact path='/en/services/' component={ServiceScreenEn} />
                <Route exact path='/en/references/' component={ReferenceScreenEn} />
                <Route exact path='/en/contact/' component={ContactScreenEn} />

                <Route component={ErrorScreen} />
            </Switch>
        </Router>
    )
}

export default App