import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Categories from '../../categoriesTr'

const CategoryScreen = () => {
    return (
        <>
            <Meta 
            title='Ürünler - Kuru Blok Kalibratör, Sıcaklık Kalibrasyon Banyosu' 
            description='Kuru blok kalibratör, sıcaklık kalibrasyon banyosu, elektriksel test cihazı, ısıtmalı soğutmalı sirkülatör, kalibrasyon fırını, sıcaklık nem kalibrasyon ve test kabini, siyah cisim infrared kalibrasyon fırını, test ve ölçüm cihazı.'
            keywords='kuru blok kalibratör, sıcaklık kalibrasyon banyosu, sıcaklık kalibratörü, kalibrasyon banyosu, elektriksel test cihazı, sıcaklık nem kalibrasyon ve test kabini, nem kabini, iklimlendirme kabini, soğutmalı sirkülatör, kalibrasyon fırını' />

            <Header />

            <Container>
                <Row>
                    <h1 className='header-1'>ÜRÜNLER</h1>
                    
                    {Categories.map(category => category.topCategoryId === null && category.isActive === true ?
                        <Col xs={6} sm={6} md={6} lg={4} xl={4} className='categoryCol' key={category.name}>
                            <Link to={`/tr/urunler/${category.url}/`} className='textDecorationNone'>
                                <Col className='categoryItem'>  
                                    <picture>
                                        <source media='(min-width:750px)' srcset={category.imageM} />
                                        <Image src={category.imageS} alt={category.name} className='categoryImage' fluid/> 
                                    </picture>   

                                    <h2 className='categoryHeader'>
                                        {category.name}
                                    </h2>
                                </Col>
                            </Link>
                        </Col> : null)}  
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default CategoryScreen