const productsTr = [
    {
        isActive: true,
        id: 'P0001',
        category: 'C02',
        externalUrl: false,
        url: 'pyros-140-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/pyros-140-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/pyros-140-kuru-blok-kalibrator.jpg',
        image450: [ '/tr/s/450-450/pyros-140-kuru-blok-kalibrator.jpg'],
        name: 'PYROS 140',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [['Marka', 'Giussani'], ['Menşei', 'İtalya'], ['Sıcaklık Aralığı', '-26 °C ... +140 °C'], ['Doğruluk', '±0.25 °C'], ['Stabilite', '±0.10 °C'], ['Kuyu Çapı', '19 mm'], ['Kuyu Derinliği', '104 mm']],
        introduction: 'Giussani PYROS 140 kuru blok kalibratör sahadaki uygulamalar ve denizcilik sektörünün zorlu koşulları için tasarlanmıştır. Isıtma ve soğutma aşamalarında azaltılan tepki süresi ve hızlı stabilizasyon, çok noktalı kalibrasyon işlemlerinde zaman tasarrufu sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 'Giussani PYROS 140 Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "Pyros 140-1H", "Pyros 140-2H"], ["Sıcaklık Aralığı", "-26 °C ... +140 °C", "-26 °C ... +140 °C"], ["Ekran Doğruluğu", "±0.25 °C", "±0.25 °C"], ["Ekran Çözünürlüğü", "0.1 °C", "0.1 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", <>T<sub>Ortam</sub> ... +120 °C: 17 dk</>, <>T<sub>Ortam</sub> ... +120 °C: 20 dk</>], ["Soğuma Süresi (Stabilizasyon dahil)", <>120 °C ... T<sub>Ortam</sub>: 15 dk<br/>T<sub>Ortam</sub> ... -20 °C: 15 dk</>, <>120 °C ... T<sub>Ortam</sub>: 17 dk<br/>T<sub>Ortam</sub> ... -20 °C: 20 dk</>], ["Stabilite", "±0.10 °C", "±0.10 °C"], ["Eksenel Tekdüzelik", <>±0.10 °C (-20 °C'de)<br/>±0.05 °C (0 °C'de)<br/>±0.10 °C (100 °C'de)</>, <>±0.12 °C (-20 °C'de)<br/>±0.04 °C (0 °C'de)<br/>±0.12 °C (100 °C'de)</>], ["Radyal Homojenlik (40 mm'de)", "±0.05 °C (100 °C'de)", "±0.05 °C (100 °C'de)"], ["Kuyu Çapı", "1 x 19 mm", "2 x 13 mm"], ["Kuyu Derinliği", "104 mm", "104 mm"], ["Çalışma Gerilimi", <>115/240 VAC<br/>± 10% 50/60Hz</>, <>115/240 VAC<br/>± 10% 50/60Hz</>], ["Elektrik Gücü", "80 W", "80 W"], ["Boyutlar", "130x260x280 mm", "130x260x280 mm"], ["Ağırlık", "5.5 kg", "5.5 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "PYROS 140-1H: 2D3391 4 Delikli Insert (1 x 6.4 mm, 2 x 4.8 mm, 1 x 3.3 mm), 2D3463 Deliksiz Insert", "PYROS 140-2H: 2D3199-003 1 Delikli Insert (1 x 4.8 mm), 2D3199-004 1 Delikli Insert (1 x 6.4 mm)"],
        hasAccessory: true,
        accessory: [["2MFR526MAX5053", "Rigid Case", '/tr/s/150-150/2MFR526MAX5053.jpg'], ["2TRMBAG-PYROS", "Cordura Soft Bag", '/tr/s/150-150/2TRMBAG-PYROS.jpg'], [<>(PYROS 140-2H)<br/>2D3199-000<br/>2D3199-001<br/>2D3199-002<br/>2D3199-003<br/>2D3199-004<br/>2D3199-005<br/>2D3199-006<br/>2D3199-007</>, <>(PYROS 140-2H)<br/>Deliksiz Insert<br/>1 Delikli Insert (1 x 3.2 mm)<br/>1 Delikli Insert (1 x 4.0 mm)<br/>1 Delikli Insert (1 x 4.8 mm)<br/>1 Delikli Insert (1 x 6.4 mm)<br/>1 Delikli Insert (1 x 7.9 mm)<br/>1 Delikli Insert (1 x 9.5 mm)<br/>1 Delikli Insert (1 x 11.1 mm)</>, '/tr/s/150-150/pyros-140-inserts.jpg']],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0002',
        category: 'C02',
        externalUrl: false,
        url: 'pyros-375-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/pyros-375-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/pyros-375-kuru-blok-kalibrator.jpg',
        image450: [ '/tr/s/450-450/pyros-375-kuru-blok-kalibrator.jpg',],
        name: 'PYROS 375',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [['Marka', 'Giussani'], ['Menşei', 'İtalya'], ['Sıcaklık Aralığı', '+30 °C ... +375 °C'], ['Doğruluk', '±0.35 °C'], ['Stabilite', '±0.10 °C'], ['Kuyu Çapı', '26 mm'], ['Kuyu Derinliği', '150 mm']],
        introduction: 'DNV sertifikalı Giussani PYROS 375 kuru blok kalibratör sahadaki uygulamalar ve denizcilik sektörünün zorlu koşulları için tasarlanmıştır. Gemilerin zorlu koşulları için kompakt boyutlu, kolay taşınabilir kuru blok sıcaklık kalibratörü. Isıtma ve soğutma aşamalarında azaltılan tepki süresi ve hızlı stabilizasyon, çok noktalı kalibrasyon işlemlerinde zaman tasarrufu sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 'Giussani PYROS 375 Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "Pyros 375"], ["Sıcaklık Aralığı", "+30 °C ... +375 °C"], ["Ekran Doğruluğu", "±0.35 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", <>T<sub>Ortam</sub> ... +375 °C: 15 dk</>], ["Soğuma Süresi (Stabilizasyon dahil)", "+375 °C ... +50 °C: 60 dk"], ["Stabilite", "±0.10 °C"], ["Eksenel Tekdüzelik", <>±0.02 °C (50 °C'de)<br/>±0.2 °C (200 °C'de)<br/>±0.4 °C (375 °C'de)</>], ["Radyal Homojenlik (40 mm'de)", <>±0.1 °C (200 °C'de)<br/>±0.2 °C (375 °C'de)</>], ["Kuyu Çapı", "26 mm"], ["Kuyu Derinliği", "150 mm"], ["Çalışma Gerilimi", <>115/240 VAC<br/>± 10% 50/60Hz</>], ["Elektrik Gücü", "600 W"], ["Boyutlar", "130x260x280 mm"], ["Ağırlık", "5.32 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "2D3332 4 Delikli Insert (1 x 11.1 mm, 1 x 6.4 mm, 1 x 4.8 mm, 1 x 3.2 mm)"],
        hasAccessory: true,
        accessory: [["2MFR526MAX5053", "Rigid Case", '/tr/s/150-150/2MFR526MAX5053.jpg'], ["2TRMBAG-PYROS", "Cordura Soft Bag", '/tr/s/150-150/2TRMBAG-PYROS.jpg'], [<>2D3333<br/>2D3332<br/>2D3331<br/>2D3738</>, <>Deliksiz Insert<br/>4 Delikli Insert (1 x 11.1 mm, 1 x 6.4 mm, 1 x 4.8 mm, 1 x 3.2 mm)<br/>2 Delikli Insert (1 x 12.7 mm, 1 x 6.4 mm)<br/>Siyah Cisim (Black Body)</>, '/tr/s/150-150/pyros-375-inserts.jpg']],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0003',
        category: 'C02',
        externalUrl: false,
        url: 'pyros-650-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/pyros-650-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/pyros-650-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/pyros-650-kuru-blok-kalibrator.jpg',],
        name: 'PYROS 650',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [['Marka', 'Giussani'], ['Menşei', 'İtalya'], ['Sıcaklık Aralığı', '+35 °C ... +650 °C'], ['Doğruluk', '±0.50 °C'], ['Stabilite', '±0.30 °C'], ['Kuyu Çapı', '26 mm'], ['Kuyu Derinliği', '150 mm']],
        introduction: 'DNV sertifikalı Giussani PYROS 650 kuru blok kalibratör sahadaki uygulamalar ve denizcilik sektörünün zorlu koşulları için tasarlanmıştır. Gemilerin zorlu koşulları için kompakt boyutlu, kolay taşınabilir kuru blok sıcaklık kalibratörü. Isıtma ve soğutma aşamalarında azaltılan tepki süresi ve hızlı stabilizasyon, çok noktalı kalibrasyon işlemlerinde zaman tasarrufu sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pyros-series-dry-block-calibrators.pdf', 'Giussani PYROS 650 Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "PYROS 650"], ["Sıcaklık Aralığı", "+35 °C ... +650 °C"], ["Ekran Doğruluğu", "±0.50 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", <>T<sub>Ortam</sub> ... +650 °C: 40 dk</>], ["Soğuma Süresi (Stabilizasyon dahil)", "+650 °C ... +100 °C: 70 dk"], ["Stabilite", "±0.3 °C"], ["Eksenel Tekdüzelik", <>±0.6 °C (250 °C'de)<br/>±0.5 °C (450 °C'de)<br/>±0.5 °C (650 °C'de)</>], ["Radyal Homojenlik (40 mm'de)", <>±0.15 °C (450 °C'de)<br/>±0.6 °C (650 °C'de)</>], ["Kuyu Çapı", "26 mm"], ["Kuyu Derinliği", "150 mm"], ["Çalışma Gerilimi", <>115/240 VAC<br/>± 10% 50/60Hz</>], ["Elektrik Gücü", "600 W"], ["Boyutlar", "130x260x280 mm"], ["Ağırlık", "6.5 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "2D3133 4 Delikli Insert (1 x 10.5 mm, 1 x 7 mm, 1 x 5 mm, 1 x 3.2 mm)"],
        hasAccessory: true,
        accessory: [["2MFR526MAX5053", "Rigid Case", '/tr/s/150-150/2MFR526MAX5053.jpg'], ["2TRMBAG-PYROS", "Cordura Soft Bag", '/tr/s/150-150/2TRMBAG-PYROS.jpg'], [<>2D2927<br/>2D2928<br/>2D2929<br/>2D3033<br/>2D3034<br/>2D3133<br/>2D3738</>, <>Deliksiz Insert<br/>2 Delikli Insert (1 x 12.7 mm, 1 x 6.5 mm)<br/>1 Delikli Insert (1 x 15.7 mm)<br/>3 Delikli Insert (1 x 10.5 mm, 1 x 6.5 mm, 1 x 4.5 mm)<br/>1 Delikli Insert (1 x 17.5 mm)<br/>4 Delikli Insert (1 x 10.5 mm, 1 x 7 mm, 1 x 5 mm, 1 x 3.2 mm)<br/>Siyah Cisim (Black Body)</>, '/tr/s/150-150/pyros-650-inserts.jpg']],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0004',
        category: 'C02',
        externalUrl: false,
        url: 'quartz-633-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/quartz-633-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/quartz-633-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/quartz-633-kuru-blok-kalibrator.jpg',],
        name: 'QUARTZ 633',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [['Marka', 'Giussani'], ['Menşei', 'İtalya'], ['Sıcaklık Aralığı', '-30 °C ... +150 °C'], ['Doğruluk', '±0.15 °C'], ['Stabilite', '±0.03 °C'], ['Kuyu Çapı', '35 mm'], ['Kuyu Derinliği', '135 mm']],
        introduction: 'Giussani QUARTZ 633 kuru blok kalibratör sahip olduğu hızlı tepki süresi, yüksek stabilite ve yüksek doğruluk ile direnç termometrelerini, termokuplları ve sıcaklık sensörlerini kalibre ederken veya doğrularken size zaman ve emek tasarrufu sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-quartz-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-quartz-series-dry-block-calibrators.pdf', 'Giussani QUARTZ 633 Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "QUARTZ 633"], ["Sıcaklık Aralığı", "-30 °C ... +150 °C"], ["Ekran Doğruluğu", "±0.15 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+25 °C ... +140 °C: 40 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+140 °C ... +25 °C: 21 dk"], ["Stabilite", "±0.03 °C"], ["Eksenel Tekdüzelik (60 mm)", "±0.01 °C"], ["Kuyu Çapı", "35 mm"], ["Kuyu Derinliği", "135 mm"], ["Çalışma Gerilimi", "115 veya 240 VAC ± 10% 50/60Hz"], ["Elektrik Gücü", "350 W"], ["Boyutlar", "160x350x360 mm"], ["Ağırlık", "9.3 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "2D1191 6 Delikli Insert (1 x 10.5 mm, 1 x 8.5 mm, 1 x 6.5 mm, 1 x 5.5 mm, 1 x 4.5 mm, 1 x 3.5 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0005',
        category: 'C02',
        externalUrl: false,
        url: 'quartz-50-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/quartz-50-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/quartz-50-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/quartz-50-kuru-blok-kalibrator.jpg',],
        name: 'QUARTZ 50',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [['Marka', 'Giussani'], ['Menşei', 'İtalya'], ['Sıcaklık Aralığı', '-50 °C ... +150 °C'], ['Doğruluk', '±0.15 °C'], ['Stabilite', '±0.03 °C'], ['Kuyu Çapı', '35 mm'], ['Kuyu Derinliği', '135 mm']],
        introduction: 'Giussani QUARTZ 50 kuru blok kalibratör sahip olduğu hızlı tepki süresi, yüksek stabilite ve yüksek doğruluk ile direnç termometrelerini, termokuplları ve sıcaklık sensörlerini kalibre ederken veya doğrularken size zaman ve emek tasarrufu sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-quartz-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-quartz-series-dry-block-calibrators.pdf', 'Giussani QUARTZ 50 Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "QUARTZ 50"], ["Sıcaklık Aralığı", "-50 °C ... +150 °C"], ["Ekran Doğruluğu", "±0.15 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+25 °C ... +140 °C: 23 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+140 °C ... +25 °C: 31 dk"], ["Stabilite", "±0.03 °C"], ["Eksenel Tekdüzelik (60 mm)", "±0.01 °C"], ["Kuyu Çapı", "35 mm"], ["Kuyu Derinliği", "135 mm"], ["Çalışma Gerilimi", "115 veya 240 VAC ± 10% 50/60Hz"], ["Elektrik Gücü", "400 W"], ["Boyutlar", "160x350x360 mm"], ["Ağırlık", "9.3 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "2D1191 6 Delikli Insert (1 x 10.5 mm, 1 x 8.5 mm, 1 x 6.5 mm, 1 x 5.5 mm, 1 x 4.5 mm, 1 x 3.5 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0006',
        category: 'C02',
        externalUrl: false,
        url: 'solar-1100-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/solar-1100-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/solar-1100-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/solar-1100-kuru-blok-kalibrator.jpg',],
        name: 'SOLAR 1100',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [["Marka", "Giussani"], ["Menşei", "İtalya"], ["Sıcaklık Aralığı", "+200 °C ... +1100 °C"], ["Doğruluk", "±3 °C"], ["Stabilite", "±0.30 °C"], ["Kuyu Çapı", "44 mm"], ["Kuyu Derinliği", "220 mm"]],
        introduction: 'Yüksek sıcaklıklardaki saha ve laboratuvar uygulamaları için +1100 °C\'ye kadar çalışma imkanı sağlayan SOLAR 1100 kuru blok kalibratör. Yüksek sıcaklıklarda bile yüksek ısı yalıtımı sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-solar-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-solar-series-dry-block-calibrators.pdf', 'Giussani SOLAR 1100 Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "SOLAR 1100"], ["Sıcaklık Aralığı", "+200 °C ... +1100 °C"], ["Ekran Doğruluğu", "±3 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+30 °C ... +1000 °C: 100 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+1000 °C ... +100 °C: 360 dk"], ["Stabilite", "±0.3 °C"], ["Eksenel Tekdüzelik (40 mm)", "3.6 °C ± 0.3"], ["Kuyu Çapı", "44 mm"], ["Kuyu Derinliği", "220 mm"], ["Çalışma Gerilimi", "115 veya 230 VAC"], ["Elektrik Gücü", "900 W"], ["Boyutlar", "170x330x450 mm"], ["Ağırlık", "12 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "2D3274 4 Delikli Seramik Insert (1 x 13.5 mm, 1 x 11 mm, 1 x 9 mm, 1 x 7 mm)", "2D3273 1 Delikli Seramik Insert (1 x 26 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0007',
        category: 'C02',
        externalUrl: false,
        url: 'solar-1200-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/solar-1200-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/solar-1200-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/solar-1200-kuru-blok-kalibrator.jpg',],
        name: 'SOLAR 1200',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [["Marka", "Giussani"], ["Menşei", "İtalya"], ["Sıcaklık Aralığı", "+200 °C ... +1200 °C"], ["Doğruluk", "±3 °C"], ["Stabilite", "±0.30 °C"], ["Kuyu Çapı", "35 mm"], ["Kuyu Derinliği", "210 mm"]],
        introduction: 'Yüksek sıcaklıklardaki saha ve laboratuvar uygulamaları için +1200 °C\'ye kadar çalışma imkanı sağlayan SOLAR 1200 kuru blok kalibratör. Yüksek sıcaklıklarda bile yüksek ısı yalıtımı sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-solar-series-dry-block-calibrators.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-solar-series-dry-block-calibrators.pdf', 'Giussani SOLAR 1200 Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "SOLAR 1200"], ["Sıcaklık Aralığı", "+200 °C ... +1200 °C"], ["Ekran Doğruluğu", "±3 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+30 °C ... +1000 °C: 100 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+1000 °C ... +100 °C: 360 dk"], ["Stabilite", "±0.3 °C"], ["Eksenel Tekdüzelik (40 mm)", "1.5 °C ± 0.8"], ["Kuyu Çapı", "35 mm"], ["Kuyu Derinliği", "210 mm"], ["Çalışma Gerilimi", "230 VAC"], ["Elektrik Gücü", "900 W"], ["Boyutlar", "160x350x385 mm"], ["Ağırlık", "11.5 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "2D4019 4 Delikli Seramik Insert (1 x 12.8 mm, 1 x 9.5 mm, 1 x 6.4 mm, 1 x 4.8 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0008',
        category: 'C02',
        externalUrl: false,
        url: 'pulsar-35cu-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/pulsar-35cu-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/pulsar-35cu-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/pulsar-35cu-kuru-blok-kalibrator.jpg',],
        name: 'PULSAR 35CU',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [["Marka", "Giussani"], ["Menşei", "İtalya"], ["Sıcaklık Aralığı", "+35 °C ... +600 °C"], ["Doğruluk", "±0.3 °C"], ["Stabilite", "±0.05 °C"], ["Kuyu Çapı", "35 mm"], ["Kuyu Derinliği", "185 mm"]],
        introduction: 'Giussani PULSAR 35CU kuru blok kalibratör sahip olduğu hızlı tepki süresi, yüksek stabilite ve yüksek doğruluk ile direnç termometrelerini, termokuplları ve sıcaklık sensörlerini kalibre ederken veya doğrularken size zaman ve emek tasarrufu sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pulsar-35cu-dry-block-calibrator.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pulsar-35cu-dry-block-calibrator.pdf', 'Giussani PULSAR 35CU Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "PULSAR 35CU"], ["Sıcaklık Aralığı", "+30 °C ... +600 °C"], ["Ekran Doğruluğu", "±0.3 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+30 °C ... +600 °C: 52 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+600 °C ... +100 °C: 65 dk"], ["Stabilite", "±0.05 °C"], ["Eksenel Tekdüzelik (60 mm)", "0.15 °C"], ["Kuyu Çapı", "35 mm"], ["Kuyu Derinliği", "185 mm"], ["Çalışma Gerilimi", "115 veya 230 VAC"], ["Elektrik Gücü", "800 W"], ["Boyutlar", "160x360x350 mm"], ["Ağırlık", "10 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "2D3007 5 Delikli Insert (1 x 12.5 mm, 1 x 8.5 mm, 1 x 6.5 mm, 1 x 5 mm, 1 x 3.5 mm)"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0009',
        category: 'C02',
        externalUrl: false,
        url: 'pulsar-80cu-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/pulsar-80cu-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/pulsar-80cu-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/pulsar-80cu-kuru-blok-kalibrator.jpg',],
        name: 'PULSAR 80CU',
        device: 'Kuru Blok Kalibratör',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [["Marka", "Giussani"], ["Menşei", "İtalya"], ["Sıcaklık Aralığı", "+30 °C ... +550 °C"], ["Doğruluk", "±0.3 °C"], ["Stabilite", "±0.05 °C"], ["Kuyu Çapı", "60 mm"], ["Kuyu Derinliği", "275 mm"]],
        introduction: 'Giussani PULSAR 80CU kuru blok kalibratör sahip olduğu yüksek stabilite, yüksek doğruluk ve geniş kuyu ile direnç termometrelerini, termokuplları ve sıcaklık sensörlerini kalibre ederken veya doğrularken size zaman ve emek tasarrufu sağlar.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-pulsar-80cu-dry-block-calibrator.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-pulsar-80cu-dry-block-calibrator.pdf', 'Giussani PULSAR 80CU Kuru Blok Kalibratör Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "PULSAR 80CU"], ["Sıcaklık Aralığı", "+30 °C ... +550 °C"], ["Ekran Doğruluğu", "±0.3 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Isıtma Süresi", "9 °C/dk"], ["Soğuma Süresi", "1.6 °C/dk"], ["Stabilite", "±0.05 °C"], ["Eksenel Tekdüzelik (120 mm)", "0.3 °C"], ["Kuyu Çapı", "60 mm"], ["Kuyu Derinliği", "275 mm"], ["Çalışma Gerilimi", "230 VAC"], ["Elektrik Gücü", "1700 W"], ["Boyutlar", "170x450x330 mm"], ["Ağırlık", "23 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["Enerji Kablosu", "Insert Cımbızı", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Test Raporu", "İsteğe Bağlı Delikli Insert"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0010',
        category: 'C01',
        externalUrl: false,
        url: 'fluid-100-45-sicaklik-kalibrasyon-banyosu',
        image250: '/tr/s/250-250/fluid-100-45-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/fluid-100-45-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/fluid-100-45-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'FLUID 100-45',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [["Marka", "Giussani"], ["Menşei", "İtalya"], ["Sıcaklık Aralığı", "-35 °C ... +140 °C"], ["Doğruluk", "±0.15 °C"], ["Stabilite", "±0.02 °C"], ["Kuyu Çapı", "45 mm"], ["Kuyu Derinliği", "170 mm"]],
        introduction: 'FLUID 100-45 sıcaklık kalibrasyon banyosu laboratuvarda ve sahada termokuplları, direnç termometreleri ve cam termometreleri kontrol etmek için kullanılan taşınabilir sıvılı sıcaklık kalibratörüdür. Kullanılan akışkanın viskozitesine göre hızı ayarlanabilen manyetik karıştırıcı ile sürekli homojen tutulmaktadır.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 'FLUID 100-45 Sıcaklık Kalibrasyon Banyosu Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "FLUID 100-45"], ["Sıcaklık Aralığı", "-35 °C ... +140 °C"], ["Ekran Doğruluğu", "±0.15 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+25 °C ... +140 °C: 40 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+140 °C ... +25 °C: 100 dk"], ["Stabilite", "±0.02 °C"], ["Eksenel Tekdüzelik", "±0.05 °C"], ["Kuyu Çapı", "45 mm"], ["Kuyu Derinliği", "170 mm"], ["Çalışma Gerilimi", "115 veya 230 VAC"], ["Elektrik Gücü", "350 W"], ["Boyutlar", "160x360x350 mm"], ["Ağırlık", "11 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["FLUID 100-45 Sıcaklık Kalibrasyon Banyosu", "500 ml Silikon Yağı 47V10", "Hazne Kapağı", "Sıvı Boşaltma Sistemi", "Cam Termometre Tutucu", "Enerji Kablosu", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Cordura® Taşıma Çantası"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0011',
        category: 'C01',
        externalUrl: false,
        url: 'fluid-h100-sicaklik-kalibrasyon-banyosu',
        image250: '/tr/s/250-250/fluid-h100-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/fluid-h100-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/fluid-h100-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'FLUID H100',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [["Marka", "Giussani"], ["Menşei", "İtalya"], ["Sıcaklık Aralığı", "-18 °C ... +140 °C"], ["Doğruluk", "±0.15 °C"], ["Stabilite", "±0.02 °C"], ["Kuyu Çapı", "60 mm"], ["Kuyu Derinliği", "170 mm"]],
        introduction: 'FLUID H100 sıcaklık kalibrasyon banyosu laboratuvarda ve sahada termokuplları, direnç termometreleri ve cam termometreleri kontrol etmek için kullanılan taşınabilir sıvılı sıcaklık kalibratörüdür. Kullanılan akışkanın viskozitesine göre hızı ayarlanabilen manyetik karıştırıcı ile sürekli homojen tutulmaktadır.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 'FLUID H100 Sıcaklık Kalibrasyon Banyosu Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "FLUID H100"], ["Sıcaklık Aralığı", "-18 °C ... +140 °C"], ["Ekran Doğruluğu", "±0.15 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+25 °C ... +140 °C: 55 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+140 °C ... +25 °C: 50 dk"], ["Stabilite", "±0.02 °C"], ["Eksenel Tekdüzelik", "±0.05 °C"], ["Kuyu Çapı", "60 mm"], ["Kuyu Derinliği", "170 mm"], ["Çalışma Gerilimi", "115 veya 230 VAC"], ["Elektrik Gücü", "300 W"], ["Boyutlar", "160x360x350 mm"], ["Ağırlık", "11 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["FLUID H100 Sıcaklık Kalibrasyon Banyosu", "500 ml Silikon Yağı 47V10", "Hazne Kapağı", "Sıvı Boşaltma Sistemi", "Cam Termometre Tutucu", "Enerji Kablosu", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu", "Cordura® Taşıma Çantası"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0012',
        category: 'C01',
        externalUrl: false,
        url: 'fluid-200-sicaklik-kalibrasyon-banyosu',
        image250: '/tr/s/250-250/fluid-200-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/fluid-200-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/fluid-200-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'FLUID 200',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [["Marka", "Giussani"], ["Menşei", "İtalya"], ["Sıcaklık Aralığı", "+30 °C ... +200 °C"], ["Doğruluk", "±0.15 °C"], ["Stabilite", "±0.02 °C"], ["Kuyu Çapı", "60 mm"], ["Kuyu Derinliği", "170 mm"]],
        introduction: 'FLUID 200 sıcaklık kalibrasyon banyosu laboratuvarda ve sahada termokuplları, direnç termometreleri ve cam termometreleri kontrol etmek için kullanılan taşınabilir sıvılı sıcaklık kalibratörüdür. Kullanılan akışkanın viskozitesine göre hızı ayarlanabilen manyetik karıştırıcı ile sürekli homojen tutulmaktadır.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 'FLUID 200 Sıcaklık Kalibrasyon Banyosu Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "FLUID 200"], ["Sıcaklık Aralığı", "+30 °C ... +200 °C"], ["Ekran Doğruluğu", "±0.15 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+25 °C ... +140 °C: 25 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+140 °C ... +25 °C: 70 dk"], ["Stabilite", "±0.02 °C"], ["Eksenel Tekdüzelik", "±0.01 °C"], ["Kuyu Çapı", "60 mm"], ["Kuyu Derinliği", "170 mm"], ["Çalışma Gerilimi", "115 veya 230 VAC"], ["Elektrik Gücü", "500 W"], ["Boyutlar", "160x360x350 mm"], ["Ağırlık", "8.3 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["FLUID 200 Sıcaklık Kalibrasyon Banyosu", "500 ml Silikon Yağı 47V20", "500 ml Silikon Yağı 47V10", "Hazne Kapağı", "Sıvı Boşaltma Sistemi", "Cam Termometre Tutucu", "Enerji Kablosu", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0013',
        category: 'C01',
        externalUrl: false,
        url: 'fluid-h200-sicaklik-kalibrasyon-banyosu',
        image250: '/tr/s/250-250/fluid-h200-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/fluid-h200-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/fluid-h200-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'FLUID H200',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [["Marka", "Giussani"], ["Menşei", "İtalya"], ["Sıcaklık Aralığı", "+30 °C ... +250 °C"], ["Doğruluk", "±0.2 °C"], ["Stabilite", "±0.02 °C"], ["Kuyu Çapı", "60 mm"], ["Kuyu Derinliği", "170 mm"]],
        introduction: 'FLUID H200 sıcaklık kalibrasyon banyosu laboratuvarda ve sahada termokuplları, direnç termometreleri ve cam termometreleri kontrol etmek için kullanılan taşınabilir sıvılı sıcaklık kalibratörüdür. Kullanılan akışkanın viskozitesine göre hızı ayarlanabilen manyetik karıştırıcı ile sürekli homojen tutulmaktadır.',
        hasDataSheet: true,
        dataSheet: '/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 
        hasDocument: true,
        document: [['/en/s/giussani-fluid-series-temperature-calibration-baths.pdf', 'FLUID H200 Sıcaklık Kalibrasyon Banyosu Data Sheet']],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "FLUID H200"], ["Sıcaklık Aralığı", "+30 °C ... +250 °C"], ["Ekran Doğruluğu", "±0.2 °C"], ["Ekran Çözünürlüğü", "0.1/0.01 °C"], ["Isıtma Süresi (Stabilizasyon dahil)", "+25 °C ... +140 °C: 25 dk"], ["Soğuma Süresi (Stabilizasyon dahil)", "+140 °C ... +25 °C: 70 dk"], ["Stabilite", "±0.02 °C"], ["Eksenel Tekdüzelik", "±0.01 °C"], ["Kuyu Çapı", "60 mm"], ["Kuyu Derinliği", "170 mm"], ["Çalışma Gerilimi", "115 veya 230 VAC"], ["Elektrik Gücü", "500 W"], ["Boyutlar", "160x360x350 mm"], ["Ağırlık", "8.3 kg"]],
        hasStandartEquipment: true,
        standardEquipment: ["FLUID 200 Sıcaklık Kalibrasyon Banyosu", "500 ml Silikon Yağı 47V20", "500 ml Silikon Yağı 47V10", "Hazne Kapağı", "Sıvı Boşaltma Sistemi", "Cam Termometre Tutucu", "Enerji Kablosu", "Sigortalar", "Termostat Bağlantı Kabloları", "Kullanım Kılavuzu"],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0014',
        category: 'C01',
        externalUrl: false,
        url: 'sicaklik-kalibrasyon-banyosu-mini-200',
        image250: '/tr/s/250-250/mini-200-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/mini-200-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/mini-200-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'MINI 200',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+35 °C ... +200 °C"], ["Doğruluk", "±0.35 °C"], ["Stabilite", "±0.06 °C"], ["Kuyu Çapı", "66 mm"], ["Kuyu Derinliği", "120 mm"]],
        introduction: 'MINI 200 sıcaklık kalibrasyon banyosu laboratuvarda ve sahada termokuplları, direnç termometreleri ve cam termometreleri kontrol etmek için kullanılan taşınabilir sıvılı sıcaklık kalibratörüdür.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "MINI 200"], ["Sıcaklık Aralığı", "+35 °C ... +200 °C"], ["Ekran Doğruluğu", "±0.35 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.06 °C"], ["Kuyu Çapı", "66 mm"], ["Kullanılabilir Kuyu Derinliği", "120 mm"], ["Dolum Hacmi", "0.5 L"], ["Çalışma Gerilimi", "220/230 VAC ± 10% 50/60Hz"], ["Elektrik Gücü", "500 W"], ["Boyutlar (G x D x Y)", "132x210x272 mm"], ["Ağırlık", "6 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'İnkübatör kalibrasyonu, etüv kalibrasyonu ve buharlı sterilizatör otoklav validasyonu gibi ilaç endüstri uygulamaları için yüksek stabilite ve doğruluk sunan MINI 200 sıcaklık kalibrasyon banyosu.'], ['', 'İmalat sektörü plastik enjeksiyon uygulamaları için yüksek stabilite ve doğruluk sunan MINI 200 sıcaklık kalibrasyon banyosu.']],
    },
    {
        isActive: true,
        id: 'P0015',
        category: 'C02',
        externalUrl: false,
        url: 'dy-gtl-d-ultra-soguk-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/dy-gtl-d-ultra-soguk-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/dy-gtl-d-ultra-soguk-kuru-blok-kalibrator.jpg',
        image450: [ '/tr/s/450-450/dy-gtl-d-ultra-soguk-kuru-blok-kalibrator.jpg', '/tr/s/450-450/dy-gtl-d-ultra-soguk-kuru-blok-kalibrator-2.jpg',],
        name: 'DY-GTL-D',
        device: 'Ultra Soğuk Kuru Blok Kalibratör',
        brand: null,
        madeIn: null,
        overview: [["Sıcaklık Aralığı", "-100 °C ... +40 °C"], ["Doğruluk", "±0.2 °C"], ["Ekran Çözünürlüğü", "0.01 °C"], ["Stabilite", "±0.2 °C"], ["Kuyu Çapı", "30 mm"], ["Kuyu Derinliği", "155 mm"], ["Soğutma Süresi", "+20 °C ... -100 °C: 45 dk"]],
        introduction: 'Düşük sıcaklıklardaki saha ve laboratuvar uygulamaları için -100 °C\'ye kadar çalışma imkanı sağlayan DY-GTL-D ultra soğuk kuru blok kalibratör. Ortam sıcaklığından -100 °C\'ye 45 dakikada inerek direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin test, kontrol ve kalibrasyon süreçlerinde zaman tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-100 °C ... +40 °C"], ["Ekran Çözünürlüğü", "0.01 °C"], ["Doğruluk", "±0.2 °C"], ["Stabilite", "±0.2 °C"], ["Soğutma Süresi", "+20 °C ... -100 °C: 45 dk"], ["Kuyu Çapı", "30 mm"], ["Kuyu Derinliği", "155 mm"], ["Ağırlık", "12 kg"], ["Çalışma Gerilimi", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'İnkübatör kalibrasyonu, iklimlendirme test kabini kalibrasyonu, buzdolabı kalibrasyonu ve ultra derin dondurucu kalibrasyonu gibi ilaç endüstri uygulamaları için yüksek stabilite ve doğruluğu kısa sürede sunan DY-GTL-D kuru blok kalibratör.']],
    },
    {
        isActive: true,
        id: 'P0016',
        category: 'C02',
        externalUrl: false,
        url: 'dy-gtl150x-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/dy-gtl150x-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/dy-gtl150x-kuru-blok-kalibrator.jpg',
        image450: [ '/tr/s/450-450/dy-gtl150x-kuru-blok-kalibrator.jpg'],
        name: 'DY-GTL150X',
        device: 'Kuru Blok Kalibratör',
        brand: null,
        madeIn: null,
        overview: [["Sıcaklık Aralığı", "-30 °C ... +150 °C"], ["Doğruluk", "±0.2 °C"], ["Stabilite", "±0.1 °C"], ["Kuyu Çapı", "30 mm"], ["Kuyu Derinliği", "155 mm"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Ağırlık", "8 kg"]],
        introduction: 'DY-GTL150X kuru blok kalibratör sahip olduğu yüksek stabilite ve yüksek doğruluk ile direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin test, kontrol ve kalibrasyon süreçlerinde size zaman ve emek tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-30 °C ... +150 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Doğruluk", "±0.2 °C"], ["Stabilite", "±0.1 °C"], ["Kuyu Çapı", "30 mm"], ["Kuyu Derinliği", "155 mm"], ["Ağırlık", "8 kg"], ["Çalışma Gerilimi", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'İnkübatör kalibrasyonu, etüv kalibrasyonu, iklimlendirme test kabini kalibrasyonu, buzdolabı kalibrasyonu, ultra derin dondurucu kalibrasyonu ve buharlı sterilizatör otoklav validasyonu gibi ilaç endüstri uygulamaları için yüksek stabilite ve doğruluğu kısa sürede sunan DY-GTL150X kuru blok kalibratör.']],
    },
    {
        isActive: true,
        id: 'P0017',
        category: 'C02',
        externalUrl: false,
        url: 'dy-gtl650x-kuru-blok-kalibrator',
        image250: '/tr/s/250-250/dy-gtl1200x-termokupl-kalibrasyon-firini.jpg',
        image350: '/tr/s/350-350/dy-gtl1200x-termokupl-kalibrasyon-firini.jpg',
        image450: [ '/tr/s/450-450/dy-gtl1200x-termokupl-kalibrasyon-firini.jpg'],
        name: 'DY-GTL650X',
        device: 'Kuru Blok Kalibratör',
        brand: null,
        madeIn: null,
        overview: [["Sıcaklık Aralığı", "+50 °C ... +650 °C"], ["Doğruluk", "±0.5 °C"], ["Stabilite", "±0.1 °C"], ["Kuyu Çapı", "34 mm"], ["Kuyu Derinliği", "155 mm"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Radyal Tekdüzelik", "±0.2 °C"]],
        introduction: 'DY-GTL650X kuru blok kalibratör sahip olduğu yüksek stabilite ve yüksek doğruluk ile direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin test, kontrol ve kalibrasyon süreçlerinde size zaman ve emek tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+50 °C ... +650 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Doğruluk", "±0.5 °C"], ["Stabilite", "±0.1 °C"], ["Radyal Tekdüzelik", "±0.2 °C"], ["Kuyu Çapı", "34 mm"], ["Kuyu Derinliği", "155 mm"], ["Ağırlık", "8 kg"], ["Çalışma Gerilimi", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0018',
        category: 'C02',
        externalUrl: false,
        url: 'dy-gtl1200x-termokupl-kalibrasyon-firini',
        image250: '/tr/s/250-250/dy-gtl1200x-termokupl-kalibrasyon-firini.jpg',
        image350: '/tr/s/350-350/dy-gtl1200x-termokupl-kalibrasyon-firini.jpg',
        image450: [ '/tr/s/450-450/dy-gtl1200x-termokupl-kalibrasyon-firini.jpg'],
        name: 'DY-GTL1200X',
        device: 'Termokupl Kalibrasyon Fırını',
        brand: null,
        madeIn: null,
        overview: [["Sıcaklık Aralığı", "+300 °C ... +1200 °C"], ["Doğruluk", "±2.0 °C"], ["Stabilite", "±1.0 °C"], ["Kuyu Çapı", "30 mm"], ["Kuyu Derinliği", "155 mm"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Radyal Tekdüzelik", "±1.0 °C"]],
        introduction: 'Yüksek sıcaklıklardaki saha ve laboratuvar uygulamaları için +1200 °C\'ye kadar çalışma imkanı sağlayan DY-GTL1200X kuru blok kalibratör. Çelik, alüminyum üretimi ve bunun gibi imalat sektörü uygulamaları için taşınabilir termokupl kalibrasyon fırını.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+300 °C ... +1200 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Doğruluk", "±2.0 °C"], ["Stabilite", "±1.0 °C"], ["Radyal Tekdüzelik", "±1.0 °C"], ["Kuyu Çapı", "30 mm"], ["Kuyu Derinliği", "155 mm"], ["Ağırlık", "8 kg"], ["Çalışma Gerilimi", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0019',
        category: 'C01',
        externalUrl: false,
        url: 'sicaklik-kalibrasyon-banyosu-mini-cool-125-5',
        image250: '/tr/s/250-250/mini-cool-125-5-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/mini-cool-125-5-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/mini-cool-125-5-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'MINI COOL 125-5',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "-5 °C ... +125 °C"], ["Doğruluk", "±0.65 °C"], ["Stabilite", "±0.07 °C"], ["Kuyu Çapı", "65 mm"], ["Kuyu Derinliği", "130 mm"]],
        introduction: 'MINI COOL 125-5 sıcaklık kalibrasyon banyosu laboratuvarda ve sahada termokuplları, direnç termometreleri ve cam termometreleri kontrol etmek için kullanılan taşınabilir (Peltier Soğutuculu) sıvılı sıcaklık kalibratörüdür.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "MINI COOL 125-5"], ["Sıcaklık Aralığı", "-5 °C ... +125 °C"], ["Ekran Doğruluğu", "±0.65 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.07 °C"], ["Kuyu Çapı", "65 mm"], ["Kullanılabilir Kuyu Derinliği", "130 mm"], ["Kuyu Derinliği", "150 mm"], ["Dolum Hacmi", "0.4 L"], ["Çalışma Gerilimi", "220/230 VAC ± 10% 50/60Hz"], ["Boyutlar (G x D x Y)", "170x270x275 mm"], ["Ağırlık", "9 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'İnkübatör kalibrasyonu, etüv kalibrasyonu, iklimlendirme test kabini kalibrasyonu, buzdolabı kalibrasyonu ve buharlı sterilizatör otoklav validasyonu gibi ilaç endüstri uygulamaları için yüksek stabilite ve doğruluk sunan MINI COOL 125-5 sıcaklık kalibrasyon banyosu.']],
    },
    {
        isActive: true,
        id: 'P0020',
        category: 'C01',
        externalUrl: false,
        url: 'sicaklik-kalibrasyon-banyosu-mini-cool-125-15',
        image250: '/tr/s/250-250/mini-cool-125-15-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/mini-cool-125-15-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/mini-cool-125-15-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'MINI COOL 125-15',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "-15 °C ... +125 °C"], ["Doğruluk", "±0.75 °C"], ["Stabilite", "±0.07 °C"], ["Kuyu Çapı", "25 mm"], ["Kuyu Derinliği", "130 mm"]],
        introduction: 'MINI COOL 125-15 sıcaklık kalibrasyon banyosu laboratuvarda ve sahada termokuplları, direnç termometreleri ve cam termometreleri kontrol etmek için kullanılan taşınabilir (Peltier Soğutuculu) sıvılı sıcaklık kalibratörüdür.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "MINI COOL 125-15"], ["Sıcaklık Aralığı", "-15 °C ... +125 °C"], ["Ekran Doğruluğu", "±0.75 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.07 °C"], ["Kuyu Çapı", "25 mm"], ["Kullanılabilir Kuyu Derinliği", "130 mm"], ["Kuyu Derinliği", "150 mm"], ["Çalışma Gerilimi", "220/230 VAC ± 10% 50/60Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'İnkübatör kalibrasyonu, etüv kalibrasyonu, iklimlendirme test kabini kalibrasyonu, buzdolabı kalibrasyonu ve buharlı sterilizatör otoklav validasyonu gibi ilaç endüstri uygulamaları için yüksek stabilite ve doğruluk sunan MINI COOL 125-15 sıcaklık kalibrasyon banyosu.']],
    },
    {
        isActive: true,
        id: 'P0021',
        category: 'C01',
        externalUrl: false,
        url: 'sicaklik-kalibrasyon-tuz-banyosu-duden-550l',
        image250: '/tr/s/250-250/duden-550l-sicaklik-kalibrasyon-tuz-banyosu.jpg',
        image350: '/tr/s/350-350/duden-550l-sicaklik-kalibrasyon-tuz-banyosu.jpg',
        image450: [  '/tr/s/450-450/duden-550l-sicaklik-kalibrasyon-tuz-banyosu.jpg',],
        name: 'DUDEN 550L',
        device: 'Yüksek Sıcaklık Kalibrasyon Tuz Banyosu',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+180 °C ... +550 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.06 °C"], ["Kuyu Çapı", "158 mm"], ["Kuyu Derinliği", "500 mm"]],
        introduction: 'Isı transfer tuzu ile sıvı ortamda yüksek sıcaklıklarda test ve kalibrasyon uygulamaları için +550 °C\'ye kadar çalışma imkanı sağlayan DUDEN 550L Yüksek Sıcaklık Kalibrasyon Tuz Banyosu.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+180 °C ... +550 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.06 °C (+200 °C'de), ±0.1 °C (+500 °C'de)"], ["Hazne Çapı", "158 mm (Etkin kullanım alanı %55)"], ["Hazne Derinliği", "500 mm"], ["Dolum Hacmi", "9 L"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "500 mm × 1172 mm × 638 mm"], ["Ağırlık", "75 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0022',
        category: 'C01',
        externalUrl: false,
        url: 'sicaklik-kalibrasyon-banyosu-duden-300s',
        image250: '/tr/s/250-250/sicaklik-kalibrasyon-banyosu-duden-300s.jpg',
        image350: '/tr/s/350-350/sicaklik-kalibrasyon-banyosu-duden-300s.jpg',
        image450: [  '/tr/s/450-450/sicaklik-kalibrasyon-banyosu-duden-300s.jpg',],
        name: 'DUDEN 300S',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+35 °C ... +300 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.03 °C"], ["Kuyu Çapı", "100 mm"], ["Kuyu Derinliği", "200 mm"]],
        introduction: 'DUDEN 300S serisi sıcaklık kalibrasyon banyosu +35 °C ile +300 °C sıcaklıkları arasında direnç termometrelerinin, cam termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş erişim açıklığı sayesinde aynı anda fazla sayıda termometrenin test ve kalibrasyon sürecinde zaman tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+35 °C ... +300 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.03 °C"], ["Hazne Çapı", "100 mm"], ["Hazne Derinliği", "200 mm"], ["Dolum Hacmi", "3.5 L"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "255 mm × 475 mm × 420 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0023',
        category: 'C01',
        externalUrl: false,
        url: 'sicaklik-kalibrasyon-banyosu-duden-150-20',
        image250: '/tr/s/250-250/sicaklik-kalibrasyon-banyosu-duden-150-20.jpg',
        image350: '/tr/s/350-350/sicaklik-kalibrasyon-banyosu-duden-150-20.jpg',
        image450: [  '/tr/s/450-450/sicaklik-kalibrasyon-banyosu-duden-150-20.jpg',],
        name: 'DUDEN -20 Serisi',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "-20 °C ... +100 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.03 °C"], ["Kuyu Çapı", "100 mm"], ["Kuyu Derinliği", "200 mm"]],
        introduction: 'DUDEN -20 serisi sıcaklık kalibrasyon banyosu -20 °C ile +100 °C sıcaklıkları arasında direnç termometrelerinin, cam termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş erişim açıklığı sayesinde aynı anda fazla sayıda termometrenin test ve kalibrasyon sürecinde zaman tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-20 °C ... +100 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.03 °C"], ["Hazne Çapı", "100 mm"], ["Hazne Derinliği", "200 mm"], ["Dolum Hacmi", "3.5 L"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0024',
        category: 'C01',
        externalUrl: false,
        url: 'sicaklik-kalibrasyon-banyosu-duden-150-40',
        image250: '/tr/s/250-250/sicaklik-kalibrasyon-banyosu-duden-150-40.jpg',
        image350: '/tr/s/350-350/sicaklik-kalibrasyon-banyosu-duden-150-40.jpg',
        image450: [  '/tr/s/450-450/sicaklik-kalibrasyon-banyosu-duden-150-40.jpg',],
        name: 'DUDEN -40 Serisi',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "-40 °C ... +100 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.03 °C"], ["Kuyu Çapı", "100 mm"], ["Kuyu Derinliği", "200 mm"]],
        introduction: 'DUDEN -40 serisi sıcaklık kalibrasyon banyosu -20 °C ile +100 °C sıcaklıkları arasında direnç termometrelerinin, cam termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş erişim açıklığı sayesinde aynı anda fazla sayıda termometrenin test ve kalibrasyon sürecinde zaman tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-40 °C ... +100 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.03 °C"], ["Hazne Çapı", "100 mm"], ["Hazne Derinliği", "200 mm"], ["Dolum Hacmi", "3.5 L"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "320 mm × 635 mm × 420 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0025',
        category: 'C01',
        externalUrl: false,
        url: 'sicaklik-kalibrasyon-banyosu-duden-100-80',
        image250: '/tr/s/250-250/sicaklik-kalibrasyon-banyosu-duden-100-80.jpg',
        image350: '/tr/s/350-350/sicaklik-kalibrasyon-banyosu-duden-100-80.jpg',
        image450: [  '/tr/s/450-450/sicaklik-kalibrasyon-banyosu-duden-100-80.jpg',],
        name: 'DUDEN -80 Serisi',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "-80 °C ... +50 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.03 °C"], ["Kuyu Çapı", "100 mm"], ["Kuyu Derinliği", "200 mm"]],
        introduction: 'DUDEN -80 serisi sıcaklık kalibrasyon banyosu -20 °C ile +100 °C sıcaklıkları arasında direnç termometrelerinin, cam termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş erişim açıklığı sayesinde aynı anda fazla sayıda termometrenin test ve kalibrasyon sürecinde zaman tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-80 °C ... +50 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Stabilite", "±0.03 °C"], ["Hazne Çapı", "100 mm"], ["Hazne Derinliği", "200 mm"], ["Dolum Hacmi", "3.5 L"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "415 mm × 840 mm × 615 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0026',
        category: 'C01',
        externalUrl: false,
        url: 'dy-rts30m-sicaklik-kalibrasyon-banyosu',
        image250: '/tr/s/250-250/dy-rts30m-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/dy-rts30m-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/dy-rts30m-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'DY-RTS30M',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: null,
        madeIn: null,
        overview: [["Sıcaklık Aralığı", "-30 °C ... +95 °C"], ["Ekran Çözünürlüğü", "0.001 °C"], ["Stabilite", "±0.01 °C"], ["Hazne Çapı", "100 mm"], ["Hazne Derinliği", "300 mm"], ["Dolum Hacmi", "6.5 L"]],
        introduction: 'DY-RTS30M sıcaklık kalibrasyon banyosu -30 °C ile +95 °C sıcaklıkları arasında direnç termometrelerinin, cam termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş erişim açıklığı sayesinde aynı anda fazla sayıda termometrenin test ve kalibrasyon sürecinde zaman tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-30 °C ... +95 °C"], ["Ekran Çözünürlüğü", "0.001 °C"], ["Stabilite", "±0.01 °C"], ["Radyal Sıcaklık Farkı", "±0.01 °C"], ["Eksenel Sıcaklık Farkı", "±0.02 °C"], ["Hazne Çapı", "100 mm"], ["Hazne Derinliği", "300 mm"], ["Dolum Hacmi", "6.5 L"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar", "310 mm × 420 mm × 500 mm"], ["Ağırlık", "27 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0027',
        category: 'C01',
        externalUrl: false,
        url: 'dy-rts60-sicaklik-kalibrasyon-banyosu',
        image250: '/tr/s/250-250/dy-rts60-sicaklik-kalibrasyon-banyosu.jpg',
        image350: '/tr/s/350-350/dy-rts60-sicaklik-kalibrasyon-banyosu.jpg',
        image450: [  '/tr/s/450-450/dy-rts60-sicaklik-kalibrasyon-banyosu.jpg',],
        name: 'DY-RTS60',
        device: 'Sıcaklık Kalibrasyon Banyosu',
        brand: null,
        madeIn: null,
        overview: [["Sıcaklık Aralığı", "-60 °C ... +95 °C"], ["Ekran Çözünürlüğü", "0.001 °C"], ["Stabilite", "±0.01 °C"], ["Hazne Çapı", "130 mm"], ["Hazne Derinliği", "480 mm"], ["Dolum Hacmi", "18 L"]],
        introduction: 'DY-RTS60 sıcaklık kalibrasyon banyosu -60 °C ile +95 °C sıcaklıkları arasında direnç termometrelerinin, cam termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş erişim açıklığı sayesinde aynı anda fazla sayıda termometrenin test ve kalibrasyon sürecinde zaman tasarrufu sağlar.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-60 °C ... +95 °C"], ["Ekran Çözünürlüğü", "0.001 °C"], ["Stabilite", "±0.01 °C"], ["Radyal Sıcaklık Farkı", "±0.01 °C"], ["Eksenel Sıcaklık Farkı", "±0.02 °C"], ["Hazne Çapı", "130 mm"], ["Hazne Derinliği", "480 mm"], ["Dolum Hacmi", "18 L"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar", "850 mm × 600 mm × 1200 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0028',
        category: 'C02',
        externalUrl: false,
        url: 'kuru-blok-kalibrator-erciyes-marine-650',
        image250: '/tr/s/250-250/marine-serisi-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/marine-serisi-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/marine-serisi-kuru-blok-kalibrator.jpg',],
        name: 'Marine Serisi',
        device: 'Kuru Blok Kalibratör',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+50 °C ... +650 °C"], ["Doğruluk", "±1.5 °C"], ["Stabilite", "±0.3 °C"], ["Kuyu Çapı", "26 mm"], ["Daldırma Derinliği", "125 mm"]],
        introduction: 'Marine serisi kuru blok kalibratör sahadaki uygulamalar ve denizcilik sektörünün zorlu koşulları için tasarlanmıştır. Gemilerin zorlu koşulları için kompakt boyutlu, kolay taşınabilir kuru blok sıcaklık kalibratörü. ',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "Marine 650"], ["Sıcaklık Aralığı", "+50 °C ... +650 °C"], ["Ekran Doğruluğu", "±1.5 °C"], ["Ekran Çözünürlüğü", "1 °C"], ["Stabilite", "±0.3 °C"], ["Stabilizasyon Süresi", "12 dk."], ["Kuyu Çapı", "26 mm"], ["Daldırma Derinliği", "125 mm"], ["Çalışma Gerilimi", "115/230 VAC (± 10%), 50/60 Hz"], ["Elektrik Gücü", "900 W"], ["Boyutlar (G x Y x D)", "122 x 314 x 210 mm"], ["Ağırlık", "5.8 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0029',
        category: 'C02',
        externalUrl: false,
        url: 'kuru-blok-kalibrator-erciyes-650',
        image250: '/tr/s/250-250/erciyes-serisi-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/erciyes-serisi-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/erciyes-serisi-kuru-blok-kalibrator.jpg',],
        name: 'Erciyes Serisi',
        device: 'Kuru Blok Kalibratör',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+50 °C ... +650 °C"], ["Doğruluk", "±1.5 °C"], ["Stabilite", "±0.3 °C"], ["Kuyu Çapı", "35 mm"], ["Daldırma Derinliği", "125 mm"]],
        introduction: 'Erciyes serisi kuru blok kalibratör laboratuvarda ve sahada termokuplları, direnç termometrelerini ve sıcaklık sensörlerini kalibre etmek için kullanılan taşınabilir kuru blok sıcaklık kalibratörüdür.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+50 °C ... +650 °C"], ["Ekran Çözünürlüğü", "1 °C"], ["Doğruluk", "±1.2 °C"], ["Stabilite", "±0.3 °C"], ["Stabilizasyon Süresi", "15 dk"], ["Kuyu Çapı", "35 mm"], ["Daldırma Derinliği", "125 mm"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "153 mm x 312 mm x 242 mm"], ["Ağırlık", "7.6 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0030',
        category: 'C02',
        externalUrl: false,
        url: 'kuru-blok-kalibrator-erciyes-500',
        image250: '/tr/s/250-250/custom-serisi-kuru-blok-kalibrator.jpg',
        image350: '/tr/s/350-350/custom-serisi-kuru-blok-kalibrator.jpg',
        image450: [  '/tr/s/450-450/custom-serisi-kuru-blok-kalibrator.jpg',],
        name: 'Custom Serisi',
        device: 'Kuru Blok Kalibratör',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+35 °C ... +500 °C"], ["Doğruluk", "±1.2 °C"], ["Stabilite", "±0.15 °C"], ["Kuyu Çapı", "70 mm"], ["Daldırma Derinliği", "x ≤ 190 mm"]],
        introduction: 'Custom serisi kuru blok kalibratör +35 °C ile +500 °C sıcaklıkları arasında özel boyutlardaki termostatların, direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin testleri için istenilen delik adetine, çapına ve derinliğine sahip özel tasarlanan taşınabilir sıcaklık kalibratörüdür.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+35 °C ... +500 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Doğruluk", "±1.2 °C"], ["Stabilite", "±0.15 °C"], ["Kuyu Çapı", "70 mm"], ["Blok Delikleri", "Müşteri isteğine göre imal edilir."], ["Kuyu Derinliği", "x ≤ 190 mm (Müşteri isteğine göre imal edilir.)"], ["Kuyu Tipi", "Sabit (Değiştirilemez)"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "153 mm x 312 mm x 242 mm"], ["Ağırlık", "8.2 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0031',
        category: 'C02',
        externalUrl: false,
        url: 'kuru-blok-kalibrator-micro-350',
        image250: '/tr/s/250-250/kuru-blok-kalibrator-micro-350.jpg',
        image350: '/tr/s/350-350/kuru-blok-kalibrator-micro-350.jpg',
        image450: [  '/tr/s/450-450/kuru-blok-kalibrator-micro-350.jpg',],
        name: 'Micro 350',
        device: 'Kuru Blok Kalibratör',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+35 °C ... +350 °C"], ["Doğruluk", "±1.5 °C"], ["Stabilite", "±0.1 °C"], ["Daldırma Derinliği", "80 mm"]],
        introduction: 'Micro 350 kuru blok kalibratör sahada +35 °C ile +350 °C sıcaklıkları arasında direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin kalibrasyon süreçlerinde endüstride kullanılır. 1.2 kg ağırlığında kompakt boyutlu, kolay taşınabilir kuru blok sıcaklık kalibratörü.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+35 °C ... +350 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Doğruluk", "±1.5 °C"], ["Stabilite", "±0.1 °C"], ["Stabilizasyon Süresi", "5 dk"], ["Delikler Arası Sıcaklık Farkı", "±0.1 °C (+200 °C'de), ±0.3 °C (+350 °C'de)"], ["Eksenel Tekdüzelik", "±0.8 °C (+200 °C'de), ±2.1 °C (+350 °C'de)"], ["Daldırma Derinliği", "80 mm"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "11 cm x 7 cm x 16 cm"], ["Ağırlık", "1.2 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0032',
        category: 'C03',
        externalUrl: false,
        url: 'isitmali-sogutmali-sirkulator-abant-health',
        image250: '/tr/s/250-250/abant-health-serisi-isitmali-sogutmali-sirkulator.jpg',
        image350: '/tr/s/350-350/abant-health-serisi-isitmali-sogutmali-sirkulator.jpg',
        image450: [  '/tr/s/450-450/abant-health-serisi-isitmali-sogutmali-sirkulator.jpg',],
        name: 'Abant Health Serisi',
        device: 'Isıtmalı Sirkülatör',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+30 °C ... +100 °C"], ["Stabilite", "±0.03 °C"]],
        introduction: 'Abant Health serisi ısıtmalı sirkülatör +30 °C ile +100 °C sıcaklıkları arasında direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş hacim sayesinde numunelerin test ve kontrol süreçlerinde de kullanılabilir. Abant Health serisi kulaktan ateş ölçer ve alından ateş ölçer infrared termometrelerin kalibrasyonu ve testi için tasarlanmış ASTM E1965 / ASTM E2847 siyah cismi ile beraber kullanılabilir.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+30 °C ... +100 °C"], ["Stabilite", "±0.03 °C"], ["Ekran Tipi", "Dijital"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0033',
        category: 'C03',
        externalUrl: false,
        url: 'isitmali-sogutmali-sirkulator-abant-150-40',
        image250: '/tr/s/250-250/isitmali-sogutmali-sirkulator-abant-150-40.jpg',
        image350: '/tr/s/350-350/isitmali-sogutmali-sirkulator-abant-150-40.jpg',
        image450: [  '/tr/s/450-450/isitmali-sogutmali-sirkulator-abant-150-40.jpg',],
        name: 'Abant -40 Serisi',
        device: 'Isıtmalı Soğutmalı Sirkülatör',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "-40 °C ... +100 °C"], ["Stabilite", "±0.03 °C"]],
        introduction: 'Abant -40 serisi ısıtmalı soğutmalı sirkülatör -40 °C ile +100 °C sıcaklıkları arasında direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş hacim sayesinde numunelerin test ve kontrol süreçlerinde de kullanılabilir.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-40 °C ... +100 °C"], ["Stabilite", "±0.03 °C"], ["Ekran Tipi", "Dijital"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0034',
        category: 'C03',
        externalUrl: false,
        url: 'isitmali-sogutmali-sirkulator-abant-150-20',
        image250: '/tr/s/250-250/isitmali-sogutmali-sirkulator-abant-150-20.jpg',
        image350: '/tr/s/350-350/isitmali-sogutmali-sirkulator-abant-150-20.jpg',
        image450: [  '/tr/s/450-450/isitmali-sogutmali-sirkulator-abant-150-20.jpg',],
        name: 'Abant -20 Serisi',
        device: 'Isıtmalı Soğutmalı Sirkülatör',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "-20 °C ... +100 °C"], ["Stabilite", "±0.03 °C"]],
        introduction: 'Abant -20 serisi ısıtmalı soğutmalı sirkülatör -20 °C ile +100 °C sıcaklıkları arasında direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş hacim sayesinde numunelerin test ve kontrol süreçlerinde de kullanılabilir.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "-20 °C ... +100 °C"], ["Stabilite", "±0.03 °C"], ["Ekran Tipi", "Dijital"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0035',
        category: 'C03',
        externalUrl: false,
        url: 'isitmali-sogutmali-sirkulator-abant-200ss',
        image250: '/tr/s/250-250/isitmali-sogutmali-sirkulator-abant-200ss.jpg',
        image350: '/tr/s/350-350/isitmali-sogutmali-sirkulator-abant-200ss.jpg',
        image450: [  '/tr/s/450-450/isitmali-sogutmali-sirkulator-abant-200ss.jpg',],
        name: 'Abant 200 Serisi',
        device: 'Isıtmalı Sirkülatör',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [["Marka", "TKS"], ["Menşei", "Türkiye"], ["Sıcaklık Aralığı", "+30 °C ... +200 °C"], ["Stabilite", "±0.03 °C"]],
        introduction: 'Abant 200 serisi ısıtmalı irkülatör +30 °C ile +200 °C sıcaklıkları arasında direnç termometrelerinin, termokuplların ve sıcaklık sensörlerinin test ve kalibrasyon süreçlerinde endüstride kullanılır. Sahip olduğu geniş hacim sayesinde numunelerin test ve kontrol süreçlerinde de kullanılabilir.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Sıcaklık Aralığı", "+30 °C ... +200 °C"], ["Stabilite", "±0.03 °C"], ["Ekran Tipi", "Dijital"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Banyo Tankı", "Paslanmaz Çelik"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "255 mm x 475 mm x 420 mm"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0036',
        category: 'C10',
        externalUrl: false,
        url: 'td1858-tasinabilir-cok-fonksiyonlu-kalibrator',
        image250: '/tr/s/250-250/td1858-tasinabilir-cok-fonksiyonlu-kalibrator.jpg',
        image350: '/tr/s/350-350/td1858-tasinabilir-cok-fonksiyonlu-kalibrator.jpg',
        image450: [  '/tr/s/450-450/td1858-tasinabilir-cok-fonksiyonlu-kalibrator.jpg', '/tr/s/450-450/td1858-tasinabilir-cok-fonksiyonlu-kalibrator-2.jpg'],
        name: 'TD1858',
        device: "Taşınabilir Çok Fonksiyonlu Kalibratör",
        brand: 'TUNKIA',
        madeIn: 'Çin',
        overview: [["Marka", "TUNKIA"], ['Doğruluk', '100 ppm']],
        introduction: 'TD1858 yüksek hassasiyetli ve çok fonksiyonlu bir kalibratördür. AC\\DC voltaj kaynağı, AC\\DC akım kaynağı, DC direnç kaynağı vb. entegre eder. Taşınabilirdir ve yerinde kalibrasyon için çok uygundur.',
        hasDataSheet: true,
        dataSheet: '/tr/s/td1858-portable-multifunction-calibrator.pdf', 
        hasDocument: true,
        document: [['/tr/s/td1858-portable-multifunction-calibrator.pdf', 'TD 1858 Taşınabilir Çok Fonksiyonlu Kalibratör Data Sheet']],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0037',
        category: 'C10',
        externalUrl: true,
        url: 'https://www.tunkia.cn/products.html',
        image250: '/tr/s/250-250/tunkia-products.jpg',
        image350: '/tr/s/350-350/tunkia-products.jpg',
        image450: [  '/tr/s/450-450/tunkia-products.jpg',],
        name: 'TUNKIA Ürünleri İçin Tıklayınız',
        device: '',
        brand: null,
        madeIn: null,
        overview: [["Duyuru", "Ürünler yakında web sitemize yüklenecektir. TUNKIA için tıklayınız."]],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0038',
        category: 'C11',
        externalUrl: false,
        url: 'dy-wsx01-sicaklik-nem-kalibrasyon-ve-test-kabini',
        image250: '/tr/s/250-250/dy-wsx01-sicaklik-nem-kalibrasyon-ve-test-kabini.jpg',
        image350: '/tr/s/350-350/dy-wsx01-sicaklik-nem-kalibrasyon-ve-test-kabini.jpg',
        image450: [  '/tr/s/450-450/dy-wsx01-sicaklik-nem-kalibrasyon-ve-test-kabini.jpg', '/tr/s/450-450/dy-wsx01-sicaklik-nem-kalibrasyon-ve-test-kabini-2.jpg', '/tr/s/450-450/dy-wsx01-sicaklik-nem-kalibrasyon-ve-test-kabini-3.jpg'],
        name: 'DY-WSX01',
        device: "Sıcaklık Nem Kalibrasyon ve Test Kabini",
        brand: 'Demei Instrument',
        madeIn: 'Çin',
        overview: [["Marka", "Demei Instrument"], ["Menşei", "Çin"], ["Nem Aralığı", "5% RH ... 95% RH"], ["Sıcaklık Aralığı", "+5 °C ... +50 °C"], ["Nem Stabilitesi", "±0.8% RH"], ["Sıcaklık Stabilitesi", "±0.2 °C"]],
        introduction: 'Sıcaklık nem kalibrasyon ve test kabini sahip olduğu yüksek stabilite ve homojenite sayesinde nem ölçerlerin kalibrasyonunda, testlerinde ve farklı birçok uygulamada kullanılabilmektedir. 5% RH ile 95% RH nem oranlarında çeşitli kontrollerin gerekli olduğu endüstriyel ve laboratuvar uygulamaları için geniş hacim sunan sıcaklık nem kalibrasyon ve test kabini. Sahip olduğu geniş hacim sayesinde numunelerin test ve kontrol süreçlerinde de kullanılabilir.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Nem Aralığı", "5% RH ... 95% RH"], ["Sıcaklık Aralığı", "+5 °C ... +50 °C"], ["Nem Stabilitesi", "±0.8% RH"], ["Sıcaklık Stabilitesi", "±0.2 °C"], ["Ekran Çözünürlüğü", "0.1 °C, 0.1% RH"], ["İç Boyut", "500×500×600 mm"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Ağırlık", "320 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0039',
        category: 'C11',
        externalUrl: true,
        url: 'https://www.demeithermo.com/',
        image250: '/tr/s/250-250/demei-products.jpg',
        image350: '/tr/s/350-350/demei-products.jpg',
        image450: [  '/tr/s/450-450/demei-products.jpg',],
        name: 'DEMEI Ürünleri İçin Tıklayınız',
        device: '',
        brand: null,
        madeIn: null,
        overview: [["Duyuru", "Ürünler yakında web sitemize yüklenecektir. DEMEI için tıklayınız."]],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0040',
        category: 'C05',
        externalUrl: false,
        url: 'dy-ht50m-infrared-kalibrasyon-firini',
        image250: '/tr/s/250-250/dy-ht50m-infrared-kalibrasyon-firini.jpg',
        image350: '/tr/s/350-350/dy-ht50m-infrared-kalibrasyon-firini.jpg',
        image450: [  '/tr/s/450-450/dy-ht50m-infrared-kalibrasyon-firini.jpg', ],
        name: 'DY-HT50M',
        device: 'Infrared Kalibrasyon Fırını',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Model", "DY-HT50M"], ["Sıcaklık Aralığı", "+35 °C ... +500 °C"], ["Ekran Çözünürlüğü", "0.1 °C"], ["Çalışma Gerilimi", "230 VAC (± 10%), 50/60 Hz"], ["Boyutlar (G x Y x D)", "220 x 145 x 250 mm"], ["Ağırlık", "4 kg"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0041',
        category: 'C05',
        externalUrl: false,
        url: 'siyah-cisim-astm-e1965-e2847-infrared-termometre',
        image250: '/tr/s/250-250/siyah-cisim-astm-e1965-e2847-infrared-termometre.jpg',
        image350: '/tr/s/350-350/siyah-cisim-astm-e1965-e2847-infrared-termometre.jpg',
        image450: [  '/tr/s/450-450/siyah-cisim-astm-e1965-e2847-infrared-termometre.jpg', ],
        name: 'ASTM E1965 / ASTM E2847',
        device: 'Siyah Cisim',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'ASTM E1965 / ASTM E2847 siyah cismi sıcaklık kalibrasyon banyoları ve ısıtmalı soğutmalı sirkülatörlere yerleştirilerek, kulaktan ateş ölçer ve alından ateş ölçer infrared termometrelerin kalibrasyonu ve testi için kullanılmaktadır. Amerikan Standartlarına (ASTM E1965, ASTM E2847) göre tasarlanıp üretilen siyah cisim kalibrasyon ve test laboratuvarlarında kullanılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'ASTM E1965 / ASTM E2847 siyah cismi sıcaklık kalibrasyon banyoları ve ısıtmalı soğutmalı sirkülatörlere yerleştirilerek, kulaktan ateş ölçer ve alından ateş ölçer infrared termometrelerin kalibrasyonu ve testi için kullanılmaktadır. Amerikan Standartlarına (ASTM E1965, ASTM E2847) göre tasarlanıp üretilen siyah cisim kalibrasyon ve test laboratuvarlarında kullanılmaktadır.']],
    },
    {
        isActive: true,
        id: 'P0042',
        category: 'C05',
        externalUrl: false,
        url: 'siyah-cisim-astm-e1965-infrared-termometre',
        image250: '/tr/s/250-250/siyah-cisim-astm-e1965-infrared-termometre.jpg',
        image350: '/tr/s/350-350/siyah-cisim-astm-e1965-infrared-termometre.jpg',
        image450: [  '/tr/s/450-450/siyah-cisim-astm-e1965-infrared-termometre.jpg',],
        name: 'ASTM E1965',
        device: 'Siyah Cisim',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'ASTM E1965 siyah cismi sıcaklık kalibrasyon banyoları ve ısıtmalı soğutmalı sirkülatörlere yerleştirilerek, kulaktan ateş ölçer termometrelerin kalibrasyonu ve testi için kullanılmaktadır. Amerikan Standartlarına (ASTM E1965) göre tasarlanıp üretilen siyah cisim kalibrasyon ve test laboratuvarlarında kullanılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'ASTM E1965 siyah cismi sıcaklık kalibrasyon banyoları ve ısıtmalı soğutmalı sirkülatörlere yerleştirilerek, kulaktan ateş ölçer termometrelerin kalibrasyonu ve testi için kullanılmaktadır. Amerikan Standartlarına (ASTM E1965) göre tasarlanıp üretilen siyah cisim kalibrasyon ve test laboratuvarlarında kullanılmaktadır.']],
    },
    {
        isActive: true,
        id: 'P0043',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-e2-sinifi-paslanmaz-celik-kutle-seti',
        image250: '/tr/s/250-250/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',
        image350: '/tr/s/350-350/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',
        image450: [  '/tr/s/450-450/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',],
        name: 'OIML E2 Sınıfı Kütle Setleri',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML E2 sınıfı OIML rehber dökümanına uygun paslanmaz çelikten üretilen paslanmaz çelik kütleler (test ağırlıkları) kalibrasyon laboratuvarında kalibrasyonu yapılıp, sertifikalandırılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Ebatlar:', 'OIML R 111-1'], ['Malzeme:', 'Paslanmaz Çelik'], ['Kütle Seti Seçenekleri:', ''], ['', '1 mg, 2 mg, 2 mg, 5 mg, 10 mg, 20 mg, 20 mg, 50 mg, 100 mg, 200 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 2 g, 5 g, 10 g, 20 g, 20 g, 50 g, 100 g, 200 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 2 kg, 5 kg'], ['Kalibrasyon Sertifikası:', '✓']],
    },
    {
        isActive: true,
        id: 'P0044',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-e2-sinifi-paslanmaz-celik-kutle',
        image250: '/tr/s/250-250/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',
        image350: '/tr/s/350-350/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',
        image450: [  '/tr/s/450-450/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',],
        name: 'OIML E2 Sınıfı Paslanmaz Kütleler',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML E2 sınıfı OIML rehber dökümanına uygun paslanmaz çelikten üretilen paslanmaz çelik kütleler (test ağırlıkları) kalibrasyon laboratuvarında kalibrasyonu yapılıp, sertifikalandırılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Ebatlar:', 'OIML R 111-1'], ['Malzeme:', 'Paslanmaz Çelik'], ['Kütle Seçenekleri:', ''], ['', '1 mg, 2 mg, 5 mg, 10 mg, 20 mg, 50 mg, 100 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 5 g, 10 g, 20 g, 50 g, 100 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Kalibrasyon Sertifikası:', '✓']],
    },
    {
        isActive: true,
        id: 'P0045',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-f1-sinifi-paslanmaz-celik-kutle-seti',
        image250: '/tr/s/250-250/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',
        image350: '/tr/s/350-350/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',
        image450: [  '/tr/s/450-450/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',],
        name: 'OIML F1 Sınıfı Kütle Setleri',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML F1 sınıfı OIML rehber dökümanına uygun paslanmaz çelikten üretilen paslanmaz çelik kütleler (test ağırlıkları) kalibrasyon laboratuvarında kalibrasyonu yapılıp, sertifikalandırılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Ebatlar:', 'OIML R 111-1'], ['Malzeme:', 'Paslanmaz Çelik'], ['Kütle Seti Seçenekleri:', ''], ['', '1 mg, 2 mg, 2 mg, 5 mg, 10 mg, 20 mg, 20 mg, 50 mg, 100 mg, 200 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 2 g, 5 g, 10 g, 20 g, 20 g, 50 g, 100 g, 200 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 2 kg, 5 kg'], ['Kalibrasyon Sertifikası:', 'Opsiyonel olarak talep edilebilir.']],
    },
    {
        isActive: true,
        id: 'P0046',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-f1-sinifi-paslanmaz-celik-kutle',
        image250: '/tr/s/250-250/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',
        image350: '/tr/s/350-350/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',
        image450: [  '/tr/s/450-450/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',],
        name: 'OIML F1 Sınıfı Paslanmaz Kütleler',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML F1 sınıfı OIML rehber dökümanına uygun paslanmaz çelikten üretilen paslanmaz çelik kütleler (test ağırlıkları) kalibrasyon laboratuvarında kalibrasyonu yapılıp, sertifikalandırılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Ebatlar:', 'OIML R 111-1'], ['Malzeme:', 'Paslanmaz Çelik'], ['Kütle Seçenekleri:', ''], ['', '1 mg, 2 mg, 5 mg, 10 mg, 20 mg, 50 mg, 100 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 5 g, 10 g, 20 g, 50 g, 100 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Kalibrasyon Sertifikası:', 'Opsiyonel olarak talep edilebilir.']],
    },
    {
        isActive: true,
        id: 'P0047',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-f2-sinifi-paslanmaz-celik-kutle-seti',
        image250: '/tr/s/250-250/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',
        image350: '/tr/s/350-350/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',
        image450: [  '/tr/s/450-450/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutle-setleri.jpg',],
        name: 'OIML F2 Sınıfı Kütle Setleri',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML F2 sınıfı OIML rehber dökümanına uygun paslanmaz çelikten üretilen paslanmaz çelik kütleler (test ağırlıkları) kalibrasyon laboratuvarında kalibrasyonu yapılıp, sertifikalandırılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Ebatlar:', 'OIML R 111-1'], ['Malzeme:', 'Paslanmaz Çelik'], ['Kütle Seti Seçenekleri:', ''], ['', '1 mg, 2 mg, 2 mg, 5 mg, 10 mg, 20 mg, 20 mg, 50 mg, 100 mg, 200 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 2 g, 5 g, 10 g, 20 g, 20 g, 50 g, 100 g, 200 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 2 kg, 5 kg'], ['Kalibrasyon Sertifikası:', 'Opsiyonel olarak talep edilebilir.']],
    },
    {
        isActive: true,
        id: 'P0048',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-f2-sinifi-paslanmaz-celik-kutle',
        image250: '/tr/s/250-250/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',
        image350: '/tr/s/350-350/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',
        image450: [  '/tr/s/450-450/oiml-e2-f1-f2-sinifi-paslanmaz-celik-kutleler.jpg',],
        name: 'OIML F2 Sınıfı Paslanmaz Kütleler',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML F2 sınıfı OIML rehber dökümanına uygun paslanmaz çelikten üretilen paslanmaz çelik kütleler (test ağırlıkları) kalibrasyon laboratuvarında kalibrasyonu yapılıp, sertifikalandırılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Ebatlar:', 'OIML R 111-1'], ['Malzeme:', 'Paslanmaz Çelik'], ['Kütle Seçenekleri:', ''], ['', '1 mg, 2 mg, 5 mg, 10 mg, 20 mg, 50 mg, 100 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 5 g, 10 g, 20 g, 50 g, 100 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Kalibrasyon Sertifikası:', 'Opsiyonel olarak talep edilebilir.']],
    },
    {
        isActive: true,
        id: 'P0049',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-m1-sinifi-paslanmaz-celik-kutle',
        image250: '/tr/s/250-250/oiml-m1-sinifi-paslanmaz-celik-kutleler.jpg',
        image350: '/tr/s/350-350/oiml-m1-sinifi-paslanmaz-celik-kutleler.jpg',
        image450: [  '/tr/s/450-450/oiml-m1-sinifi-paslanmaz-celik-kutleler.jpg',],
        name: 'OIML M1 Sınıfı Paslanmaz Kütleler',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML M1 Sınıfı, paslanmaz çelik malzemeden imal edilen kütleler (test ağırlıkları) terazi kullanan, kalibrasyon ve doğrulama ihtiyacı duyan tüm işletmelerde ve kalibrasyon laboratuvarlarında kullanılabilir.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Ebatlar:', 'OIML R 111-1'], ['Malzeme:', 'Paslanmaz Çelik'], ['Kütle Seçenekleri:', ''], ['', '1 mg, 2 mg, 5 mg, 10 mg, 20 mg, 50 mg, 100 mg, 200 mg, 500 mg'], ['', '1 g, 2 g, 5 g, 10 g, 20 g, 50 g, 100 g, 200 g, 500 g'], ['', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Kalibrasyon Sertifikası:', 'Opsiyonel olarak talep edilebilir.']],
    },
    {
        isActive: true,
        id: 'P0050',
        category: 'C08',
        externalUrl: false,
        url: 'oiml-m1-sinifi-pik-dokum-kutle',
        image250: '/tr/s/250-250/oiml-m1-sinifi-pik-dokum-kutleler.jpg',
        image350: '/tr/s/350-350/oiml-m1-sinifi-pik-dokum-kutleler.jpg',
        image450: [  '/tr/s/450-450/oiml-m1-sinifi-pik-dokum-kutleler.jpg',],
        name: 'OIML M1 Sınıfı Pik Döküm Kütleler',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'OIML M1 Sınıfı, pik dökümden imal edilen kütleler (test ağırlıkları) terazi kullanan, kalibrasyon ve doğrulama ihtiyacı duyan tüm işletmelerde ve kalibrasyon laboratuvarlarında kullanılabilir.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['Ebatlar:', 'OIML R 111-1'], ['Malzeme:', 'Pik Döküm'], ['Kütle Seçenekleri:', '1 kg, 2 kg, 5 kg, 10 kg, 20 kg, 50 kg, 100 kg, 200 kg, 500 kg, 1000 kg'], ['Kalibrasyon Sertifikası:', 'Opsiyonel olarak talep edilebilir.']],
    },
    {
        isActive: true,
        id: 'P0051',
        category: 'C05',
        externalUrl: false,
        url: 'siyah-cisim-astm-e2847-infrared-termometre',
        image250: '/tr/s/250-250/siyah-cisim-astm-e2847-infrared-termometre.jpg',
        image350: '/tr/s/350-350/siyah-cisim-astm-e2847-infrared-termometre.jpg',
        image450: [  '/tr/s/450-450/siyah-cisim-astm-e2847-infrared-termometre.jpg',],
        name: 'ASTM E2847',
        device: 'Siyah Cisim',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'ASTM E2847 siyah cismi sıcaklık kalibrasyon banyoları ve ısıtmalı soğutmalı sirkülatörlere yerleştirilerek, alından ateş ölçer infrared termometrelerin kalibrasyonu ve testi için kullanılmaktadır. Amerikan Standartlarına (ASTM E2847) göre tasarlanıp üretilen siyah cisim kalibrasyon ve test laboratuvarlarında kullanılmaktadır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'ASTM E2847 siyah cismi sıcaklık kalibrasyon banyoları ve ısıtmalı soğutmalı sirkülatörlere yerleştirilerek, alından ateş ölçer infrared termometrelerin kalibrasyonu ve testi için kullanılmaktadır. Amerikan Standartlarına (ASTM E2847) göre tasarlanıp üretilen siyah cisim kalibrasyon ve test laboratuvarlarında kullanılmaktadır.']],
    },
    {
        isActive: true,
        id: 'P0052',
        category: 'C05',
        externalUrl: true,
        url: 'https://www.demeithermo.com/',
        image250: '/tr/s/250-250/demei-products.jpg',
        image350: '/tr/s/350-350/demei-products.jpg',
        image450: [  '/tr/s/450-450/demei-products.jpg',],
        name: 'DEMEI Ürünleri İçin Tıklayınız',
        device: '',
        brand: null,
        madeIn: null,
        overview: [["Duyuru", "Ürünler yakında web sitemize yüklenecektir. DEMEI için tıklayınız."]],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0053',
        category: 'C06',
        externalUrl: false,
        url: 'silikon-yagi',
        image250: '/tr/s/250-250/silikon-yagi.jpg',
        image350: '/tr/s/350-350/silikon-yagi.jpg',
        image450: [  '/tr/s/450-450/silikon-yagi.jpg',],
        name: 'Silikon Yağı',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Sıcaklık kalibrasyon banyoları ve ısıtmalı soğutmalı sirkülatörler için yüksek sıcaklıklara dayanabilen silikon yağları. DOW silikon yağları (XIAMETER PMX-200 Silikon Yağı 10 cSt, 50 cSt ve daha fazlası). WACKER silikon yağları (AK 50, AK 100, AP 200 ve daha fazlası).',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: true,
        document: [['/en/s/dow-xiameter-pmx-200-5-20.pdf', "Dow Xiameter PMX-200 5 ... 20 cSt Data Sheet"], ['/en/s/dow-xiameter-pmx-200-50-1000.pdf', "Dow Xiameter PMX-200 50 ... 1000 cSt Data Sheet"], ['/en/s/wacker-ap-200.pdf', "Wacker AP 200 Data Sheet"]],
        hasTechnicalSpecification: true,
        technicalSpecification: [["Marka", "Model", "Parlama Noktası"], ["DOW", "XIAMETER PMX-200 Silikon Yağı 5 cSt (Silikon Yağı Tip 200.05)", "134 °C (Closed Cup)"], ["DOW", "XIAMETER PMX-200 Silikon Yağı 10 cSt (Silikon Yağı Tip 200.10)", "211 °C (Closed Cup)"], ["DOW", "XIAMETER PMX-200 Silikon Yağı 20 cSt (Silikon Yağı Tip 200.20)", "246 °C (Closed Cup)"], ["DOW", "XIAMETER PMX-200 Silikon Yağı 50 cSt (Silikon Yağı Tip 200.50)", "318 °C (Open Cup)"], ["WACKER", "AP 200 ", "260 °C"]],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0054',
        category: 'C06',
        externalUrl: false,
        url: 'dusuk-sicaklik-sivisi',
        image250: '/tr/s/250-250/dusuk-sicaklik-sivisi.jpg',
        image350: '/tr/s/350-350/dusuk-sicaklik-sivisi.jpg',
        image450: [  '/tr/s/450-450/dusuk-sicaklik-sivisi.jpg',],
        name: 'Düşük Sıcaklık Sıvısı',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Sıcaklık kalibrasyon banyolarında ve ısıtmalı soğutmalı sirkülatörlerde düşük viskozitesi ile yüksek stabilite ve homojeniteye sahip test ve kalibrasyon ortamı sağlayan düşük sıcaklık sıvısı.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Sıcaklık kalibrasyon banyolarında ve ısıtmalı soğutmalı sirkülatörlerde düşük viskozitesi ile yüksek stabilite ve homojeniteye sahip test ve kalibrasyon ortamı sağlayan düşük sıcaklık sıvısı.']],
    },
    {
        isActive: true,
        id: 'P0055',
        category: 'C06',
        externalUrl: false,
        url: 'isi-transfer-tuzu',
        image250: '/tr/s/250-250/isi-transfer-tuzu.jpg',
        image350: '/tr/s/350-350/isi-transfer-tuzu.jpg',
        image450: [  '/tr/s/450-450/isi-transfer-tuzu.jpg',],
        name: 'Isı Transfer Tuzu',
        device: '',
        brand: null,
        madeIn: null,
        overview: [],
        introduction: 'Isı transfer tuzu DUDEN 550L sıcaklık kalibrasyon tuz banyosunda çok yüksek sıcaklıklarda sıvı ortamda test ve kalibrasyon işlemlerinin yapılabilmesi için kullanılır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Isı transfer tuzu DUDEN 550L sıcaklık kalibrasyon tuz banyosunda çok yüksek sıcaklıklarda sıvı ortamda test ve kalibrasyon işlemlerinin yapılabilmesi için kullanılır.']],
    },
    {
        isActive: true,
        id: 'P0056',
        category: 'C06',
        externalUrl: false,
        url: 'sicaklik-kalibratoru-tasima-cantasi',
        image250: '/tr/s/250-250/sicaklik-kalibratoru-tasima-cantasi.jpg',
        image350: '/tr/s/350-350/sicaklik-kalibratoru-tasima-cantasi.jpg',
        image450: [  '/tr/s/450-450/sicaklik-kalibratoru-tasima-cantasi.jpg',],
        name: 'Alüminyum Taşıma Çantaları',
        device: '',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'Kuru blok kalibratörler, sıcaklık kalibrasyon banyoları, sıcaklık kalibratörleri, test ve ölçüm cihazları için istenilen boyutlarda alüminyum taşıma çantaları.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Kuru blok kalibratörler, sıcaklık kalibrasyon banyoları, sıcaklık kalibratörleri, test ve ölçüm cihazları için istenilen boyutlarda alüminyum taşıma çantaları.']],
    },
    {
        isActive: true,
        id: 'P0057',
        category: 'C04',
        externalUrl: false,
        url: 'secici-salter',
        image250: '/tr/s/250-250/test-ve-olcum-cihazlari-secici-salter.jpg',
        image350: '/tr/s/350-350/test-ve-olcum-cihazlari-secici-salter.jpg',
        image450: [  '/tr/s/450-450/test-ve-olcum-cihazlari-secici-salter.jpg',],
        name: '6 Girişli Seçici Şalter',
        device: '',
        brand: 'TKS',
        madeIn: 'Türkiye',
        overview: [],
        introduction: 'Direnç termometreleri ve termokupllar için altı girişli seçici anahtar. Seçici anahtar, cihaza bağlı sensörlerin kolayca seçilmesine olanak sağlar. Birden fazla sensörün testi ve kalibrasyonu için karmaşıklığı ortadan kaldırır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Direnç termometreleri ve termokupllar için altı girişli seçici anahtar. Seçici anahtar, cihaza bağlı sensörlerin kolayca seçilmesine olanak sağlar. Birden fazla sensörün testi ve kalibrasyonu için karmaşıklığı ortadan kaldırır.']],
    },
    {
        isActive: true,
        id: 'P0058',
        category: 'C04',
        externalUrl: false,
        url: 'pt100-referans-direnc-termometresi',
        image250: '/tr/s/250-250/pt100-referans-direnc-termometresi.jpg',
        image350: '/tr/s/350-350/pt100-referans-direnc-termometresi.jpg',
        image450: [  '/tr/s/450-450/pt100-referans-direnc-termometresi.jpg',],
        name: 'Pt100 Referans Direnç Termometresi',
        device: '',
        brand: 'KMP Krick Messtechnik & Partner GmbH',
        madeIn: 'Almanya',
        overview: [],
        introduction: 'Pt100 sıcaklık sensörleri, yüksek hassasiyetleri ve dayanıklılıkları nedeniyle endüstriyel otomasyon, gıda üretimi, ilaç endüstrisi, laboratuvarlar ve iklim kontrol sistemleri gibi birçok farklı uygulamada yaygın olarak kullanılır.',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: true,
        technicalSpecification: [['Standart', 'DIN EN 60751, Class 1/10 DIN B; 1 x Pt100, 4 Uçlu'], ['Çap', '4 mm'], ['Uzunluk', '400 mm'], ['Ölçme Aralığı', '-50 °C ... 500 °C']],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0059',
        category: 'C09',
        externalUrl: false,
        url: 'silindirik-dewar-kabi',
        image250: '/tr/s/250-250/silindirik-dewar-kabi.jpg',
        image350: '/tr/s/350-350/silindirik-dewar-kabi.jpg',
        image450: [  '/tr/s/450-450/silindirik-dewar-kabi.jpg',],
        name: 'Silindirik Dewar Kabı',
        device: '',
        brand: 'KGW Isotherm',
        madeIn: null,
        overview: [],
        introduction: '',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: true,
        document: [['/en/s/dewargefasse-typ-00-S22-E.pdf', 'Dewar Kapları Silindirik Tip 00-S22'], ['/en/s/dewargefasse-typ-G-0-S22-E.pdf', 'Dewar Kapları Silindirik Tutma Kollu Tip G0-GS22']],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0060',
        category: 'C09',
        externalUrl: false,
        url: 'paslanmaz-celik-dewar-kabi',
        image250: '/tr/s/250-250/paslanmaz-celik-dewar-kabi.jpg',
        image350: '/tr/s/350-350/paslanmaz-celik-dewar-kabi.jpg',
        image450: [  '/tr/s/450-450/paslanmaz-celik-dewar-kabi.jpg',],
        name: 'Paslanmaz Çelik Dewar Kabı',
        device: '',
        brand: 'KGW Isotherm',
        madeIn: null,
        overview: [],
        introduction: '',
        hasDataSheet: true,
        dataSheet: '/en/s/dewar-flasks-made-of-stainless-steel-type-gss-dss.pdf', 
        hasDocument: true,
        document: [['/en/s/dewar-flasks-made-of-stainless-steel-type-gss-dss.pdf', 'Paslanmaz Çelik Tip Dewar Kapları']],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: false,
        explanation: [],
    },
    {
        isActive: true,
        id: 'P0061',
        category: 'C06',
        externalUrl: false,
        url: 'giussani-tasima-cantalari',
        image250: '/tr/s/250-250/giussani-tasima-cantalari.jpg',
        image350: '/tr/s/350-350/giussani-tasima-cantalari.jpg',
        image450: [  '/tr/s/450-450/giussani-tasima-cantalari.jpg',],
        name: 'Giussani Taşıma Çantaları',
        device: '',
        brand: 'Giussani',
        madeIn: 'İtalya',
        overview: [],
        introduction: 'Giussani kuru blok kalibratör ve sıcaklık kalibrasyon banyosu modelleri için giussani taşıma çantaları. Cordura® Soft Bag, Rigid Case, Aluminium Rigid Case',
        hasDataSheet: false,
        dataSheet: null, 
        hasDocument: false,
        document: [],
        hasTechnicalSpecification: false,
        technicalSpecification: [],
        hasStandartEquipment: false,
        standardEquipment: [],
        hasAccessory: false,
        accessory: [],
        hasExplanation: true,
        explanation: [['', 'Cordura® Soft Bag'], ['', 'Rigid Case'], ['', 'Aluminium Rigid Case']],
    },
]
  
export default productsTr