import React from 'react'
import Header from '../components/Header'
import AdvertisementCarousel from '../components/AdvertisementCarousel'
import Footer from '../components/Footer'
import Meta from '../components/Meta'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Products from '../../popularProductsTr'

const HomeScreen = () => {
    return (
        <>
            <Meta 
            title='Kuru Blok Kalibratör, Sıcaklık Kalibrasyon Banyosu, Elektriksel Test Cihazı' 
            description='Kuru blok kalibratör, sıcaklık kalibrasyon banyosu, elektriksel test cihazı, ısıtmalı soğutmalı sirkülatör, kalibrasyon fırını, sıcaklık nem kalibrasyon ve test kabini, siyah cisim infrared kalibrasyon fırını, test ve ölçüm cihazı.'
            keywords='kuru blok kalibratör, sıcaklık kalibrasyon banyosu, sıcaklık kalibratörü, kalibrasyon banyosu, elektriksel test cihazı, sıcaklık nem kalibrasyon ve test kabini, nem kabini, iklimlendirme kabini, soğutmalı sirkülatör, kalibrasyon fırını' />
            
            <Header />

            <AdvertisementCarousel />

            <Container>
                <Row>
                    <h3 className='homeHeader-3'>Popüler Ürünler</h3>
                    {Products.map( product => 
                        <>
                            {product.isActive === true ? 
                                <Col xs={6} sm={6} md={4} lg={4} xl={3} className='categoryCol'>
                                    <Link to={`/tr/urun/${product.url}/`} className='textDecorationNone'>
                                        <Col className='categoryItem'>
                                            <picture>
                                                <source media='(min-width:750px)' srcset={product.image350} />
                                                <Image src={product.image250} alt={product.name + ' ' + product.device} className='categoryImage-2' fluid/>
                                            </picture>
                                            <h2 className='categoryHeader-2'>
                                                {product.name}
                                            </h2>
                                            <p className='techinacalData'>{product.device}</p>
                                            {product.overview.map((overview) => <p className='techinacalData'>{overview[0]}: {overview[1]}</p>)}
                                            <Button className='categoryButton'>
                                                Ürünü İncele
                                            </Button>
                                        </Col>
                                    </Link>
                                </Col>
                            : null}
                        </>
                    )}

                    <h1 className='homeHeader'>TKS Test ve Kalibrasyon Sistemleri</h1>
                    <p className='homeP'>
                        TKS Test ve Kalibrasyon Sistemleri San. ve Tic. Ltd. Şti. endüstrinin ihtiyacı olan test ve kalibrasyon sistemlerini sunan bir üretim firmasıdır. Bir çok zaman ilgili standartlara göre malzeme testleri için özel sistemler gerekir. Bu ihtiyaçları karşılamak amacı ile iş alanımızın bir bölümünü test sistemleri üretimine ayrılmıştır. Firmamız -80 °C ile +1400 °C sıcaklıkları arasında yüksek hassasiyetli Sıcaklık Kalibrasyon Banyosu, Kuru Blok Kalibratör, Isıtmalı Soğutmalı Sirkülatör ve kulaktan ateş ölçer, alından ateş ölçer infrared termometrelerin kalibrasyonu için Siyah Cisim (ASTM E1965, ASTM E2847) üretimi yapmaktadır.
                    </p>
                </Row>
            </Container>

            <section className='homeSection'>
                <Container>
                    <Row>
                        <Col xl={6} className='homeCol-2'>
                            <h2 className='homeHeader-2'>Sıcaklık Kalibrasyon Banyosu Nedir?</h2>
                            <p className='homeP-2'>
                                Sıcaklık Kalibrasyon Banyoları, rezistans termometre sıcaklık sensörleri (RTD), platin rezistans termometreler (PRT, SPRT), termokupllar gibi sıcaklık sensörlerinin daha hassas kalibrasyonunu sağlamak için bir sıvının ısıtılmasını veya soğutulmasını sağlayan sabit sıcaklık kaynaklarıdır. Kalibrasyon için ölçülecek sıcaklığa bağlı olarak sıcaklık kalibrasyon banyoları ya su ile ya da belirli yağ türleri ile doldurulur. Daha yüksek bir sıcaklık homejenliği ve stabilitesi ile en düşük kalibrasyon belirsizliklerini elde edebilmek için uzmanlar kuru blok kalibratörler yerine sıcaklık kalibrasyon banyolarını önermektedir. Kalibrasyon banyoları ya portatif aletler şeklinde ya da büyük, kalıcı olarak laboratuvara kurulmuş olabilir.
                            </p>
                        </Col> 
                        <Col xl={6} className='homeCol-2'>
                            <h2 className='homeHeader-2'>Kuru Blok Kalibratör Nedir?</h2>
                            <p className='homeP-2'>
                                Kuru Blok Kalibratörler, rezistans termometre sıcaklık sensörleri (RTD), termometreler, termokupllar gibi sıcaklık sensörlerinin kalibrasyonları için kullanılan sabit sıcaklık kaynaklarıdır. Kalibratörlerde daha doğru sonuçlar elde etmek için kontrol edilen sıcaklık probu ile etrafında hava akışını önlemek için maksimum birkaç milimetre boşluk olmalıdır. Bu yüzden Kuru Blok Kalibratörler farklı çaplardaki termometreler, termokupllar ile iyi bir termal temas elde edebilmek için değiştirilebilir kuru bloklara sahiptir. Kuru Blok Sıcaklık Kalibratörleri hızlıdır ve kolay taşınabilirliği sayesinde mobil kalibrasyon hizmeti veren kalibrasyon laboratuvarlarına kolaylık sağlar.
                            </p>
                        </Col> 
                        <Col xl={6} className='homeCol-2'>
                            <h2 className='homeHeader-2'>Test ve Ölçüm Cihazı Nedir?</h2>
                            <p className='homeP-2'>
                                Bir cihazın performansını tanımlayan çeşitli parametreleri test etmek için kullanılan araçlar, yaygın olarak Test ve Ölçüm Cihazları olarak adlandırılır. Test ekipmanı, test edilen cihazın performansını kontrol etmek için sinyaller oluşturur ve yanıtını okur. Test, cihazın ısı, şok, titreşim ve değişken çevresel koşullar gibi dış etkenlere maruz kalması nedeniyle zamanında bozulmaya uğrayan bileşenlerin belirlenmesine yardımcı olur. Voltmetre, Ohmmetre, Ampermetre ve Multimetreler birinci kategorideki en yaygın cihazlardır.
                            </p>
                        </Col>
                        <Col xl={6} className='homeCol-2'>
                            <h2 className='homeHeader-2'>Paslanmaz Çelik Kütle Nedir?</h2>
                            <p className='homeP-2'>
                                Terazilerin doğru sonuçlar verip vermediğinden emin olmak için teraziler düzenli aralıklarla kalibre edilmelidir. Paslanmaz Çelik Kütleler (Endüstriyel Test Ağırlıkları) terazilerin test ve kalibrasyonu sürecinde kullanılmaktadır. Kütleler (Test Ağırlıkları) ASTM, OIML ve NIST gibi standartlara göre üretilebilirler. En düşük (en doğru) ile en yüksek tolerans arasında, OIML sınıfları E1, E2, F1, F2, M1, M2 ve M3'tür. OIML sınıf ağırlıkları, ABD dışında Avrupa ve Latin Amerika'da daha yaygın olarak kullanılmaktadır.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container>
                <Row>
                    <h3 className='homeHeader-3'>Distribütörlükler</h3>
                    <Col xs={6} sm={6} md={4} lg={4} xl={4} className='categoryCol'>
                        <a href='https://www.giussanionline.com/en' path="/" className='textDecorationNone' target='_blank' rel="noopener noreferrer">
                            <Col className='categoryItem'>
                                <Image src='/tr/s/350-175/giussani-logo.jpg' alt='giussani logo' className='categoryImage-3' fluid/>
                            </Col>
                        </a>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4} xl={4} className='categoryCol'>
                        <a href='https://www.tunkia.cn/' path="/" className='textDecorationNone' target='_blank' rel="noopener noreferrer">
                            <Col className='categoryItem'>
                                <Image src='/tr/s/350-175/tunkia-logo.jpg' alt='tunkia logo' className='categoryImage-3' fluid/>
                            </Col>
                        </a>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4} xl={4} className='categoryCol'>
                        <a href='https://www.demeithermo.com/' path="/" className='textDecorationNone' target='_blank' rel="noopener noreferrer">
                            <Col className='categoryItem'>
                                <Image src='/tr/s/350-175/demei-logo.jpg' alt='demei instrument logo' className='categoryImage-3' fluid/>
                            </Col>
                        </a>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default HomeScreen